import React from "react";
import { Button, Modal } from "react-bootstrap";
import CustomButton from "./CustomButton";

export const ConfirmDeleteModal = ({ deleteShow, handleDelete, handleDeleteClose, name = "", text = "" }) => {
  const handleDeleteClick = () => {
    handleDelete();
    handleDeleteClose();
  };

  return (
    <Modal show={deleteShow} onHide={handleDeleteClose} size="md" backdrop>
      <Modal.Header closeButton>
        <Modal.Title>Delete {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Are you sure you want to delete this {name}</h5>
        <h5>{text}</h5>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <CustomButton variant="outline-white" onClick={handleDeleteClose}>
          Close
        </CustomButton>
        <CustomButton variant="primary" onClick={handleDeleteClick}>
          Confirm
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};
