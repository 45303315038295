// import node module libraries
import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import React, { useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useAsyncDebounce } from "react-table";
import tomatopng from "assets/images/png/tomato.png";
import decrease from "assets/svgs/minus.svg";
import increase from "assets/svgs/plus.svg";
const productOptions = [
  { label: "Bowl", value: "Bowl" },
  { label: "Basket", value: "Basket" },
  { label: "Bucket", value: "Bucket" },
];
const Product = ({ imageSrc, name, amount, subTotal }) => {
  const [unitAmount, setUnitAmount] = useState(amount);
  const handleIncrease = (amount) => {
    setUnitAmount(amount + 1);
  };
  const handleDecrease = (amount) => {
    amount > 0 ? setUnitAmount(amount - 1) : setUnitAmount(0);
  };
  return (
    <Card className=" p-1">
      <Row className="align-items-end gap-3">
        <Col>
          <ProductCardImage src={imageSrc} />
        </Col>
        <Col className="p-0">
          {" "}
          <Col>{name}</Col>
          <Col>Select Quality</Col>
          <Col className="d-flex">
            <img
              alt="decrease"
              src={decrease}
              onClick={() => {
                handleDecrease(unitAmount);
              }}
            />
            <Form.Control
              className="p-1 w-25 text-center"
              type="text"
              readOnly
              disabled
              value={unitAmount}
              placeholder={0}
            />
            <img
              alt="increase"
              src={increase}
              onClick={() => {
                handleIncrease(unitAmount);
              }}
            />
          </Col>
        </Col>
        <Col className="p-0">
          {" "}
          <CustomInput
            label=" "
            inputType="select"
            value="Oshodi"
            selectOptions={productOptions}
          />
        </Col>
        <Col className="p-0">
          {" "}
          <Col>
            <CustomButton
              variant="primary"
              type="button"
              text="Add"
              // onClick={handleShow}
            />
          </Col>
          <Col>Subtotal</Col>
          <Col>
            <CustomText
              fontFamily={"Poppins"}
              fontSize={16}
              fontWeight={600}
              text={subTotal}
              cNColor="primary"
            />
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

export default Product;
