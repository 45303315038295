// import node module libraries
import React, { useEffect, useState } from "react";
import { Col, Row, Card, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

// import custom components
import ApexCharts from "components/charts/ApexCharts";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as RevenueCardSvg } from "assets/svgs/revenue-card.svg";
import { ReactComponent as WithdrawalardSvg } from "assets/svgs/withdrawal-card.svg";

import CustomInput from "components/CustomInput";
import CustomDetailGroup from "components/CustomDetailGroup";
import { CustomText } from "components/CustomText";
import { useGetDashboardStatsMutation, useGetMarketsMutation, useGetOrderStatsByIntervalMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";
import { isSubstringInArray } from "helper/formatArray";
import { removeUndefinedKeys, removeUndefinedOrNull } from "helper/formatObject";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Link>
));

const displayOptions = [
  { label: "This month", value: "This month" },
  { label: "This week", value: "This week" },
  { label: "This year", value: "This year" },
  { label: "Today", value: "Today" },
];
const intervalOptions = [
  { label: "Today", value: moment().startOf("day").valueOf() },
  { label: "This week", value: moment().startOf("isoWeek").valueOf() },
  { label: "This month", value: moment().startOf("month").valueOf() },
  { label: "This year", value: moment().startOf("year").valueOf() },
  { label: "All", value: 0 },
];
const ChartActionMenu = () => {
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <i className="fe fe-more-vertical text-muted"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>SETTINGS</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            <i className="fe fe-external-link dropdown-item-icon "></i> Export
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            <i className="fe fe-mail dropdown-item-icon "></i> Email Report
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            <i className="fe fe-download dropdown-item-icon "></i> Download
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const Dashboard = () => {
  const { user } = useSelector((state) => state.userAuth);
  const [getMarkets, { isLoading: isLoadingMarkets }] = useGetMarketsMutation();
  const [getDashboardStats, { isLoading }] = useGetDashboardStatsMutation();
  const [getOrderStatsByInterval, { isLoading: isLoadingOrderStats }] = useGetOrderStatsByIntervalMutation();
  const [marketOptions, setMarketOptions] = useState([{ label: "All", value: "" }]);
  const [interval, setInterval] = useState({ startDate: 0, endDate: Date.now(), market_id: "" });
  const [stats, setStats] = useState({ orderCount: 0, customerCount: 0, shopperCount: 0, productCount: 0, vendorCount: 0 });
  const [orderStats, setOrderStats] = useState({
    deliveredOrderCount: 0,
    openOrderCount: 0,
    intransitOrderCount: 0,
    shoppedOrderCount: 0,
    cancelledOrderCount: 0,
    acceptedOrderCount: 0,
  });

  const fetchMarkets = async () => {
    const res = await getMarkets();
    if (res?.data?.status === "success") {
      const marketList = res?.data?.data?.map((x) => {
        if (isSubstringInArray(user?.role, ["finance"])) {
          return { label: `${x?.name}` + ` (${x?.state_name})`, value: x?._id };
        } else if (isSubstringInArray(user?.role, ["super admin, local admin"]) && isSubstringInArray(x?.country_name, ["nigeria"])) {
          return { label: `${x?.name}` + ` (${x?.state_name})`, value: x?._id };
        } else if (isSubstringInArray(user?.role, ["global admin"]) && !isSubstringInArray(x?.country_name, ["nigeria"])) {
          return { label: `${x?.name}` + ` (${x?.state_name})`, value: x?._id };
        } else if (isSubstringInArray(user?.role, ["market admin"]) && !isSubstringInArray(x?.country_name, ["nigeria"])) {
          return { label: `${x?.name}` + ` (${x?.state_name})`, value: x?._id };
        }
      });
      setMarketOptions(
        removeUndefinedOrNull([isSubstringInArray(user?.role, ["finance"]) ? { label: "All", value: "" } : null, ...marketList]),
      );
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchDashStats = async () => {
    const res = await getDashboardStats();
    if (res?.data?.status === "success") {
      const orderList = res?.data?.data;
      setStats(orderList);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchOrderStats = async () => {
    const res = await getOrderStatsByInterval(interval);
    if (res?.data?.status === "success") {
      const orderList = res?.data?.data;
      setOrderStats(orderList);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchDashStats();
    fetchMarkets();
  }, []);

  useEffect(() => {
    fetchOrderStats();
  }, [interval]);

  return (
    <div>
      <Row>
        <Col xl={4} lg={6} md={6} sm={12}>
          <StatRightIcon
            title="Customers"
            value={stats?.customerCount || 0}
            iconComponent={<UserCardSvg />}
            iconColorVariant="primary"
            classValue="mb-4"
          />
        </Col>
        <Col xl={4} lg={6} md={6} sm={12}>
          <StatRightIcon
            title="Shoppers"
            value={stats?.shopperCount || 0}
            iconComponent={<UserCardSvg />}
            iconColorVariant="primary"
            classValue="mb-4"
          />
        </Col>

        <Col xl={4} lg={6} md={6} sm={12}>
          <StatRightIcon
            title="Vendors"
            value={stats?.vendorCount || 0}
            iconComponent={<UserCardSvg />}
            iconColorVariant="primary"
            classValue="mb-4"
          />
        </Col>

        <Col xl={4} lg={6} md={6} sm={12}>
          <StatRightIcon
            title="Products"
            value={stats?.productCount || 0}
            iconComponent={<OrderCardSvg />}
            iconColorVariant="primary"
            classValue="mb-4"
          />
        </Col>

        <Col xl={4} lg={6} md={6} sm={12}>
          <StatRightIcon
            title="Orders"
            value={stats?.orderCount || 0}
            iconComponent={<StoreCardSvg />}
            iconColorVariant="primary"
            classValue="mb-4"
          />
        </Col>
      </Row>

      {isSubstringInArray(user?.role, ["super", "local admin", "global admin", "finance", "market"]) && (
        <Card>
          <Card.Header className="align-items-center card-header-height d-flex justify-content-between align-items-center">
            <div>
              <h4 className="mb-0">Orders Stats</h4>
            </div>
            <div>
              <ChartActionMenu />
            </div>
          </Card.Header>
          <Card.Body>
            <Row className="align-items-start">
              <Col lg={6} xl={6}>
                <CustomInput
                  label="Market"
                  inputType="select"
                  selectOptions={marketOptions}
                  onChange={(e) => {
                    setInterval({ ...interval, market_id: e });
                  }}
                  value={marketOptions[0]?.value}
                />
              </Col>
              <Col lg={6} xl={6}>
                <CustomInput
                  label="Interval"
                  inputType="select"
                  selectOptions={intervalOptions}
                  onChange={(e) => {
                    setInterval({ ...interval, startDate: e });
                  }}
                  value={intervalOptions?.find((x) => x?.value == interval?.startDate) || { label: "All", value: 0 }}
                />
              </Col>
            </Row>
            <Row className="align-items-start">
              <Col className="mb-4" lg={6} xl={6}>
                <div id="chart">
                  <ApexCharts options={ChartOptions} series={ChartSeries(orderStats)} type="donut" />
                </div>
              </Col>
              <Col className="mb-4" lg={6} xl={6}>
                <CustomDetailGroup
                  className={"px-4 py-2 d-flex gap-3  align-items-center"}
                  fontFamily={"Montserrat"}
                  fontWeight="600"
                  itemKey={orderStats?.openOrderCount}
                  keyFontSize={18}
                  keyStyleColor={"#1f77b4"}
                  itemValue="Opened Orders"
                  valueFontSize={14}
                />
                <CustomDetailGroup
                  className={"px-4 py-2 d-flex gap-3  align-items-center"}
                  fontFamily={"Montserrat"}
                  fontWeight="600"
                  itemKey={orderStats?.acceptedOrderCount}
                  keyFontSize={18}
                  keyStyleColor={"#ff7f0e"}
                  itemValue="Accepted"
                  valueFontSize={14}
                />
                <CustomDetailGroup
                  className={"px-4 py-2 d-flex gap-3  align-items-center"}
                  fontFamily={"Montserrat"}
                  fontWeight="600"
                  itemKey={orderStats?.shoppedOrderCount}
                  keyFontSize={18}
                  keyStyleColor={"#9467bd"}
                  itemValue="Being Shopped"
                  valueFontSize={14}
                />
                <CustomDetailGroup
                  className={"px-4 py-2 d-flex gap-3  align-items-center"}
                  fontFamily={"Montserrat"}
                  fontWeight="600"
                  itemKey={orderStats?.intransitOrderCount}
                  keyFontSize={18}
                  keyStyleColor={"#ffdc00"}
                  itemValue="In Transit"
                  valueFontSize={14}
                />
                <CustomDetailGroup
                  className={"px-4 py-2 d-flex gap-3  align-items-center"}
                  fontFamily={"Montserrat"}
                  fontWeight="600"
                  itemKey={orderStats?.deliveredOrderCount}
                  keyFontSize={18}
                  keyStyleColor={"#2ca02c"}
                  itemValue="Delivered Orders"
                  valueFontSize={14}
                />
                <CustomDetailGroup
                  className={"px-4 py-2 d-flex gap-3  align-items-center"}
                  fontFamily={"Montserrat"}
                  fontWeight="600"
                  itemKey={orderStats?.cancelledOrderCount}
                  keyFontSize={18}
                  keyStyleColor={"#d62728"}
                  itemValue="Cancelled Orders"
                  valueFontSize={14}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export const ChartSeries = ({
  openOrderCount,
  acceptedOrderCount,
  shoppedOrderCount,
  intransitOrderCount,
  deliveredOrderCount,
  cancelledOrderCount,
}) => {
  return [openOrderCount, acceptedOrderCount, shoppedOrderCount, intransitOrderCount, deliveredOrderCount, cancelledOrderCount];
};
export const ChartOptions = {
  dataLabels: { enabled: !1 },
  labels: ["Open", "Accepted", "Shopped", "In Transit", "Delivered", "Cancelled"],
  colors: [
    "#1f77b4", // Blue
    "#ff7f0e", // Orange
    "#9467bd", // Purple
    "#ffdc00", // Yellow
    "#2ca02c", // Green
    "#d62728", // Red
  ],
  legend: { position: "bottom" },
  plotOptions: { pie: { expandOnClick: !1, donut: { size: "78%" } } },
  chart: { type: "donut" },
  tooltip: { theme: "light", marker: { show: !0 }, x: { show: !1 } },
  states: { hover: { filter: { type: "none" } } },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          height: 400,
        },
      },
    },
    {
      breakpoint: 5000,
      options: {
        chart: {
          height: 380,
        },
      },
    },
  ],
};

export default Dashboard;
