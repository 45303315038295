import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomText } from "components/CustomText";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Card, Col, Dropdown, DropdownButton, Form, FormLabel, FormSelect, InputGroup, Row, Table } from "react-bootstrap";
import { Button } from "bootstrap";
import { useEffect, useMemo, useState } from "react";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { AssignShopper } from "./components/AssignShopper";
import { ChangeOrderStatus } from "./components/ChangeOrderStatus";
import CustomDetailGroup from "components/CustomDetailGroup";
import OrderSummary from "./components/OrderSummary";
import { Products } from "./components/Products";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { capitalize, formatCurrency } from "helper/formatText";
import {
  useAssignOrderShopperMutation,
  useUnassignOrderShopperMutation,
  useGetCountriesMutation,
  useGetOrderMutation,
  useGetTransactionsMutation,
  useUpdateOrderMutation,
  useUpdateOrderStatusMutation,
} from "redux/features/user/userApi";
import { saveCountries } from "redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { convertJsonStringToObject } from "helper/formatObject";
import { refresh } from "@cloudinary/url-gen/qualifiers/artisticFilter";
import { TableHeader } from "components/TableHeader";
import TransactionTable from "components/TransactionTable";
import { findLowestFreightRate, isSubstringInArray } from "helper/formatArray";
import { ChangeOrderPaymentStatus } from "./components/ChangeOrderPaymentStatus";
const OrderDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [assign, setAssign] = useState(false);
  const [status, setStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [details, setDetails] = useState(location.state);
  const [transactions, setTransactions] = useState([]);
  const { countries } = useSelector((state) => state.userAuth);
  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();
  const [getTransactions, { isLoading: isLoadingTransactions }] = useGetTransactionsMutation();
  const [getOrder, { isLoading: isLoadingOrder }] = useGetOrderMutation();
  const [updateOrder, { isLoading: isUpdatingOrder }] = useUpdateOrderMutation();
  const [updateOrderStatus, { isLoading: isUpdatingOrderStatus }] = useUpdateOrderStatusMutation();
  const [assignOrderShopper, { isLoading: isAssigningOrderShopper }] = useAssignOrderShopperMutation();
  const [unassignOrderShopper, { isLoading: isUnAssigningOrderShopper }] = useUnassignOrderShopperMutation();

  const handleOrderStatusChange = async (order_status) => {
    const res = await updateOrderStatus({ id: details?.id, data: { order_status, shopper_id: details?.shopper_id } });
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
      handleCloseStatus();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleOrderPaymentStatusChange = async (payment_status) => {
    const res = await updateOrder({ id: details?.id, data: { payment_status } });
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Payment Status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
      handleClosePaymentStatus();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchOrder = async () => {
    const res = await getOrder(location.state?.id);
    if (res?.data?.status === "success") {
      setDetails(convertJsonStringToObject(JSON.parse(JSON.stringify(res?.data?.data))));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchTransactions = async () => {
    const res = await getTransactions({ order_id: location.state?.id });
    if (res?.data?.status === "success") {
      setTransactions(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchOrder();
    fetchTransactions();
  }, [refresh]);

  const handleCloseAssign = () => {
    setAssign(false);
  };
  const handleShowAssign = () => {
    setAssign(true);
  };
  const handleCloseStatus = () => {
    setStatus(false);
    setRefresh(!refresh);
  };
  const handleClosePaymentStatus = () => {
    setPaymentStatus(false);
    setRefresh(!refresh);
  };
  const handleShowStatus = () => {
    setStatus(true);
  };
  const handleShowPaymentStatus = () => {
    setPaymentStatus(true);
  };

  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleAssignShopper = async (shopper) => {
    const res = await assignOrderShopper({ id: details?.id, data: { shopper_id: shopper?._id } });
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Shopper assigned successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
      handleCloseAssign();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleUnassignShopper = async (shopper) => {
    const res = await unassignOrderShopper({ id: details?.id, data: { shopper_id: shopper?._id } });
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Shopper unassigned successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = useMemo(
    () => [
      //   { accessor: "sn", Header: "S/N" },
      //   { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "price",
        Header: "Price",
      },
      {
        accessor: "unitOfMeasure",
        Header: "Unit of Measure",
      },
      // enableSorting: true,
      {
        accessor: "quantity",
        Header: "Quantity",
      },
      {
        accessor: "subTotal",
        Header: "Sub Total",
      },
    ],
    [],
  );
  const data = useMemo(() => {
    let filtered = Array(7).fill(null);
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        id: index + 1,
        sn: index + 1,
        name: "Tomato",

        price: Math.random() > 0.5 ? "100" : "50",
        unitOfMeasure: Math.random() > 0.5 ? "Bowl" : "Bucket",
        quantity: "5",
        subTotal: Math.random() > 0.5 ? "2,000" : "1,000",
        originalAmount: "5,000",
        marketArea: "Oshodi" || "Mile 12",
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
      };
    });

    return filtered;
  }, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // pageSize: pagesize,
      initialState: {
        // pageSize: pagesize,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const statusOptions = [
    { label: "Open", value: "open" },
    { label: "Accepted", value: "accepted" },
    { label: "Shopped", value: "shopped" },
    { label: "In Transit", value: "intransit" },
    { label: "Delivered", value: "delivered" },
    { label: "Cancelled", value: "cancelled" },
  ];

  const DoorDeliveryDetails = ({ rate, deliveryDetails }) => {
    return (
      <Row className="p-0 m-0">
        <Col className="p-0 m-0">
          <Card className="p-0 m-0">
            <Col id="comptitle" className="p-0 m-0">
              <Col className=" p-3 ">
                <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Door Delivery Details"} cNColor="primary" />
              </Col>
            </Col>
            <CustomDetailGroup itemKey="Contact name:" itemValue={deliveryDetails?.contact_name} />
            <CustomDetailGroup itemKey="Contact phone:" itemValue={deliveryDetails?.contact_phone} />
            <CustomDetailGroup itemKey="Street Address:" itemValue={deliveryDetails?.street_address} />
            <CustomDetailGroup itemKey="Apartment/Suite:" itemValue={deliveryDetails?.apartment} />
            <CustomDetailGroup itemKey="City:" itemValue={deliveryDetails?.city} />
            <CustomDetailGroup itemKey="State/Province:" itemValue={deliveryDetails?.state} />
            <CustomDetailGroup itemKey="Country:" itemValue={deliveryDetails?.country} />
          </Card>
        </Col>
        <Col className="p-0 m-0">
          <Card className="p-0 m-0">
            <Col id="comptitle" className="p-0 m-0">
              <Col className=" p-3 ">
                <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Delivery Service Details"} cNColor="primary" />
              </Col>
            </Col>
            <CustomDetailGroup itemKey="Carrier name:" itemValue={rate?.carrier_name} />
            <CustomDetailGroup itemKey="Service name:" itemValue={rate?.service_name} />
            <CustomDetailGroup itemKey="Service ID:" itemValue={rate?.service_id} />
            <CustomDetailGroup itemKey="Total amount:" itemValue={`${rate?.total?.value} ${rate?.total?.currency}`} />
            <CustomDetailGroup itemKey="Transit time days:" itemValue={rate?.transit_time_days} />
            <CustomDetailGroup
              itemKey="Valid until:"
              itemValue={rate?.valid_until?.day + "/" + rate?.valid_until?.month + "/" + rate?.valid_until?.year}
            />
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <Row>
      <Card>
        <Row id="comptitle">
          <Col className=" p-3 d-flex align-items-center justify-content-between">
            <CustomText
              fontFamily={"Montserrat"}
              fontSize={16}
              fontWeight={600}
              text={`Order ID : ${details?.order_id}`}
              cNColor="primary"
            />
          </Col>
          <Col className="p-3 d-flex align-items-center justify-content-end">
            {details?.payment_status == "unpaid" && (
              <CustomButton
                variant="primary"
                // type="submit"
                onClick={handleShowPaymentStatus}
                text="Change Payment Status"
                // loading={}
              />
            )}
            <CustomButton
              variant="primary"
              // type="submit"
              onClick={handleShowStatus}
              text="Change Order Status"
              style={{ marginLeft: 10 }}
            />
          </Col>
        </Row>
        <Row className="py-1">
          <CustomDetailGroup
            itemKey="Status:"
            valueClassName={"status"}
            itemValue={statusOptions?.find((x) => x?.value == details?.order_status)?.label}
          />
          <CustomDetailGroup itemKey="Payment Status:" itemValue={capitalize(details?.payment_status)} />
          <CustomDetailGroup
            itemKey="Delivery Method:"
            itemValue={isSubstringInArray("pickup", [details?.delivery_method || "###"]) ? "Pickup Station" : "Door Delivery"}
          />
          <CustomDetailGroup
            itemKey="Delivery Contact:"
            itemValue={`${details?.delivery_address?.contact_name}, \n ${details?.delivery_address?.contact_phone}`}
          />
          {isSubstringInArray("pickup", [details?.delivery_method || "###"]) ? (
            <CustomDetailGroup
              itemKey="Pickup Address:"
              itemValue={`${details?.market?.pickup_station_name} -> ${details?.market?.pickup_station || ""} -> ${
                details?.market?.pickup_station_description || ""
              }`}
            />
          ) : (
            <CustomDetailGroup
              itemKey="Delivery Address:"
              // itemValue={`${details?.delivery_address?.street_address}, ${details?.delivery_address?.city}, ${details?.delivery_address?.state}, ${details?.delivery_address?.country}`}
              itemValue={
                <div>
                  {details?.delivery_address?.apartment && (
                    <CustomText
                      fontFamily={"Poppins"}
                      fontSize={14}
                      fontWeight={400}
                      text={`${details?.delivery_address?.apartment}`}
                      cNColor="primary"
                    />
                  )}
                  <CustomText
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={400}
                    text={`${details?.delivery_address?.street_address}`}
                    cNColor="primary"
                  />
                  <CustomText
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={400}
                    text={`${details?.delivery_address?.city}, ${details?.delivery_address?.state}, ${details?.delivery_address?.country}`}
                    cNColor="primary"
                  />
                </div>
              }
            />
          )}
          <CustomDetailGroup itemKey="Delivery postal code:" itemValue={`${details?.delivery_address?.postal_code || "N/A"}`} />
          <CustomDetailGroup itemKey="Delivery instructions:" itemValue={`${details?.delivery_address?.additional_info || "N/A"}`} />
          {details?.additional_delivery_info !== [] && !!details?.additional_delivery_info && (
            <DoorDeliveryDetails
              rate={findLowestFreightRate(details?.additional_delivery_info?.data?.rate)?.lowestService}
              deliveryDetails={details?.delivery_address}
            />
          )}
          <CustomDetailGroup
            itemKey="Market:"
            itemValue={` ${details?.market?.name} ( ${details?.market?.market_code} ${details?.market?.state_name} - ${details?.market?.country_name})`}
          />
          <CustomDetailGroup
            itemKey="Service charge:"
            itemValue={formatCurrency(details?.total_service_charge, 2, details?.country?.currency?.symbol)}
          />
          <CustomDetailGroup
            itemKey="Delivery fee:"
            itemValue={formatCurrency(details?.total_delivery_fee, 2, details?.country?.currency?.symbol)}
          />
          <CustomDetailGroup itemKey="Vat:" itemValue={formatCurrency(details?.total_vat, 2, details?.country?.currency?.symbol)} />
          <CustomDetailGroup
            itemKey="Country tax:"
            itemValue={formatCurrency(details?.total_country_tax, 2, details?.country?.currency?.symbol)}
          />
          <CustomDetailGroup
            itemKey="Total Item Amount:"
            itemValue={formatCurrency(details?.total_item_amount, 2, details?.country?.currency?.symbol)}
          />
          <CustomDetailGroup
            itemKey="Total Amount:"
            itemValue={formatCurrency(details?.total_amount, 2, details?.country?.currency?.symbol)}
          />
          {/* <CustomDetailGroup itemKey="Additional Delivery Info:" itemValue={JSON.stringify(details?.additional_delivery_info)} /> */}
          <CustomDetailGroup itemKey="Date Created:" itemValue={moment(details?.createdAt).format("ddd MMMM DD yyyy")} />
          <CustomDetailGroup itemKey="Last Updated:" itemValue={moment(details?.updatedAt).format("ddd MMMM DD yyyy")} />
        </Row>
        <Row className="py-1">
          <Col className="p-0">
            <Card className="p-0 m-0">
              <Col id="comptitle">
                <Col className=" p-3 ">
                  <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Customer's Details"} cNColor="primary" />
                </Col>
              </Col>
              <CustomDetailGroup itemKey="First name:" itemValue={details?.customer?.firstName} />
              <CustomDetailGroup itemKey="Last name:" itemValue={details?.customer?.lastName} />
              <CustomDetailGroup itemKey="Phone Number:" itemValue={details?.customer?.phoneNumber} />
              <CustomDetailGroup itemKey="Email Address:" itemValue={details?.customer?.email} />
            </Card>
          </Col>

          {!!details?.shopper ? (
            <Card className="p-0 m-0">
              <Row id="comptitle" className="p-0 m-0">
                <Col className=" p-3 ">
                  <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Shopper's Details"} cNColor="primary" />
                </Col>
                <Col className="d-flex p-3 justify-content-end ">
                  <CustomButton
                    variant="primary"
                    type="submit"
                    text="Unassign shopper"
                    onClick={() => handleUnassignShopper(details?.shopper)}
                    loading={isUnAssigningOrderShopper}
                  />
                </Col>
              </Row>
              <CustomDetailGroup itemKey="First name:" itemValue={details?.shopper?.firstName} />
              <CustomDetailGroup itemKey="Last name:" itemValue={details?.shopper?.lastName} />
              <CustomDetailGroup itemKey="Email Address:" itemValue={details?.shopper?.email} />
              <CustomDetailGroup itemKey="Phone Number:" itemValue={details?.shopper?.phoneNumber} />
              {/* <CustomDetailGroup itemKey="Market:" itemValue={details?.shopper?.market_id?.name} /> */}
              {/* <CustomDetailGroup itemKey="Date Updated:" itemValue={details?.shopper} /> */}
            </Card>
          ) : (
            isSubstringInArray(details?.country?.name, ["nigeria"]) && (
              <Card className="p-0 m-0">
                <Col id="comptitle" className="p-0 m-0">
                  <Col className=" p-3 ">
                    <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Shopper's Details"} cNColor="primary" />
                  </Col>
                </Col>
                <Col className="p-11 text-center">
                  <CustomText fontFamily={"Poppins"} fontSize={20} fontWeight={500} text={"No Shopper yet"} cNColor="primary" />
                  <CustomButton
                    variant="primary"
                    type="submit"
                    text="Assign a shopper"
                    onClick={handleShowAssign}
                    loading={isAssigningOrderShopper}
                  />
                </Col>
              </Card>
            )
          )}
        </Row>
        <Col className="p-0 m-0">
          <TableHeader title={"Order Summary"} data={[]} />
          {details?.items?.length > 0 ? (
            <OrderSummary items={details?.items} vendors={details?.vendors} order={details} />
          ) : (
            <Col className="p-6 d-flex text-center justify-content-center">
              <CustomText fontFamily={"Poppins"} fontSize={20} fontWeight={500} text="Empty Order List" cNColor="primary" />
            </Col>
          )}
        </Col>
        <TransactionTable items={transactions} title={"Order Transactions"} />
      </Card>
      <Products show={show} close={handleClose} />
      <AssignShopper
        show={assign}
        close={handleCloseAssign}
        onConfirm={handleAssignShopper}
        market={details?.market}
        isLoading={isAssigningOrderShopper}
      />
      <ChangeOrderStatus
        initial={details}
        show={status}
        close={handleCloseStatus}
        onConfirm={handleOrderStatusChange}
        isLoading={isUpdatingOrderStatus}
      />
      <ChangeOrderPaymentStatus
        initial={details}
        show={paymentStatus}
        close={handleClosePaymentStatus}
        onConfirm={handleOrderPaymentStatusChange}
        isLoading={isUpdatingOrder}
      />
    </Row>
  );
};

export default OrderDetails;
