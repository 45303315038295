import React from "react";
import { Row, Spinner } from "react-bootstrap";

const Loader = () => {
  return (
    <Row className="d-flex justify-content-center align-items-center min-vh-100">
      <Spinner style={{ color: "#e0bf00" }} />
    </Row>
  );
};

export default Loader;
