import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomText } from "components/CustomText";

export const UpdateVerificationStatus = ({ show, close, initial, onConfirm }) => {
  const statusOptions = [
    { label: "Verified", value: true },
    { label: "Unverified", value: false },
  ];

  const [status, setStatus] = useState(initial);

  return (
    <Modal show={show} onHide={close} size="md">
      <Modal.Header>
        <Modal.Title>
          <CustomText fontFamily={"Poppins"} fontSize={20} fontWeight={600} text={"Update Withdrawal Status"} cNColor="primary" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <CustomInput
              label=" "
              inputType="select"
              selectOptions={statusOptions}
              placeholder="status"
              onChange={(e) => setStatus(e)}
              value={status}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between p-1">
        <CustomButton
          variant="secondary"
          type="submit"
          text="Cancel"
          onClick={close}
          // loading={}
        />

        <CustomButton
          variant="primary"
          type="submit"
          text="Confirm"
          onClick={() => {
            onConfirm(status);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
