import React from "react";
import { useState } from "react";
import { InputGroup, Button, Form, Image, Col } from "react-bootstrap";
import ReactSelect from "react-select";
import { CustomText } from "./CustomText";
import { ReactComponent as EyeOnSvg } from "assets/svgs/eyeOpen.svg";
import { ReactComponent as EyeOffSvg } from "assets/svgs/eyeClose.svg";

const CustomInput = ({
  value,
  defaultValue,
  as,
  inputType = "textinput", //options -> textinput or select
  selectOptions = [],
  variant = "form", // options are [ form, auth ]
  label,
  leftComp,
  rightComp,
  accept,
  type = "text",
  required = true,
  id = "text",
  placeholder = "input",
  onChange = () => {},
  inputStyle,
  rightAction = () => {},
  leftAction = () => {},
  rightIcon,
  leftIcon,
  rightIconStyle,
  leftIconStyle,
  error,
  mt = 0,
  inputTop = 5,
  inputBottom = 5,
  errorClassName = "fs-8 text-danger mb-0",
  returnObj,
  isPassword,
  isSearchable = true,
  isClearable = true,
  onInputChange = () => {},
  ...props
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div style={{ marginTop: mt }}>
      {variant === "form" && label !== null && (
        <CustomText
          fontFamily={"Poppins"}
          fontSize={12}
          fontWeight={600}
          text={label || id || ""}
          cNColor="gray"
          textClassName="text-capitalize text-truncate"
        />
      )}
      {inputType === "textinput" ? (
        <InputGroup style={{ marginTop: inputTop, marginBottom: inputBottom }}>
          {leftComp
            ? leftComp
            : leftIcon && (
                <Button
                  variant="outline-secondary"
                  style={{
                    backgroundColor: "#eeeeee",
                    borderColor: "#d9d9d9",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    ...leftIconStyle,
                  }}
                  onClick={leftAction}
                >
                  {leftIcon}
                </Button>
              )}

          <Form.Control
            as={as}
            value={value}
            type={isPassword ? (!passwordShown ? "password" : "text") : type}
            id={id}
            placeholder={placeholder}
            required={required}
            accept={accept}
            onChange={onChange}
            style={{
              backgroundColor: variant === "form" ? "transparent" : "transparent",
              ...inputStyle,
              borderColor: variant === "form" ? "#d9d9d9" : "#d9d9d9",
              borderRadius: "4px",
              height: variant === "form" ? "38px" : "50px",
            }}
            {...props}
          >
            {type == "select" ? (
              <>
                <option value="">Select a Market ID</option>
                {selectOptions?.map((opt) => (
                  <option value={opt?.value}>{opt.label}</option>
                ))}
              </>
            ) : null}
          </Form.Control>

          {rightComp
            ? rightComp
            : rightIcon && (
                <Button
                  variant="outline-secondary"
                  style={{
                    backgroundColor: "#f8f8f8",
                    borderColor: "#d9d9d9",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderLeftWidth: "0px",
                  }}
                  onClick={rightAction}
                >
                  {rightIcon}
                </Button>
              )}
          <Col style={{ position: "absolute", right: "10px", top: "6px", zIndex: 10 }}>
            {isPassword && (!passwordShown ? <EyeOffSvg onClick={togglePassword} /> : <EyeOnSvg onClick={togglePassword} />)}
          </Col>
        </InputGroup>
      ) : (
        <Col style={{ marginTop: inputTop, marginBottom: inputBottom }}>
          <ReactSelect
            options={selectOptions}
            defaultValue={defaultValue || value}
            placeholder={placeholder}
            required={required}
            onChange={(e) => {
              if (returnObj) {
                onChange(e);
              } else if (props?.isMulti) {
                onChange(
                  e?.map((x) => {
                    return x?.value;
                  }),
                );
              } else onChange(e?.value);
            }}
            isClearable={isClearable}
            isSearchable={isSearchable}
            onInputChange={onInputChange}
            style={{
              backgroundColor: variant === "form" ? "#f8f8f8" : "transparent",
              ...inputStyle,
              borderColor: variant === "form" ? "#eeeeee" : "#d9d9d9",
              height: variant === "form" ? "40px" : "50px",
              zIndex: 5,
              width: 120,
              marginTop: `${inputTop}px`,
              marginBottom: `${inputBottom}px`,
            }}
            id={id}
            name={id}
            {...props}
          />
        </Col>
      )}
      {error && <p className={errorClassName}>{error}</p>}
    </div>
  );
};

export default CustomInput;
