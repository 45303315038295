import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import tomatopng from "assets/images/png/tomato.png";
import { getValue } from "helper/formatObject";
import { isSubstringInArray } from "helper/formatArray";

export const ChangeTransactionStatus = ({
  isBulk,
  initial,
  show,
  close = () => {},
  onConfirm = () => {},
  isLoading,
  removedOptions = [],
}) => {
  const statusOptions = useMemo(() => {
    return [
      { label: "Pending", value: "pending" },
      { label: "Approved", value: "approved" },
      { label: "Settled", value: "settled" },
      { label: "Rejected", value: "rejected" },
      { label: "Refunded", value: "refunded" },
      { label: "Cancelled", value: "cancelled" },
    ];
  }, [show]);
  const [status, setStatus] = useState(statusOptions.find((x) => x.value == initial) || statusOptions[0]);

  useEffect(() => {
    initial && setStatus(statusOptions.find((x) => x.value == initial));
    console.log(
      initial,
      "statusOptions.find",
      statusOptions.find((x) => x.value == initial),
    );
  }, [initial, show, statusOptions]);

  const filteredOptions = useMemo(() => {
    let filtered = statusOptions || [];
    if (isBulk && removedOptions?.length > 0) {
      filtered = filtered.filter((x) => !isSubstringInArray(x?.value, removedOptions));
    }
    filtered = filtered.filter((x) => x?.value !== initial);
    return filtered;
  }, [initial, statusOptions, removedOptions]);

  return (
    <Modal show={show} onHide={close} size={"sm"}>
      <Card>
        <Modal.Header>
          <Modal.Title>
            <CustomText fontFamily={"Poppins"} fontSize={16} fontWeight={600} text={"Update Transaction Status"} cNColor="primary" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomInput
            label="Transaction Status"
            inputType="select"
            selectOptions={filteredOptions}
            onChange={(e) => setStatus(e)}
            value={status}
            defaultValue={statusOptions.find((x) => x.value == initial)}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <CustomButton
            variant="secondary"
            type="submit"
            //   disabled={!formik?.isValid}
            text="Cancel"
            onClick={close}
            // loading={}
          />

          <CustomButton
            variant="primary"
            type="submit"
            text="Confirm"
            onClick={() => {
              onConfirm(getValue(status));
            }}
            disabled={!filteredOptions?.find((x) => x.value == getValue(status))}
            loading={isLoading}
          />
        </Modal.Footer>
      </Card>
    </Modal>
  );
};
