import React from "react";
import { Row, Col, Button, ListGroup } from "react-bootstrap";

const Pagination = ({ previousPage, pageCount, pageIndex, gotoPage, nextPage }) => {
  const pagesToShow = 5; // Number of pages to display
  const range = Math.floor(pagesToShow / 2); // Half the range of pages to display

  let startPage = Math.max(0, pageIndex - range);
  let endPage = Math.min(pageCount - 1, pageIndex + range);

  if (startPage === 0) {
    endPage = Math.min(pageCount - 1, startPage + pagesToShow - 1);
  }

  if (endPage === pageCount - 1) {
    startPage = Math.max(0, endPage - pagesToShow + 1);
  }

  const renderEllipsis = (key) => (
    <ListGroup.Item as="li" bsPrefix="page-item" key={key} disabled>
      <span className="page-link mx-1 rounded">&#8230;</span>
    </ListGroup.Item>
  );

  const renderPageButton = (page) => (
    <ListGroup.Item as="li" bsPrefix="page-item" key={page} className={`page-item ${pageIndex === page ? "active" : ""}`}>
      <Button className="page-link mx-1 rounded" onClick={() => gotoPage(page)}>
        {page + 1}
      </Button>
    </ListGroup.Item>
  );

  return (
    <Row>
      <Col lg={12} md={12} sm={12}>
        <div className="pb-5">
          <nav>
            <ListGroup as="ul" bsPrefix="pagination" className="justify-content-center mb-0">
              <ListGroup.Item as="li" bsPrefix="page-item">
                <Button onClick={() => previousPage()} className="page-link mx-1 rounded">
                  <i className="fe fe-chevron-left"></i>
                </Button>
              </ListGroup.Item>

              {startPage > 0 && renderEllipsis("ellipsis-start")}

              {Array.from(Array(endPage - startPage + 1).keys())
                .map((i) => startPage + i)
                .map((page) => renderPageButton(page))}

              {endPage < pageCount - 1 && renderEllipsis("ellipsis-end")}

              <ListGroup.Item as="li" bsPrefix="page-item">
                <Button onClick={() => nextPage()} className="page-link mx-1 rounded">
                  <i className="fe fe-chevron-right"></i>
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </nav>
        </div>
      </Col>
    </Row>
  );
};

export default Pagination;
