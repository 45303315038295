import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { toast } from "react-toastify";
import { useCreateMarketMutation, useGetCountriesMutation } from "redux/features/user/userApi";
import { useSelector } from "react-redux";
import { saveCountries } from "redux/features/auth/authSlice";
import { useDispatch } from "react-redux";

export const AddNewMarket = ({ showNew, handleCloseAddNew }) => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.userAuth);
  const [refresh, setRefresh] = useState(false);

  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();

  const validationSchema = Yup.object().shape({
    contact_name: Yup.string().required("Required").typeError("Must be text"),
    contact_phone: Yup.string().required("Required").typeError("Must be text"),
    name: Yup.string().required("Required").typeError("Must be text"),
    country: Yup.string().required("Required").typeError("Must be text"),
    state: Yup.string().required("Required").typeError("Must be text"),
    delivery_fee: Yup.number().required("Required").typeError("Must be text"),
    minimum_order_value: Yup.number().required("Required").typeError("Must be text"),
    service_charge: Yup.number().required("Required").typeError("Must be text"),
    shopper_commission: Yup.number().min(0).max(80).required("Required").typeError("Must be text").label("Shopper Commission(%)"),
    status: Yup.boolean().required("Required").typeError("Must be text"),
    pickup_available: Yup.boolean().required("Required").typeError("Must be True/False"),
    pickup_station_city: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_state: Yup.mixed().when("pickup_available", {
      is: true,
      then: Yup.mixed().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_country: Yup.mixed().when("pickup_available", {
      is: true,
      then: Yup.mixed().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_name: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_description: Yup.string().typeError("Must be text"),
    pickup_station_street_address: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_apartment: Yup.string(),
    pickup_station_additional_info: Yup.string(),
    pickup_station_postal_code: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_contact_name: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_contact_phone_prefix: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().min(2).max(4).required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_contact_phone: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().min(10).max(11).required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
  });
  const formik = useFormik({
    initialValues: {
      contact_name: "",
      contact_phone: "",
      name: "",
      country: "",
      state: "",
      delivery_fee: "",
      minimum_order_value: "",
      shopper_commission: "",
      service_charge: "",
      status: "",
      pickup_available: false,
      pickup_station_city: "",
      pickup_station_state: "",
      pickup_station_country: "",
      pickup_station_name: "",
      pickup_station_description: "",
      pickup_station_street_address: "",
      pickup_station_apartment: "",
      pickup_station_additional_info: "",
      pickup_station_postal_code: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      const extrPickupCont = countries?.find((x) => x?.id == values?.pickup_station_country);
      const extrPickupState = countries?.find((x) => x?.id == values?.pickup_station_state);
      const extrCont = countries?.find((x) => x?.id == values?.country);
      let country_name = extrCont?.name;
      let country_code = extrCont?.country_code;
      let pickup_station_country_name = extrPickupCont?.name;
      let pickup_station_country_code = extrPickupCont?.country_code;
      const extrState = extrCont.states?.find((x) => x?._id == values?.state);
      let state_name = extrState?.name;
      let state_code = extrState?.state_code;
      let pickup_station_state_name = extrPickupState?.name;
      let pickup_station_state_code = extrPickupState?.state_code;
      // const { name: state_name, state_code } = extrState;
      if (!values?.pickup_available) {
        delete values?.pickup_station_state;
        delete values?.pickup_station_country;
      }
      const resolveValues = {
        ...values,
        country_name,
        country_code,
        state_name,
        state_code,
        pickup_station_country_name,
        pickup_station_country_code,
        pickup_station_state_name,
        pickup_station_state_code,
      };
      handleAddNew(resolveValues);
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "pickup_available",
      !!formik.values?.pickup_station_name ||
        !!formik.values?.pickup_station_additional_info ||
        !!formik.values?.pickup_station_description ||
        !!formik.values?.pickup_station_apartment ||
        !!formik.values?.pickup_station_street_address ||
        !!formik.values?.pickup_station_country ||
        !!formik.values?.pickup_station_city ||
        !!formik.values?.pickup_station_state ||
        !!formik.values?.pickup_station_contact_name ||
        !!formik.values?.pickup_station_contact_phone ||
        !!formik.values?.pickup_station_postal_code,
    );
  }, [
    formik.values?.pickup_station_name,
    formik.values?.pickup_station_additional_info,
    formik.values?.pickup_station_description,
    formik.values?.pickup_station_apartment,
    formik.values?.pickup_station_street_address,
    formik.values?.pickup_station_country,
    formik.values?.pickup_station_city,
    formik.values?.pickup_station_state,
    formik.values?.pickup_station_contact_name,
    formik.values?.pickup_station_contact_phone,
    formik.values?.pickup_station_postal_code,
  ]);

  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchCountries();
  }, [refresh]);

  const [createMarket, { isLoading }] = useCreateMarketMutation();

  const handleAddNew = async (values) => {
    const res = await createMarket(values);
    if (res?.data?.status) {
      handleCloseAddNew();
      toast.success(res?.data?.message || "Created successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };

  const stateOptions = useMemo(() => {
    const extrCont = countries?.find((x) => x?.id == formik?.values?.country);
    let filtered;
    if (extrCont) {
      filtered = extrCont?.states?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      });
    }
    return filtered || [];
  }, [formik?.values?.country, countries]);

  const countryOptions = useMemo(() => {
    let filtered = countries?.map((x) => {
      return { label: x?.name, value: x?._id, id: x?._id };
    });
    return filtered;
  }, [countries]);

  return (
    <Modal show={showNew} onHide={handleCloseAddNew}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Add New Market"} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Name"}
                id={"name"}
                placeholder={"Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.name}
                value={formik?.values?.name}
              />
              <CustomInput
                as={"select"}
                inputType={"select"}
                selectOptions={countryOptions}
                required={true}
                label={"Country"}
                id={"country"}
                name={"country"}
                placeholder={"Country"}
                onChange={(e) => {
                  formik.setFieldValue("state", "");
                  formik.setFieldValue("country", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.country}
                value={formik.values.country}
              />
              <CustomInput
                as={"select"}
                inputType={"select"}
                selectOptions={stateOptions}
                required={true}
                label={"State"}
                id={"state"}
                name={"state"}
                placeholder={"State"}
                onChange={(e) => {
                  formik.setFieldValue("state", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.state}
                value={formik.values.state}
              />

              <CustomInput
                type={"text"}
                required={true}
                label={"Pickup Station Name"}
                id={"pickup_station_name"}
                placeholder={"Pickup Station Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.pickup_station_name}
                value={formik?.values?.pickup_station_name}
              />
              <CustomInput
                type={"text"}
                required={true}
                label={"Pickup Station Description"}
                id={"pickup_station_description"}
                placeholder={"Pickup Station Description"}
                onChange={formik?.handleChange}
                error={formik?.errors?.pickup_station_description}
                value={formik?.values?.pickup_station_description}
              />
              <CustomInput
                type={"text"}
                required={true}
                label={"Pickup Street Address"}
                id={"pickup_station_street_address"}
                placeholder={"Pickup Street Address"}
                onChange={formik?.handleChange}
                error={formik?.errors?.pickup_station_street_address}
                value={formik?.values?.pickup_station_street_address}
              />
              <CustomInput
                type={"text"}
                required={true}
                label={"Pickup Apartment/Suite (Optional)"}
                id={"pickup_apartment"}
                placeholder={"Pickup Apartment/Suite (Optional)"}
                onChange={formik?.handleChange}
                error={formik?.errors?.pickup_station_apartment}
                value={formik?.values?.pickup_station_apartment}
              />
              <CustomInput
                type={"text"}
                required={true}
                label={"Pickup Additional Info (Optional)"}
                id={"pickup_station_additional_info"}
                placeholder={"Pickup Additional Info (Optional)"}
                onChange={formik?.handleChange}
                error={formik?.errors?.pickup_station_additional_info}
                value={formik?.values?.pickup_station_additional_info}
              />
              <CustomInput
                as={"select"}
                inputType={"select"}
                selectOptions={countryOptions}
                required={true}
                label={"Pickup Station Country"}
                id={"pickup_station_country"}
                name={"pickup_station_country"}
                placeholder={"Pickup Station Country"}
                onChange={(e) => {
                  formik.setFieldValue("pickup_station_state", "");
                  formik.setFieldValue("pickup_station_country", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.pickup_station_country}
                value={formik.values.pickup_station_country}
              />
              <CustomInput
                as={"select"}
                inputType={"select"}
                selectOptions={stateOptions}
                required={true}
                label={"Pickup Station State"}
                id={"pickup_station_state"}
                name={"pickup_station_state"}
                placeholder={"Pickup Station State"}
                onChange={(e) => {
                  formik.setFieldValue("pickup_station_state", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.pickup_station_state}
                value={formik.values.pickup_station_state}
              />
              <CustomInput
                type={"text"}
                required={true}
                label={"Pickup Station City (Optional)"}
                id={"pickup_station_city"}
                placeholder={"Pickup Station City (Optional)"}
                onChange={formik?.handleChange}
                error={formik?.errors?.pickup_station_city}
                value={formik?.values?.pickup_station_city}
              />
              <CustomInput
                type={"number"}
                required={true}
                label={"Delivery fee"}
                id={"delivery_fee"}
                placeholder={"Delivery fee"}
                onChange={formik?.handleChange}
                error={formik?.errors?.delivery_fee}
                value={formik?.values?.delivery_fee}
              />
              <CustomInput
                type={"number"}
                required={true}
                label={"Minimum Order Value"}
                id={"minimum_order_value"}
                placeholder={"Minimum Order Value"}
                onChange={formik?.handleChange}
                error={formik?.errors?.minimum_order_value}
                value={formik?.values?.minimum_order_value}
              />
              <CustomInput
                type={"number"}
                required={true}
                label={"Shopper Commission(%)"}
                id={"shopper_commission"}
                placeholder={"Shopper Commission(%)"}
                onChange={formik?.handleChange}
                error={formik?.errors?.shopper_commission}
                value={formik?.values?.shopper_commission}
              />
              <CustomInput
                type={"number"}
                required={true}
                label={"Service Charge"}
                id={"service_charge"}
                placeholder={"Service Charge"}
                onChange={formik?.handleChange}
                error={formik?.errors?.service_charge}
                value={formik?.values?.service_charge}
              />
              <CustomInput
                inputType={"select"}
                selectOptions={[
                  { label: "Enabled", value: true, id: "true" },
                  { label: "Disabled", value: false, id: "false" },
                ]}
                required={true}
                label={"Status"}
                id={"status"}
                name={"status"}
                placeholder={"Status"}
                onChange={(e) => {
                  formik.setFieldValue("status", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.status}
                value={formik.values.status}
              />
              <CustomInput
                type={"text"}
                required={true}
                label={"Contact Name"}
                id={"contact_name"}
                placeholder={"Contact Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.contact_name}
                value={formik?.values?.contact_name}
              />
              <CustomInput
                type={"text"}
                required={true}
                label={"Contact Phone"}
                id={"contact_phone"}
                placeholder={"Contact Phone"}
                onChange={formik?.handleChange}
                error={formik?.errors?.contact_phone}
                value={formik?.values?.contact_phone}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={isLoading || !formik?.isValid}
          button2onClick={formik.handleSubmit}
          button2Text={"Create"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading}
          button1Text={"Cancel"}
          button1onClick={handleCloseAddNew}
        />
      </Modal.Footer>
    </Modal>
  );
};
