import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useHistory } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination, useRowSelect } from "react-table";
import Pagination from "./advance-table/Pagination";
import { matchSorterFn } from "./advance-table/Sorting";
import CustomButton from "./CustomButton";
import NoData from "./Nodata";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { TableHeader } from "./TableHeader";
// utilities
/*
 **props
 ** excelFileName, excelSheetName, data, columns, title,
 */
const DataTable = (props) => {
  // MEMOS
  const data = useMemo(() => props?.data, [props?.data]);
  const columns = useMemo(() => props?.columns, [props?.columns]);
  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: "",
    }),
    [],
  );
  const filterTypes = useMemo(
    () => ({
      rankedMatchSorter: matchSorterFn,
    }),
    [],
  );

  const history = useHistory();

  // CONFIGURE useTable
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      // pageSize: pagesize,
      initialState: {
        pageSize: 10,
        selectedRowIds: [],
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      // Add a checkbox column
      {
        !props?.nonSelective &&
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <input type="checkbox" {...getToggleAllRowsSelectedProps()} style={{ transform: "scale(1.5)" }} />
              ),
              Cell: ({ row }) => <input type="checkbox" {...row.getToggleRowSelectedProps()} style={{ transform: "scale(1.5)" }} />,
            },
            ...columns,
          ]);
      }
    },
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  const handleRowClick = (values) => {
    history.push(props?.detailsRedirect?.replace(":id", values?.id), values);
  };
  useEffect(() => {
    props?.setSelectedRows(selectedFlatRows.map((row) => row.original));
  }, [selectedFlatRows]);

  // RENDERING
  return (
    <Card className="mt-2">
      <TableHeader
        title={props?.title}
        data={data}
        searchable={true}
        columns={columns}
        paginated={true}
        globalFilter={globalFilter}
        setFilter={setFilter}
        setGlobalFilter={setGlobalFilter}
        currentPageSize={currentPageSize}
        setCurrentPageSize={setCurrentPageSize}
        setPageSize={setPageSize}
        pageSizeOptions={pageSizeOptions}
        actionButtons={true}
        actionButton1={props?.externalActionButton1}
        actionButton2={
          <DownloadTableExcel filename={props?.excelFileName} sheet={props?.excelSheetName} currentTableRef={tableRef.current}>
            <CustomButton variant="primary" type="button" text="Export" />
          </DownloadTableExcel>
        }
        actionButton3={props?.externalActionButton}
      />
      <Col>
        <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
          {data.length > 0 ? (
            <>
              <thead className="table-light text-center">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black ">
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDownSvg style={{ height: 20 }} />
                          ) : (
                            <SortUpSvg style={{ height: 20 }} />
                          )
                        ) : (
                          ""
                        )}
                        <div>{column.canFilter ? column.render("Filter") : null}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="text-center">
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <NoData />
          )}
        </Table>
        {data.length > 0 ? (
          <Pagination previousPage={previousPage} pageCount={pageCount} pageIndex={pageIndex} gotoPage={gotoPage} nextPage={nextPage} />
        ) : (
          <></>
        )}
      </Col>
    </Card>
  );
};

export default DataTable;
