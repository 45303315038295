import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useMemo } from "react";
import { saveBanks, saveCountries, saveMarkets } from "redux/features/auth/authSlice";
import {
  useCreateVendorMutation,
  useGetBanksMutation,
  useGetMarketsMutation,
  useUpdateVendorMutation,
  useVerifyAccountNumberMutation,
} from "redux/features/user/userApi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Cloudinary } from "@cloudinary/url-gen";
import { CustomText } from "components/CustomText";
import { getValue, removeKeysWithValues } from "helper/formatObject";
import { toast } from "react-toastify";

export const AddVendor = ({ showAdd, handleCloseAdd, type = "add", initialValues }) => {
  const [statusOptions] = useState([
    { id: "1", label: "Active", value: "active" },
    { id: "2", label: "Inactive", value: "inactive" },
  ]);
  const [genderOptions] = useState([
    { id: "1", label: "Male", value: "Male" },
    { id: "2", label: "Female", value: "Female" },
  ]);
  const [accountVerifiedOptions] = useState([
    { id: "1", label: "Verified", value: true },
    { id: "2", label: "Unverified", value: false },
  ]);

  const dispatch = useDispatch();
  const { markets, countries, banks } = useSelector((state) => state.userAuth);

  const [bankOptions, setBankOptions] = useState(
    banks?.map((bank) => {
      return { label: bank?.name, value: bank?.code, id: bank?._id };
    }),
  );
  const [marketOptions, setMarketOptions] = useState(
    markets?.map((market) => {
      return { label: `${market?.name} (${market?.state_name} - ${market?.country_name})`, value: market._id, id: market?.market_code };
    }),
  );

  const [getMarkets, { isLoading: isLoadingMarkets }] = useGetMarketsMutation();
  const [getBanks, { isLoading: isLoadingBanks }] = useGetBanksMutation();

  const fetchMarkets = async () => {
    const res = await getMarkets();
    if (res?.data?.status === "success") {
      dispatch(saveMarkets(res?.data?.data));
      setMarketOptions(
        res?.data?.data
          ?.filter((x) => x?.country_name?.toLowerCase()?.includes("nigeria"))
          ?.filter((x) => !x?.name?.toLowerCase()?.includes("default"))
          ?.map((market) => {
            return {
              label: `${market?.name} (${market?.state_name} - ${market?.country_name})`,
              value: market._id,
              id: market?.market_code,
            };
          }),
      );
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };
  const fetchBanks = async () => {
    const res = await getBanks();
    if (res?.data?.status === "success") {
      dispatch(saveBanks(res?.data?.data?.data));
      setBankOptions(
        res?.data?.data?.data?.map((bank) => {
          return { label: bank?.name, value: bank?.code, id: bank?._id };
        }),
      );
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchMarkets();
    fetchBanks();
  }, []);

  const [createVendor, { isLoading }] = useCreateVendorMutation();
  const [updateVendor, { isLoading: isUpdating }] = useUpdateVendorMutation();

  const handleCreate = async (values) => {
    const res = await createVendor(values);
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Vendor created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseAdd();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleEdit = async (values) => {
    const resolveValues = values;
    const res = await updateVendor({ id: initialValues?.id, data: resolveValues });
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Vendor updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseAdd();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phoneNumber: Yup.number(),
    gender: Yup.mixed(),
    status: Yup.mixed().required("Required"),
    bank_code: Yup.string(),
    bank_name: Yup.mixed().required("Required"),
    account_name: Yup.string(),
    account_number: Yup.string().required("Required"),
    market_id: Yup.mixed().required("Required"),
    market_number: Yup.number().required("Required"),
    vendor_number: Yup.number().required("Required"),
    bank_details_verified: Yup.boolean().required().oneOf([true], "Bank details is unverified"),
    account_verified: Yup.mixed().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: initialValues.firstName || "",
      lastName: initialValues.lastName || "",
      phoneNumber: initialValues.phoneNumber || "",
      gender: genderOptions?.find((x) => x?.value == initialValues.gender) || "",
      status: statusOptions?.find((x) => x?.value == initialValues.status) || statusOptions[0],
      market_id: marketOptions?.find((x) => x?.value == initialValues.market_id?._id) || "",
      market_number: parseInt(initialValues.market_id?.market_code?.split("/").pop()) || "",
      vendor_number: parseInt(initialValues?.vendor_code?.split("/").pop()) || "",
      account_name: initialValues?.account_name || "",
      account_number: initialValues?.account_number || "",
      bank_code: initialValues.bank_code || "",
      bank_name: bankOptions?.find((x) => x?.label == initialValues.bank_name) || "",
      bank_details_verified: initialValues?.bank_details_verified || false,
      account_verified: accountVerifiedOptions?.find((x) => x?.value == initialValues.account_verified) || accountVerifiedOptions[0],
    },
    enableReinitialize: true,
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const resolveValues = removeKeysWithValues(
        {
          ...values,
          market_number: (values?.market_number).toString(),
          vendor_number: (values?.vendor_number).toString(),
          market_id: getValue(values?.market_id),
          bank_name: getValue(values?.bank_name, "label"),
          status: getValue(values?.status),
          gender: getValue(values?.gender),
          account_verified: getValue(values?.account_verified),
        },
        [null, ""],
      );
      if (formik?.values?.account_name?.length > 1 && formik?.values?.bank_code?.length > 1) {
        type === "add" ? handleCreate(resolveValues) : handleEdit(resolveValues);
      } else {
        formik.setFieldError("account_name", "Account name is required");
        formik.setFieldError("bank_code", "Invalid bank selection, select again");
      }
    },
  });

  const handleAddImageUrl = (url) => {
    formik.setFieldValue("identity_card", formik?.values?.identity_card?.length > 0 ? [...formik?.values?.identity_card, ...url] : url);
  };

  const handleRemoveImageUrl = (index) => {
    const newImageUrls = [...formik?.values?.identity_card];
    newImageUrls?.splice(index, 1);
    formik?.setFieldValue("identity_card", newImageUrls);
  };

  const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_API_KEY, REACT_APP_CLOUDINARY_SECRET } = process.env;

  const cld = new Cloudinary({
    cloud: {
      cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
      apiKey: REACT_APP_CLOUDINARY_API_KEY,
      apiSecret: REACT_APP_CLOUDINARY_SECRET,
    },
  });

  const handleImageUpload = async (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "hlx4uinq");

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cld?.cloudinaryConfig?.cloud?.cloudName}/image/upload`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    formik.setFieldValue("passport", data.secure_url);
  };
  const handleImagesUpload = async (event) => {
    const formData = new FormData();
    const files = event.target.files;
    const urls = [];

    for (let i = 0; i < files?.length; i++) {
      formData.append("file", files[i]);
      formData.append("upload_preset", "hlx4uinq");

      const response = await fetch(`https://api.cloudinary.com/v1_1/${cld?.cloudinaryConfig?.cloud?.cloudName}/image/upload`, {
        method: "POST",
        body: formData,
      });

      const data = await response?.json();
      urls.push(data?.secure_url);
      formData.delete("file");
    }

    handleAddImageUrl(urls);
  };

  const [verifyAccountNumber, { isLoading: isLoadingVerifyAccountNumber }] = useVerifyAccountNumberMutation();

  useEffect(() => {
    formik?.values?.account_number?.length == 10 && handleVerifyAccountNumber();
  }, [formik?.values?.bank_code, formik?.values?.account_number]);

  const handleVerifyAccountNumber = async () => {
    await handleAccountName("");
    await handleBankDetailStatus(false);
    const res = await verifyAccountNumber({ bank_code: formik?.values?.bank_code, account_number: formik.values?.account_number });
    if (res?.data?.status === "success") {
      await handleAccountName(res?.data?.name);
      await handleBankDetailStatus(true);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        type: "error",
        placement: "top",
        duration: 4000,
      });
    }
  };

  const handleAccountName = async (e) => {
    await formik.setFieldValue("account_name", e);
  };
  const handleBankDetailStatus = async (e) => {
    await formik.setFieldValue("bank_details_verified", e);
  };
  const handleMarketNumber = async (e) => {
    await formik.setFieldValue("market_id", e?.value);
    const parts = e?.id?.split("/");
    const lastPart = parts[parts.length - 1];
    const lastNumber = parseInt(lastPart);
    await formik.setFieldValue("market_number", lastNumber);
  };

  return (
    <Modal show={showAdd} onHide={handleCloseAdd} size="lg">
      <Modal.Header id="comptitle">
        <CustomModalHeading title={type == "add" ? "Add new Vendor" : "Edit Vendor"} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} mb={2} text="Identity & Bank information" />

              <CustomInput
                type={"number"}
                required={true}
                label={"Vendor Number"}
                id={"vendor_number"}
                placeholder={"Vendor Number"}
                onChange={formik?.handleChange}
                error={formik?.errors?.vendor_number}
                value={formik?.values?.vendor_number}
              />
              <CustomInput
                inputType={"select"}
                returnObj={true}
                selectOptions={bankOptions}
                required={true}
                id={"bank_name"}
                label={"Bank"}
                placeholder={"Bank"}
                onChange={(e) => {
                  formik.setFieldValue("bank_code", e?.value);
                  formik.setFieldValue("bank_name", e?.label);
                }}
                error={formik?.errors?.bank_name}
                value={formik?.values?.bank_name}
              />

              <CustomInput
                type={"text"}
                required={true}
                label={"Account Number"}
                id={"account_number"}
                placeholder={"Account Number"}
                onChange={formik?.handleChange}
                error={formik?.errors?.account_number || formik?.errors?.account_name}
                value={formik?.values?.account_number}
              />
              <CustomText
                fontFamily={"Montserrat"}
                fontSize={14}
                mb={2}
                text={isLoadingVerifyAccountNumber ? "verifying..." : formik?.values?.account_name || "Invalid details"}
              />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomText fontFamily={"Montserrat"} fontSize={16} mb={2} fontWeight={600} text="Market information" />

              <CustomInput
                inputType={"select"}
                selectOptions={marketOptions}
                required={true}
                returnObj={true}
                label={"Market of Operation"}
                id={"market_id"}
                placeholder={"Market of Operation"}
                onChange={(e) => {
                  handleMarketNumber(e);
                }}
                error={formik?.errors?.market_id || formik?.errors?.market_number}
                value={formik?.values?.market_id}
              />

              <CustomInput
                inputType={"select"}
                selectOptions={accountVerifiedOptions}
                required={true}
                label={"Verification status"}
                id={"account_verified"}
                placeholder={"Is Account Details Verified"}
                onChange={(e) => {
                  formik.setFieldValue("account_verified", e);
                }}
                error={formik?.errors?.account_verified}
                value={formik?.values?.account_verified}
              />
              <CustomInput
                inputType={"select"}
                selectOptions={statusOptions}
                required={true}
                label={"Status"}
                id={"status"}
                placeholder={"Status"}
                onChange={(e) => {
                  formik.setFieldValue("status", e);
                }}
                error={formik?.errors?.status}
                value={formik?.values?.status}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <CustomText fontFamily={"Montserrat"} fontSize={16} mb={2} fontWeight={600} text="Personal information" />

            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"First Name"}
                id={"firstName"}
                placeholder={"First name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.firstName}
                value={formik?.values?.firstName}
              />

              <CustomInput
                type={"text"}
                required={true}
                label={"Last Name"}
                id={"lastName"}
                placeholder={"Last name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.lastName}
                value={formik?.values?.lastName}
              />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Phone Number"}
                id={"phoneNumber"}
                placeholder={"Phone Number"}
                onChange={formik?.handleChange}
                error={formik?.errors?.phoneNumber}
                value={formik?.values?.phoneNumber}
              />
              <CustomInput
                inputType={"select"}
                selectOptions={genderOptions}
                required={true}
                label={"Gender"}
                id={"gender"}
                placeholder={"Gender"}
                onChange={(e) => {
                  formik.setFieldValue("gender", e);
                }}
                error={formik?.errors?.gender}
                value={formik?.values?.gender}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading || isUpdating}
          button2onClick={formik.handleSubmit}
          button2disabled={!formik?.isValid || isLoading || isUpdating}
          button2Text={type == "add" ? "Create" : "Update"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading || isUpdating}
          button1Text={"Cancel"}
          button1onClick={handleCloseAdd}
        />
      </Modal.Footer>
    </Modal>
  );
};
