import CustomButton from "components/CustomButton";
import CustomDetailGroup from "components/CustomDetailGroup";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { capitalize, formatCurrency } from "helper/formatText";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import tomatopng from "../../assets/images/png/tomato.png";
const TransactionDetails = ({ location, match }) => {
  const history = useHistory();
  const [show, Setshow] = useState(false);
  const [details, setDetails] = useState(location?.state);
  const handleShow = () => {
    Setshow(true);
  };
  const handleClose = () => {
    Setshow(false);
  };

  useEffect(() => {
    !location?.state && history.goBack();
  }, [location]);

  return (
    <Row>
      <Card>
        <Row id="comptitle">
          <Col className=" p-3 d-flex align-items-center justify-content-between">
            <Col>
              <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={`${details?.name}`} cNColor="primary" />
            </Col>
            <Col className="d-flex justify-content-end align-items-center gap-3">
              <CustomButton
                variant="primary"
                type="submit"
                text="Update"
                onClick={handleShow}
                // loading={}
              />
            </Col>
          </Col>
        </Row>

        <Row className="py-1">
          <CustomDetailGroup itemKey="Status:" itemValue={capitalize(details?.status)} />
          <CustomDetailGroup
            itemKey="Amount:"
            itemValue={formatCurrency(
              details?.amount,
              2,
              details?.type == "order_payment" ? JSON.parse(details?.order_id?.country)?.currency?.symbol : undefined,
            )}
          />
          <CustomDetailGroup itemKey="Transaction Reference:" itemValue={details?.reference} />
          <CustomDetailGroup itemKey="Order ID:" itemValue={details?.order_id?.order_id} />
          <CustomDetailGroup itemKey="Order Status:" itemValue={capitalize(details?.order_id?.order_status)} />
          <CustomDetailGroup itemKey="Payment Method" itemValue={details?.payment_gateway} valueClassName={"status"} />
          <CustomDetailGroup itemKey="Entry" itemValue={capitalize(details?.entry)} />
          <CustomText
            fontFamily={"Poppins"}
            fontSize={14}
            fontWeight={500}
            text={details?.entry == "debit" ? "Recipient Details" : "Sender Details"}
            cNColor="primary"
            textStyle={{ marginLeft: 12, marginTop: 10, marginBottom: 10 }}
          />
          <CustomDetailGroup
            className={"px-4 d-flex gap-3"}
            itemKey="Name"
            itemValue={details?.user_id?.firstName + " " + details?.user_id?.lastName}
          />

          <CustomDetailGroup
            className={details?.user_id?.email ? "px-4 d-flex gap-3" : "px-4 d-flex gap-3 border-bottom"}
            itemKey="Phone number"
            itemValue={details?.user_id?.phoneNumber}
          />
          {details?.user_id?.email && (
            <CustomDetailGroup className={"px-4 d-flex gap-3 border-bottom"} itemKey="Email address" itemValue={details?.user_id?.email} />
          )}
          {details?.user_id?.account_number && (
            <>
              <CustomText
                fontFamily={"Poppins"}
                fontSize={14}
                fontWeight={500}
                text="Bank Details"
                cNColor="primary"
                textStyle={{ marginLeft: 12, marginTop: 10, marginBottom: 10 }}
              />

              <CustomDetailGroup itemKey="Account number" itemValue={details?.user_id?.account_number} className={"px-4 d-flex gap-3"} />
              <CustomDetailGroup itemKey="Account name" itemValue={details?.user_id?.account_name} className={"px-4 d-flex gap-3"} />
              <CustomDetailGroup itemKey="Bank" itemValue={details?.user_id?.bank_name} className={"px-4 d-flex gap-3 border-bottom"} />
            </>
          )}
          <CustomDetailGroup itemKey="Date Initiated" itemValue={moment(details?.createdAt).format("ddd MMM DD, yyyy")} />
          <CustomDetailGroup itemKey="Date Updated" itemValue={moment(details?.updatedAt).format("ddd MMM DD, yyyy")} />
        </Row>
      </Card>
    </Row>
  );
};

export default TransactionDetails;
