// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link, useHistory } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Tab, Nav, Spinner, FormSelect } from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";
// import Category from './../../componentsx/dashboard/cms/Category
// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import { AssignShopper } from "./components/AssignShopper";
import CustomButton from "components/CustomButton";
import NoData from "components/Nodata";
import Loader from "components/Loader";
import moment from "moment";
import { convertJsonStringToObject } from "helper/formatObject";
import { isSubstringInArray, sortedDataByUpdatedAt } from "helper/formatArray";
import { capitalize, formatCurrency } from "helper/formatText";
import { useGetCountriesMutation, useGetOrdersMutation } from "redux/features/user/userApi";
import { useSelector } from "react-redux";
import { saveCountries } from "redux/features/auth/authSlice";
import { TableHeader } from "components/TableHeader";
import { DownloadTableExcel } from "react-export-table-to-excel";

const LocalOrders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { countries } = useSelector((state) => state.userAuth);
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [stats, setStats] = useState({ open: 0, ongoing: 0, completedThisMonth: 0 });

  const [getOrders, { isLoading }] = useGetOrdersMutation();

  const fetchList = async () => {
    const res = await getOrders();
    if (res?.data?.status === "success") {
      setList(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      // { accessor: "id", Header: "ID" },
      {
        accessor: "order_id",
        Header: "ID",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit " to={{ pathname: `/orders/${row?.original?.id}`, state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "order_status",
        Header: "Order Status",
        Cell: ({ value }) => {
          return (
            <p
              className={`mb-0`}
              style={{
                color:
                  value == "delivered"
                    ? "#039D00"
                    : isSubstringInArray(value, ["intransit", "shopped", "accepted"])
                    ? "#039D00"
                    : "#DC0000",
                textTransform: "capitalize",
              }}
            >
              {value?.replace("_", " ")}
            </p>
          );
        },
      },
      {
        accessor: "market",
        Header: "Market",
        Cell: ({ value }) => {
          return `${value?.name} (${value?.state_name} - ${value?.country_name})`;
        },
      },

      {
        accessor: "payment_status",
        Header: "Payment Status",
      },
      {
        accessor: "delivery_address",
        Header: "Delivery Address",
        Cell: ({ value }) => {
          return `${value?.city} (${value?.state} - ${value?.country})`;
        },
      },
      {
        accessor: "customer",
        Header: "Customer",
        Cell: ({ value }) => {
          return `${capitalize(value?.firstName)} ${capitalize(value?.lastName)} (${value?.phoneNumber})`;
        },
      },
      {
        accessor: "total_item_amount",
        Header: "Total Item Amount",
        Cell: ({ value }) => {
          return formatCurrency(value, 2);
        },
      },
      {
        accessor: "total_amount",
        Header: "Total Amount",
        Cell: ({ value }) => {
          return formatCurrency(value, 2);
        },
      },
      {
        accessor: "updatedAt",
        Header: "Date Updated",
        Cell: ({ value }) => {
          return moment(value).format("ddd MMM DD, yyyy");
        },
      },
      {
        accessor: "createdAt",
        Header: "Date Created",
        Cell: ({ value }) => {
          return moment(value).format("ddd MMM DD, yyyy");
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = list || [];
    filtered = sortedDataByUpdatedAt(filtered);

    if (filtered?.length > 0) {
      filtered = filtered?.map((x, index) => {
        return convertJsonStringToObject(JSON.parse(JSON.stringify(x)));
      });
      filtered = filtered?.filter((x) => x?.country?.name?.toLowerCase()?.includes("nigeria"));
      filtered = filtered?.map((x, index) => {
        return {
          ...x,
          sn: index + 1,
        };
      });
      setStats({
        open: filtered?.filter((x) => x?.order_status == "open")?.length,
        ongoing: filtered?.filter((x) => isSubstringInArray(x?.order_status, ["intransit", "shopped", "accepted"]))?.length,
        completedThisMonth: filtered
          ?.filter((x) => isSubstringInArray(x?.order_status, ["delivered", "completed"]))
          ?.filter((x) => moment(x?.createdAt).isSame(new Date(), "month"))?.length,
      });
    }
    return filtered;
  }, [list, countries]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: "#e0bf00" }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <div className="overflow-hidden justify-content-space-around">
        <Row>
          <Col xl={4} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Open Orders"
              value={stats.open}
              iconComponent={<StoreCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={4} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Ongoing Orders"
              value={stats.ongoing}
              iconComponent={<StoreCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={4} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Completed this month"
              value={stats.completedThisMonth}
              iconComponent={<StoreCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card>
              <Card.Body className="p-0">
                <TableHeader
                  title={"Local Orders"}
                  data={data}
                  searchable={true}
                  paginated={true}
                  actionButtons={true}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  currentPageSize={currentPageSize}
                  setCurrentPageSize={setCurrentPageSize}
                  setPageSize={setPageSize}
                  pageSizeOptions={pageSizeOptions}
                  // actionButton1={
                  //   <CustomButton
                  //     variant="primary"
                  //     type="button"
                  //     text="Add New Order"
                  //     className={"text-nowrap"}
                  //     onClick={() => history.push("/orders/add-new-order")}
                  //   />
                  // }
                  actionButton2={
                    <DownloadTableExcel filename="FD_LocalOrders" sheet="LocalOrders" currentTableRef={tableRef.current}>
                      <CustomButton variant="primary" type="button" text="Export" />
                    </DownloadTableExcel>
                  }
                />
                <div
                  className="table-responsive"
                  // style={{position: 'relative', zIndex: 9999}}
                >
                  <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                    {data.length > 0 ? (
                      <>
                        <thead className="table-light text-center">
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                  {column.render("Header")}

                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <SortDownSvg style={{ height: 20 }} />
                                    ) : (
                                      <SortUpSvg style={{ height: 20 }} />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()} className="text-center align-item-center">
                                {row.cells.map((cell) => {
                                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </Table>
                  {/* Pagination @ Footer */}

                  {data.length > 0 ? (
                    <Pagination
                      previousPage={previousPage}
                      pageCount={pageCount}
                      pageIndex={pageIndex}
                      gotoPage={gotoPage}
                      nextPage={nextPage}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>

      <ToastContainer />
    </Fragment>
  );
};

export default LocalOrders;
