import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Card, Col, Table } from "react-bootstrap";
import { useMemo, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { capitalize, formatCurrency } from "helper/formatText";
import { useSelector } from "react-redux";
import { TableHeader } from "./TableHeader";
import Pagination from "./advance-table/Pagination";
import NoData from "./Nodata";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import CustomButton from "./CustomButton";
import { exportToExcel } from "helper/downloadFile";
import { sortedDataByUpdatedAt } from "helper/formatArray";

const TransactionTable = ({ items, title, detailsRedirect }) => {
  const history = useHistory();
  const { countries } = useSelector((state) => state.userAuth);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      //   { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return capitalize(value);
        },
      },
      {
        accessor: "amount",
        Header: "Amount",
        Cell: ({ value, row }) => {
          const currency = row?.original?.order_id?.country ? JSON.parse(row?.original?.order_id?.country)?.currency : "#";
          return `${formatCurrency(value, 2, row?.original?.type == "order_payment" ? currency?.symbol : undefined)}` || "";
        },
      },
      {
        accessor: "order_id",
        Header: "Order ID",
        Cell: ({ value, row }) => {
          return value?.order_id || "";
        },
      },
      {
        accessor: "userName",
        Header: "Sender/Recipient",
      },
      {
        accessor: "userPhone",
        Header: "Phone number",
      },
      {
        accessor: "userAccountNumber",
        Header: "Account number",
      },
      {
        accessor: "userBankName",
        Header: "Bank",
      },
      {
        accessor: "userAccountName",
        Header: "Account name",
      },
      {
        accessor: "user_model_name",
        Header: "User Type",
      },
      {
        accessor: "entry",
        Header: "Entry",
        Cell: ({ value, row }) => {
          return capitalize(value);
        },
      },
      {
        accessor: "payment_gateway",
        Header: "Payment method",
        Cell: ({ value, row }) => {
          return capitalize(value?.replace("_", " "));
        },
      },
      {
        accessor: "reference",
        Header: "Reference",
      },
      {
        accessor: "createdAt",
        Header: "Date created",
        Cell: ({ value, row }) => {
          return moment(value).format("ddd MMM DD, yyyy");
        },
      },
      {
        accessor: "updatedAt",
        Header: "Last updated",
        Cell: ({ value, row }) => {
          return moment(value).format("ddd MMM DD, yyyy");
        },
      },
    ],
    [],
  );
  const data = useMemo(() => {
    let filtered = items || [];
    filtered = sortedDataByUpdatedAt(filtered);
    filtered = filtered?.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        name: capitalize(x?.type?.replace("_", " ")),
        userName: x?.user_id?.firstName + " " + x?.user_id?.lastName,
        userEmail: x?.user_id?.email,
        userPhone: x?.user_id?.phoneNumber?.toString(),
        userBankName: x?.user_id?.bank_name,
        userAccountNumber: x?.user_id?.account_number?.toString(),
        userAccountName: x?.user_id?.account_name,
      };
    });
    return filtered;
  }, [items]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
    rows,
  } = useTable(
    {
      columns,
      data,
      // pageSize: pagesize,
      initialState: {
        // pageSize: pagesize,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  const handleRowClick = (values) => {
    history.push(detailsRedirect?.replace(":id", values?.id), values);
  };

  return (
    <Card className="mt-2">
      <TableHeader
        title={title}
        data={data}
        searchable={true}
        paginated={true}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        currentPageSize={currentPageSize}
        setCurrentPageSize={setCurrentPageSize}
        setPageSize={setPageSize}
        pageSizeOptions={pageSizeOptions}
        actionButtons={data?.length > 0}
        actionButton1={
          <DownloadTableExcel filename="FD_Transactions" sheet="transactions" currentTableRef={tableRef.current}>
            <CustomButton variant="primary" type="button" text="Export" />
          </DownloadTableExcel>
        }
      />
      <Col>
        <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
          {data.length > 0 ? (
            <>
              <thead className="table-light text-center">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDownSvg style={{ height: 20 }} />
                          ) : (
                            <SortUpSvg style={{ height: 20 }} />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="text-center" onClick={() => handleRowClick(row?.original)}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <NoData />
          )}
        </Table>
        {data.length > 0 ? (
          <Pagination previousPage={previousPage} pageCount={pageCount} pageIndex={pageIndex} gotoPage={gotoPage} nextPage={nextPage} />
        ) : (
          <></>
        )}
      </Col>
    </Card>
  );
};

export default TransactionTable;
