// import node module libraries
import { Fragment, useState } from "react";
import { Menu, Search } from "react-feather";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Nav, Navbar, InputGroup, Dropdown, Form, ListGroup, Row, Col, OverlayTrigger, Tooltip, Image } from "react-bootstrap";

// import simple bar scrolling used for notification item scrolling
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// import custom components
import DotBadge from "componentsx/elements/bootstrap/DotBadge";

// import media files
import { ReactComponent as ProfileSvg } from "assets/svgs/admin-profile.svg";

// import data files
import NotificationList from "datax/Notification";
import { CustomText } from "components/CustomText";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLogoutMutation } from "redux/features/auth/authApi";
import { logOut } from "redux/features/auth/authSlice";
import { ChangePassword } from "pages/auth/ChangePassword";

const HeaderDefault = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.userAuth);
  const [visible, setVisible] = useState(false);
  const [logout, { isLoading }] = useLogoutMutation();

  const handleLogout = async () => {
    const res = await logout();
    if (res?.data?.status === "success") {
      dispatch(logOut());
      history.push("/login");
      toast.success(res?.data?.message || "Logged out successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Fragment>
      <Navbar expanded="lg" className="navbar-default">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Link id="nav-toggle" to="#" onClick={() => props.data.SidebarToggleMenu(!props.data.showMenu)}>
              <Menu size="24px" />
            </Link>
          </div>

          <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex align-items-center nav-top-wrap">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} bsPrefix="dt" className="rounded-circle border-bottom-0" id="dropdownUser">
                <div className="avatar avatar-md avatar-indicators avatar-online">
                  <ProfileSvg />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown dropdown-menu-end mt-4 py-0" aria-labelledby="dropdownUser" align="end">
                <Dropdown.Item className="mt-3">
                  <div className="d-flex">
                    <div className="avatar avatar-md avatar-indicators avatar-online">
                      <ProfileSvg />
                    </div>
                    <div className="ms-3 lh-1">
                      <h5 className="mb-1">{user?.firstName + " " + user?.lastName}</h5>
                      <p className="mb-0 text-muted">{user?.email}</p>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="1" onClick={() => setVisible(true)}>
                  <i className="fe fe-lock me-2"></i> Change Password
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="mb-3" onClick={handleLogout}>
                  <i className="fe fe-power me-2"></i> Log out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>
      <ChangePassword showAdd={visible} handleCloseAdd={() => setVisible(false)} />
    </Fragment>
  );
};

export default HeaderDefault;
