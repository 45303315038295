import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useUpdatePasswordMutation } from "redux/features/auth/authApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const ChangePassword = ({ showAdd, handleCloseAdd }) => {
  const validationSchema = Yup.object().shape({
    password: Yup.string().min(8),
    newPassword: Yup.string().min(8),
    newPasswordConfirm: Yup.string().min(8),
  });

  const { user } = useSelector((state) => state.userAuth);

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      handlePasswordChange(values);
    },
  });

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const handlePasswordChange = async (values) => {
    const res = await updatePassword({ id: user?.id, data: values });
    if (res?.data?.status === "success") {
      toast.success("Password changed successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseAdd();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={showAdd} onHide={handleCloseAdd}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Change Password"} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Old Password"}
                id={"password"}
                placeholder={"***************"}
                onChange={formik?.handleChange}
                value={formik?.values?.password}
                isPassword
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"New Password"}
                id={"newPassword"}
                placeholder={"***************"}
                onChange={formik?.handleChange}
                value={formik?.values?.newPassword}
                isPassword
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Confirm New password"}
                id={"newPasswordConfirm"}
                placeholder={"***************"}
                onChange={formik?.handleChange}
                value={formik?.values?.newPasswordConfirm}
                isPassword
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={!formik?.isValid || isLoading}
          button1disabled={!formik?.isValid || isLoading}
          button2Text={"Confirm"}
          button2onClick={formik.handleSubmit}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1Text={"Cancel"}
          button1onClick={handleCloseAdd}
        />
      </Modal.Footer>
    </Modal>
  );
};
