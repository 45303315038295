const { Col } = require("react-bootstrap");
const { CustomText } = require("./CustomText");

const NoData = () => (
  <Col className="py-4">
    <CustomText
      fontFamily={"Poppins"}
      fontSize={20}
      fontWeight={500}
      text="No Results"
      cNColor="primary"
      textClassName="text-center"
    />
  </Col>
);
export default NoData;
