// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";

import { TrashFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Form, Tab, Nav, Spinner } from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";
// import Category from './../../componentsx/dashboard/cms/Category
// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import { AddNewUnit } from "./components/AddNewUnit";
import { EditUnit } from "./components/EditUnit";
import NoData from "components/Nodata";
import CustomButton from "components/CustomButton";
import Loader from "components/Loader";
import { useSelector } from "react-redux";
import { useDeleteUomMutation, useGetUomsMutation } from "redux/features/user/userApi";
import { saveUoms } from "redux/features/auth/authSlice";
import { TableHeader } from "components/TableHeader";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { sortedDataByUpdatedAt } from "helper/formatArray";

const UnitTypes = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [unitDetails, setUnitDetails] = useState([]);
  const [newUnit, setNewmarket] = useState(false);
  const [editType, setEditType] = useState(false);
  const handleCloseUnit = () => {
    setNewmarket(false);
    setRefresh(!refresh);
  };
  const handleCloseEditUnit = () => {
    setEditType(false);
    setRefresh(!refresh);
  };
  const handleShowNewUnit = () => {
    setNewmarket(true);
  };
  const handleShowEdit = (unit) => {
    setEditType(true);
    setUnitDetails(unit);
  };

  const { uoms, countries, categories, markets } = useSelector((state) => state.userAuth);

  const [getUoms, { isLoading }] = useGetUomsMutation();
  const [deleteUom, { isLoading: isDeleting }] = useDeleteUomMutation();

  const fetchList = async () => {
    const res = await getUoms();
    if (res?.data?.status === "success") {
      dispatch(saveUoms(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };
  const handleDelete = async (id) => {
    const res = await deleteUom(id);
    if (res?.data) {
      toast.success(res?.data?.message || "Deleted successfully");
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "description",
        Header: "Description",
      },
      {
        accessor: "action",
        Header: "Action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-around align-items-center">
              <CustomButton variant="primary" onClick={() => handleShowEdit(row?.original)}>
                Edit
              </CustomButton>

              {/* <TrashFill size={24} color="#FFB800" onClick={() => handleDelete(row?.original?._id)} /> */}
            </div>
          );
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = uoms || [];
    filtered = sortedDataByUpdatedAt(filtered);

    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });

    return filtered;
  }, [uoms]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <TableHeader
              title={"Units"}
              data={data}
              searchable={true}
              paginated={true}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              currentPageSize={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              actionButtons={true}
              actionButton1={
                <DownloadTableExcel filename="FD_UnitTypes" sheet="Units" currentTableRef={tableRef.current}>
                  <CustomButton variant="primary" type="button" text="Export" />
                </DownloadTableExcel>
              }
              actionButton2={
                <CustomButton
                  variant="primary"
                  // type="submit"
                  onClick={handleShowNewUnit}
                  text="Create New "
                  // loading={}
                />
              }
            />
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                  {data.length > 0 ? (
                    <>
                      <thead className="table-light text-center">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                {column.render("Header")}

                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownSvg style={{ height: 20 }} />
                                  ) : (
                                    <SortUpSvg style={{ height: 20 }} />
                                  )
                                ) : (
                                  ""
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className="text-center">
                              {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <NoData />
                  )}
                </Table>

                {data.length > 0 ? (
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
      {/* Add New Unit Type */}

      <AddNewUnit showNew={newUnit} handleCloseAddNew={handleCloseUnit} />

      {/* Edit Unit Type */}
      <EditUnit showEdit={editType} handleCloseEdit={handleCloseEditUnit} defaultValues={unitDetails} />
    </Fragment>
  );
};

export default UnitTypes;
