import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  token: "",
  countries: [],
  country: null,
  uoms: [],
  markets: [],
  supports: [],
  areas: [],
  banks: [],
  categories: [],
  priceByMarkets: [],
  deliveryArea: null,
};

export const authSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    saveUser: (state, { payload }) => {
      state.user = payload;
    },
    saveToken: (state, { payload }) => {
      state.token = payload;
    },
    setCredentials: (state, { payload }) => {
      state.user = payload.user;
      state.token = payload.token;
    },
    saveCountries: (state, { payload }) => {
      state.countries = payload;
    },
    saveDeliveryArea: (state, { payload }) => {
      state.deliveryArea = payload;
    },
    saveCountry: (state, { payload }) => {
      state.country = payload;
    },
    saveAreas: (state, { payload }) => {
      state.areas = payload;
    },
    saveBanks: (state, { payload }) => {
      state.areas = payload;
    },
    saveUoms: (state, { payload }) => {
      state.uoms = payload;
    },
    saveMarkets: (state, { payload }) => {
      state.markets = payload;
    },
    saveSupports: (state, { payload }) => {
      state.supports = payload;
    },
    saveCategories: (state, { payload }) => {
      state.categories = payload;
    },
    savePriceByMarkets: (state, { payload }) => {
      state.priceByMarkets = payload;
    },
    clearUser: (state) => {
      state.user = {};
    },
    clearToken: (state) => {
      state.token = {};
    },
    logOut: (state) => {
      state.token = "";
      state.user = {};
      state.deliveryArea = null;
    },
  },
});

export const {
  saveUser,
  clearUser,
  saveToken,
  saveCountries,
  saveDeliveryArea,
  saveCountry,
  clearToken,
  setCredentials,
  logOut,
  saveCategories,
  saveMarkets,
  saveSupports,
  savePriceByMarkets,
  saveUoms,
  saveAreas,
  saveBanks,
} = authSlice.actions;

export default authSlice.reducer;
