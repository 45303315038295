import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import tomatopng from "assets/images/png/tomato.png";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import GlobalFilter from "components/advance-table/GlobalFilter";
import { useGetOrdersMutation, useGetShoppersMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";
export const AssignShopper = ({ show, close, onConfirm = () => {}, market, isLoading }) => {
  const [selectedShopper, setSelectedShopper] = useState(null);
  const [list, setList] = useState([]);
  const [orders, setOrders] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [getShoppers, { isLoading: isLoadingShopper }] = useGetShoppersMutation();
  const [getOrders, { isLoading: isLoadingOrders }] = useGetOrdersMutation();

  const handleCheckboxChange = (event, value) => {
    setSelectedShopper(value);
  };

  const fetchList = async () => {
    const res = await getShoppers({ market_id: market?.id });
    const res1 = await getOrders({ market_id: market?.id });

    if (res?.data?.status === "success") {
      setList(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (res1?.data?.status === "success") {
      setOrders(res1?.data?.data);
    } else {
      toast.error("Failed to fetch availability status", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh, show]);

  const data = useMemo(() => {
    let filtered = list || [];
    filtered = filtered?.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        name: x?.firstName + " " + x?.lastName,
        availability: orders?.find((y) => y?.shopper_id == x?.id && y?.order_status == "intransit") ? "Busy" : "Free",
      };
    });

    return filtered;
  }, [list, orders]);
  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      //   { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "availability",
        Header: "Availability",
        Cell: ({ value }) => {
          return <div className={value}>{value}</div>;
        },
      },
      {
        accessor: "phoneNumber",
        Header: "Phone Number",
      },
      {
        accessor: "action",
        Header: "Assign",
        Cell: ({ value, row }) => {
          return (
            <Form.Check
              type="checkbox"
              id="myCheckbox"
              checked={selectedShopper?.id == row?.original?.id}
              onChange={(e) => handleCheckboxChange(e, row?.original)}
            />
          );
        },
      },
    ],
    [selectedShopper],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // pageSize: pagesize,
      initialState: {
        // pageSize: pagesize,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );
  const { pageIndex, globalFilter, pageSize } = state;
  return (
    <Modal show={show} onHide={close}>
      <Card>
        <Modal.Header>
          <Modal.Title className="d-flex w-100 align-items-center justify-content-between">
            <Col>
              <CustomText
                fontFamily={"Montserrat"}
                fontSize={16}
                fontWeight={600}
                text={"Shoppers in " + market?.name + "(" + market?.state_name + ")"}
                cNColor="primary"
              />
            </Col>
            <Col>
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} placeholder="Search" />
            </Col>
          </Modal.Title>
        </Modal.Header>
        <Col>
          <Table responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
            <thead className="table-light text-center">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                      {column.render("Header")}

                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortDownSvg style={{ height: 20 }} />
                        ) : (
                          <SortUpSvg style={{ height: 20 }} />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="text-center">
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
        <Modal.Footer className="justify-content-end p-1">
          <CustomButton
            variant="primary"
            type="submit"
            text="Assign"
            disabled={!selectedShopper || isLoading}
            loading={isLoading}
            onClick={() => onConfirm(selectedShopper)}
          />
        </Modal.Footer>
      </Card>
    </Modal>
  );
};
