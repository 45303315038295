import { Col, FormSelect, Row } from "react-bootstrap";
import GlobalFilter from "./advance-table/GlobalFilter";
import MultiKeySearch from "./advance-table/MultiKeySearch";
import { CustomText } from "./CustomText";

export const TableHeader = ({
  title,
  title1 = <></>,
  fontSize = 16,
  data,
  searchable,
  isMultiKeySearchable,
  paginated,
  columns,
  globalFilter,
  setFilter,
  setGlobalFilter,
  currentPageSize,
  setCurrentPageSize,
  setPageSize,
  pageSizeOptions,
  actionButton1,
  actionButton2,
  actionButton3,
  actionButtons,
}) => {
  const divide = 12 / (1 + (searchable ? 1 : 0) + (paginated ? 1 : 0) + (actionButtons ? 1 : 0));
  return (
    <Row id="comptitle" className="mb-2">
      <Row className="px-3 py-2 justify-content-between">
        <Col lg={divide} className="d-flex align-items-center ">
          <CustomText fontFamily={"Montserrat"} fontSize={fontSize} fontWeight={600} text={title} cNColor="primary" />
          {title1}
        </Col>
        {data.length > 0 ? (
          <>
            {searchable && (
              <Col lg={divide} className="d-flex align-items-center">
                {isMultiKeySearchable && columns?.length > 1 ? (
                  <MultiKeySearch setFilter={setFilter} setGlobalFilter={setGlobalFilter} columns={columns} />
                ) : (
                  <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} placeholder="Search List" />
                )}
              </Col>
            )}
            {paginated && (
              <Col lg={divide} className="d-flex align-items-center">
                {/* Pagination bit */}
                <FormSelect
                  value={currentPageSize}
                  onChange={(e) => {
                    setCurrentPageSize(Number(e.target.value));
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {pageSizeOptions.map((size) => (
                    <option key={size} value={size}>
                      {size + "  /page"}
                    </option>
                  ))}
                </FormSelect>
              </Col>
            )}
          </>
        ) : (
          <></>
        )}
        {actionButtons && (
          <Col lg={divide} className="d-flex gap-3 align-items-center justify-content-end">
            {actionButton1 && actionButton1}
            {actionButton2 && actionButton2}
            {actionButton3 && actionButton3}
          </Col>
        )}
      </Row>
    </Row>
  );
};
