import { v4 as uuid } from "uuid";
/**
 *  All Dashboard Routes
 *
 *  Understanding name/value pairs for Dashboard routes
 *
 *  Applicable for main/root/level 1 routes
 *  icon 		: String - It's only for main menu or you can consider 1st level menu item to specify icon name.
 * 				: Object - Icon as an object added from v1.4.0.
 *
 *  Applicable for main/root/level 1 and subitems routes
 * 	id 			: Number - You can use uuid() as value to generate unique ID using uuid library, you can also assign constant unique ID for react dynamic objects.
 *  title 		: String - If menu contains childern use title to provide main menu name.
 *  badge 		: String - (Optional - Default - '') If you specify badge value it will be displayed beside the menu title or menu item.
 * 	badgecolor 	: String - (Optional - Default - 'primary' ) - Used to specify badge background color.
 *
 *  Applicable for subitems / children items routes
 *  name 		: String - If it's menu item in which you are specifiying link, use name ( don't use title for that )
 *  children	: Array - Use to specify submenu items
 *
 *  Used to segrigate menu groups
 *  grouptitle : Boolean - (Optional - Default - false ) If you want to group menu items you can use grouptitle = true,
 *  ( Use title : value to specify group title  e.g. COMPONENTS , DOCUMENTATION that we did here. )
 *
 */

// import MDI icons
import { ReactComponent as DashboardSvg } from "assets/svgs/dashboard.svg";
import { ReactComponent as UsersSvg } from "assets/svgs/users.svg";
import { ReactComponent as OrdersSvg } from "assets/svgs/orders.svg";
import { ReactComponent as ShoppersSvg } from "assets/svgs/shoppers.svg";
import { ReactComponent as StoreSvg } from "assets/svgs/store.svg";
import { ReactComponent as VouchersSvg } from "assets/svgs/vouchers.svg";
import { ReactComponent as ContentManagementSvg } from "assets/svgs/content-management.svg";
import { ReactComponent as AppSettingsSvg } from "assets/svgs/app-settings.svg";
import { ReactComponent as TransactionsSvg } from "assets/svgs/statistic-up.svg";
import { useSelector } from "react-redux";

export const DashboardMenu = () => {
  const { user } = useSelector((state) => state.userAuth);

  if (user?.role?.includes("super admin")) {
    return superAdmin;
  } else if (user?.role?.includes("local admin")) {
    return localAdmin;
  } else if (user?.role?.includes("global admin")) {
    return globalAdmin;
  } else if (user?.role?.includes("local store")) {
    return localAdmin;
  } else if (user?.role?.includes("global store")) {
    return globalStore;
  } else if (user?.role?.includes("finance")) {
    return financeAdmin;
  } else if (user?.role?.includes("shopper")) {
    return shopperAdmin;
  } else if (user?.role?.includes("market admin")) {
    return marketAdmin;
  }
};
export const marketAdmin = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: <DashboardSvg />,
    link: "/",
  },
  {
    id: uuid(),
    title: "Customers",
    icon: <UsersSvg />,
    children: [
      { id: uuid(), link: "/users/local", name: "Local Customers" },
      { id: uuid(), link: "/users/global", name: "Global Customers" },
    ],
  },
  {
    id: uuid(),
    title: "Store",
    icon: <StoreSvg />,
    children: [{ id: uuid(), link: "/store/local", name: "Local Store" }],
  },
  {
    id: uuid(),
    title: "Orders",
    icon: <OrdersSvg />,
    children: [
      { id: uuid(), link: "/orders/", name: "Local Orders" },
      { id: uuid(), link: "/orders/local/transactions", name: "Local Transactions" },
    ],
  },

  {
    id: uuid(),
    title: "Shoppers",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/shoppers/accounts", name: "Accounts" },
      { id: uuid(), link: "/shoppers/transactions", name: "Transactions" },
    ],
  },
  {
    id: uuid(),
    title: "Vendors",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/vendors/accounts", name: "Accounts" },
      { id: uuid(), link: "/vendors/transactions", name: "Transactions" },
    ],
  },
  {
    id: uuid(),
    title: "Transactions",
    icon: <TransactionsSvg />,
    link: "/transactions",
  },
  {
    id: uuid(),
    title: "Vouchers",
    icon: <VouchersSvg />,
    link: "/vouchers",
  },
  {
    id: uuid(),
    title: "Content Management",
    icon: <ContentManagementSvg />,
    children: [
      {
        id: uuid(),
        link: "/content-management/unit-types",
        name: "Unit Types",
      },
      {
        id: uuid(),
        link: "/content-management/category",
        name: "Category",
      },
      { id: uuid(), link: "/content-management/support", name: "Support" },
    ],
  },
];
export const shopperAdmin = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: <DashboardSvg />,
    link: "/",
  },
  {
    id: uuid(),
    title: "Shoppers",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/shoppers/accounts", name: "Accounts" },
      { id: uuid(), link: "/shoppers/transactions", name: "Transactions" },
    ],
  },
  {
    id: uuid(),
    title: "Vendors",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/vendors/accounts", name: "Accounts" },
      { id: uuid(), link: "/vendors/transactions", name: "Transactions" },
    ],
  },
];
export const financeAdmin = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: <DashboardSvg />,
    link: "/",
  },
  {
    id: uuid(),
    title: "Transactions",
    icon: <TransactionsSvg />,
    link: "/transactions",
  },
  {
    id: uuid(),
    title: "Vouchers",
    icon: <VouchersSvg />,
    link: "/vouchers",
  },
];
export const globalStore = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: <DashboardSvg />,
    link: "/",
  },

  {
    id: uuid(),
    title: "Store",
    icon: <StoreSvg />,
    children: [{ id: uuid(), link: "/store/global", name: "Global Store" }],
  },

  {
    id: uuid(),
    title: "Content Management",
    icon: <ContentManagementSvg />,
    children: [
      {
        id: uuid(),
        link: "/content-management/unit-types",
        name: "Unit Types",
      },
      {
        id: uuid(),
        link: "/content-management/category",
        name: "Category",
      },
    ],
  },
];
export const localStore = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: <DashboardSvg />,
    link: "/",
  },

  {
    id: uuid(),
    title: "Store",
    icon: <StoreSvg />,
    children: [{ id: uuid(), link: "/store/local", name: "Local Store" }],
  },

  {
    id: uuid(),
    title: "Content Management",
    icon: <ContentManagementSvg />,
    children: [
      {
        id: uuid(),
        link: "/content-management/unit-types",
        name: "Unit Types",
      },
      {
        id: uuid(),
        link: "/content-management/category",
        name: "Category",
      },
    ],
  },
];
export const globalAdmin = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: <DashboardSvg />,
    link: "/",
  },

  {
    id: uuid(),
    title: "Customers",
    icon: <UsersSvg />,
    children: [{ id: uuid(), link: "/users/global", name: "Global Customers" }],
  },
  {
    id: uuid(),
    title: "Store",
    icon: <StoreSvg />,
    children: [{ id: uuid(), link: "/store/global", name: "Global Store" }],
  },
  {
    id: uuid(),
    title: "Orders",
    icon: <OrdersSvg />,
    children: [
      { id: uuid(), link: "/orders/global", name: "Global Orders" },
      { id: uuid(), link: "/orders/global/transactions", name: "Global Transactions" },
    ],
  },

  {
    id: uuid(),
    title: "Vendors",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/vendors/accounts", name: "Accounts" },
      { id: uuid(), link: "/vendors/transactions", name: "Transactions" },
    ],
  },
  {
    id: uuid(),
    title: "Transactions",
    icon: <TransactionsSvg />,
    link: "/transactions",
  },
  {
    id: uuid(),
    title: "Vouchers",
    icon: <VouchersSvg />,
    link: "/vouchers",
  },
  {
    id: uuid(),
    title: "Content Management",
    icon: <ContentManagementSvg />,
    children: [
      { id: uuid(), link: "/content-management/markets", name: "Markets" },
      { id: uuid(), link: "/content-management/areas", name: "Areas" },
      {
        id: uuid(),
        link: "/content-management/unit-types",
        name: "Unit Types",
      },
      {
        id: uuid(),
        link: "/content-management/category",
        name: "Category",
      },
      { id: uuid(), link: "/content-management/countries", name: "Countries" },
      { id: uuid(), link: "/content-management/support", name: "Support" },
    ],
  },
];
export const localAdmin = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: <DashboardSvg />,
    link: "/",
  },

  {
    id: uuid(),
    title: "Customers",
    icon: <UsersSvg />,
    children: [{ id: uuid(), link: "/users/local", name: "Local Customers" }],
  },

  {
    id: uuid(),
    title: "Store",
    icon: <StoreSvg />,
    children: [{ id: uuid(), link: "/store/local", name: "Local Store" }],
  },
  {
    id: uuid(),
    title: "Orders",
    icon: <OrdersSvg />,
    children: [
      { id: uuid(), link: "/orders/", name: "Local Orders" },
      { id: uuid(), link: "/orders/local/transactions", name: "Local Transactions" },
    ],
  },

  {
    id: uuid(),
    title: "Shoppers",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/shoppers/accounts", name: "Accounts" },
      { id: uuid(), link: "/shoppers/transactions", name: "Transactions" },
    ],
  },
  {
    id: uuid(),
    title: "Vendors",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/vendors/accounts", name: "Accounts" },
      { id: uuid(), link: "/vendors/transactions", name: "Transactions" },
    ],
  },
  {
    id: uuid(),
    title: "Transactions",
    icon: <TransactionsSvg />,
    link: "/transactions",
  },
  {
    id: uuid(),
    title: "Vouchers",
    icon: <VouchersSvg />,
    link: "/vouchers",
  },
  {
    id: uuid(),
    title: "Content Management",
    icon: <ContentManagementSvg />,
    children: [
      { id: uuid(), link: "/content-management/markets", name: "Markets" },
      { id: uuid(), link: "/content-management/areas", name: "Areas" },
      {
        id: uuid(),
        link: "/content-management/unit-types",
        name: "Unit Types",
      },
      {
        id: uuid(),
        link: "/content-management/category",
        name: "Category",
      },
      { id: uuid(), link: "/content-management/countries", name: "Countries" },
      { id: uuid(), link: "/content-management/support", name: "Support" },
    ],
  },
];
export const superAdmin = [
  {
    id: uuid(),
    title: "Dashboard",
    icon: <DashboardSvg />,
    link: "/",
  },

  {
    id: uuid(),
    title: "Customers",
    icon: <UsersSvg />,
    children: [
      { id: uuid(), link: "/users/local", name: "Local Customers" },
      { id: uuid(), link: "/users/global", name: "Global Customers" },
    ],
  },
  {
    id: uuid(),
    title: "Admins",
    icon: <UsersSvg />,
    link: "/users/admin",
  },
  {
    id: uuid(),
    title: "Store",
    icon: <StoreSvg />,
    children: [
      { id: uuid(), link: "/store/local", name: "Local Store" },
      { id: uuid(), link: "/store/global", name: "Global Store" },
    ],
  },
  {
    id: uuid(),
    title: "Orders",
    icon: <OrdersSvg />,
    children: [
      { id: uuid(), link: "/orders/", name: "Local Orders" },
      { id: uuid(), link: "/orders/global", name: "Global Orders" },
      { id: uuid(), link: "/orders/local/transactions", name: "Local Transactions" },
      { id: uuid(), link: "/orders/global/transactions", name: "Global Transactions" },
    ],
  },

  {
    id: uuid(),
    title: "Shoppers",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/shoppers/accounts", name: "Accounts" },
      { id: uuid(), link: "/shoppers/transactions", name: "Transactions" },
    ],
  },
  {
    id: uuid(),
    title: "Vendors",
    icon: <ShoppersSvg />,
    children: [
      { id: uuid(), link: "/vendors/accounts", name: "Accounts" },
      { id: uuid(), link: "/vendors/transactions", name: "Transactions" },
    ],
  },
  // {
  //   id: uuid(),
  //   title: "Transactions",
  //   icon: <TransactionsSvg />,
  //   children: [
  //     { id: uuid(), link: "/transactions/withdrawals", name: "Withdrawals" },
  //     { id: uuid(), link: "/transactions/commissions", name: "Commissions" },
  //     { id: uuid(), link: "/transactions/vendors", name: "Vendors Payments" },
  //     { id: uuid(), link: "/transactions/orders", name: "Orders Payments" },
  //   ],
  // },
  {
    id: uuid(),
    title: "Transactions",
    icon: <TransactionsSvg />,
    link: "/transactions",
  },
  {
    id: uuid(),
    title: "Vouchers",
    icon: <VouchersSvg />,
    link: "/vouchers",
  },
  {
    id: uuid(),
    title: "Content Management",
    icon: <ContentManagementSvg />,
    children: [
      { id: uuid(), link: "/content-management/markets", name: "Markets" },
      { id: uuid(), link: "/content-management/areas", name: "Areas" },
      {
        id: uuid(),
        link: "/content-management/unit-types",
        name: "Unit Types",
      },
      {
        id: uuid(),
        link: "/content-management/category",
        name: "Category",
      },
      { id: uuid(), link: "/content-management/countries", name: "Countries" },
      { id: uuid(), link: "/content-management/support", name: "Support" },
    ],
  },
];

export default DashboardMenu;
