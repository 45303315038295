// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link, useHistory } from "react-router-dom";

import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Tab, Nav, Spinner, Form, FormSelect } from "react-bootstrap";

import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";
// import Category from './../../componentsx/dashboard/cms/Category
// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import ProductsGrid from "./ProductsGrid";
import CustomButton from "components/CustomButton";
import NoData from "components/Nodata";
import { useGetCountriesMutation, useGetProductsMutation, useGetUomsMutation } from "redux/features/user/userApi";
import { useSelector } from "react-redux";
import { saveCountries, saveUoms } from "redux/features/auth/authSlice";
import moment from "moment";
import Loader from "components/Loader";
import { calculateAveragePrice, isSubstringInArray, sortedDataByCreatedAt } from "helper/formatArray";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { TableHeader } from "components/TableHeader";

const GlobalProducts = () => {
  const { countries, uoms } = useSelector((state) => state.userAuth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [displayType, setDisplayType] = useState("List View");
  const [listView, setListView] = useState(true);

  const [stats, setStats] = useState({ total: 0, uploadedThisMonth: 0 });

  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [getUoms, { isLoading: isLoadingUoms }] = useGetUomsMutation();
  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();
  const [getProducts, { isLoading }] = useGetProductsMutation();

  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchUoms = async () => {
    const res = await getUoms();
    if (res?.data?.status === "success") {
      dispatch(saveUoms(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const country_id = countries?.find((x) => isSubstringInArray(x?.name, ["Canada"]))?.id;

  const fetchList = async () => {
    const res = await getProducts({ country_id });

    if (res?.data?.status === "success") {
      setList(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchUoms();
  }, [refresh]);

  useEffect(() => {
    country_id && fetchList();
  }, [refresh, country_id]);

  useEffect(() => {
    if (displayType === "List View") {
      setListView(true);
    } else {
      setListView(false);
    }
  }, [displayType]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      // { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "NAME",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit " to={{ pathname: `/store/global/${row?.original?._id}`, state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "category_id",
        Header: "Category",
        Cell: ({ value, row }) => {
          return value?.name;
        },
      },
      {
        accessor: "priceByMarketId",
        Header: "Unit - Market - Cost price - Selling price - Kg",
        Cell: ({ value, row }) => {
          return value?.map((x) => {
            return (
              <p className="mb-0">
                {x?.uom_id?.name +
                  " (" +
                  x?.market_id?.name +
                  ", " +
                  "₦" +
                  x?.cost_price +
                  "/" +
                  row?.original?.currency +
                  x?.price +
                  ", " +
                  x?.kg +
                  "kg" +
                  ") "}
              </p>
            );
          });
        },
      },
      {
        accessor: "available",
        Header: "Available",
        Cell: ({ value, row }) => {
          return (
            <p className="mb-0 text-capitalize" style={value == "yes" ? { color: "green" } : { color: "red" }}>
              {value}
            </p>
          );
        },
      },
      {
        accessor: "createdAt",
        Header: "Date Updated",
        Cell: ({ value }) => {
          return moment(value)?.format("ddd MMM DD YYYY");
        },
      },
      {
        accessor: "updatedAt",
        Header: "Date Updated",
        Cell: ({ value }) => {
          return moment(value)?.format("ddd MMM DD YYYY");
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = list;
    filtered = filtered.filter((x) => x?.country_id?.name !== "Nigeria");
    filtered = sortedDataByCreatedAt(filtered);

    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        priceByMarketId1: x?.priceByMarketId,
        currency: x?.country_id?.currency?.symbol,
      };
    });
    setStats({
      total: filtered?.length,
      uploadedThisMonth: filtered?.filter((x) => moment(x?.createdAt).isSame(new Date(), "month"))?.length,
    });
    return filtered;
  }, [list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize, viewOption } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);
  const viewOptions = ["List View", "Grid View"];
  const [search, setSearch] = useState("");
  const [currenViewOption, setCurrentViewOption] = useState(viewOption);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div className="overflow-hidden justify-content-space-around">
        <Row>
          <Col xl={6} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Uploaded this month"
              value={stats?.uploadedThisMonth}
              iconComponent={<OrderCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={6} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Global Products"
              value={stats.total}
              iconComponent={<OrderCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <TableHeader
              title={"Global Products"}
              title1={
                <FormSelect
                  className="form-FormSelect"
                  value={currenViewOption}
                  onChange={(e) => {
                    setCurrentViewOption(e.target.value);
                    setDisplayType(e.target.value);
                  }}
                >
                  {viewOptions.map((view) => (
                    <option key={view} value={view}>
                      {view}
                    </option>
                  ))}
                </FormSelect>
              }
              data={data}
              searchable={true}
              paginated={true}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              currentPageSize={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              actionButtons={true}
              actionButton1={
                <DownloadTableExcel filename="FD_GlobalProducts" sheet="Global Products" currentTableRef={tableRef.current}>
                  <CustomButton variant="primary" type="button" text="Export" />
                </DownloadTableExcel>
              }
              actionButton2={
                <CustomButton variant="primary" type="button" text="Add New" onClick={() => history.push("/add-global-product")} />
              }
            />

            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                {listView && (
                  <>
                    <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                      {data.length > 0 ? (
                        <>
                          <thead className="table-light text-center">
                            {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                    {column.render("Header")}

                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <SortDownSvg style={{ height: 20 }} />
                                      ) : (
                                        <SortUpSvg style={{ height: 20 }} />
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                              prepareRow(row);
                              return (
                                <tr {...row.getRowProps()} className="text-center align-item-center">
                                  {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </>
                      ) : (
                        <NoData />
                      )}
                    </Table>
                    {data.length > 0 ? (
                      <Pagination
                        previousPage={previousPage}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}

                {!listView && <ProductsGrid pageSize={currentPageSize} search={search} data={data} />}
                {/* Pagination @ Footer */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ToastContainer />
    </Fragment>
  );
};

export default GlobalProducts;
