// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as MoneyCardSvg } from "assets/svgs/money-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Tab, Nav, Spinner } from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";

// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import CustomButton from "components/CustomButton";
import NoData from "components/Nodata";

const ShopperTransactions = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };

  const [simulatedData, setSimulatedData] = useState(null);

  useEffect(() => {
    simulateFetch("Some data", 500)
      .then((response) => setSimulatedData(response))
      .catch((error) => {});
  }, []);

  const handleViewTrigger = (details) => {};

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ details }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item eventKey="1" onClick={() => handleViewTrigger(details)}>
            {" "}
            <Send size="18px" className="dropdown-item-icon" /> View
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      // { accessor: "sn", Header: "S/N" },
      // { accessor: "id", Header: "ID" },
      {
        accessor: "type",
        Header: "Transaction Type",
      },
      {
        accessor: "transactionid",
        Header: "ID",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit" to={`/shoppers/transactions/${row?.original?.id}`}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value }) => {
          return <div className={value}>{value}</div>;
        },
      },
      {
        accessor: "amount",
        Header: "Amount",
      },

      {
        accessor: "createdAt",
        Header: "Last Updated",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = Array(0).fill(null) || usersList;
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        id: index + 1,
        sn: index + 1,
        transactionid: "1223334444",
        type: Math.random() > 0.3 ? "Withdrawal" : "Commission",
        status: Math.random() > 0.3 ? "Approved" : "Pending",
        amount: "3,000",
        marketArea: Math.random() > 0.3 ? "Oshodi" : "Mile 12",
        verified: Math.random() > 0.5 ? "Verified" : "Unverified",
        createdAt: Date.now(),
      };
    });

    return filtered;
  }, [usersList]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  if (!simulatedData) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: "#e0bf00" }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card>
              <Card.Body className="p-0">
                <div
                  className="table-responsive"
                  // style={{position: 'relative', zIndex: 9999}}
                >
                  <Col className=" d-flex justify-content-between p-3" id="comptitle">
                    <Col lg={4} md={12} sm={12} className="d-flex align-items-center">
                      <CustomText
                        fontFamily={"Montserrat"}
                        fontSize={16}
                        fontWeight={600}
                        text="Shopper's transactions"
                        cNColor="primary"
                      />
                    </Col>

                    {data.length > 0 ? (
                      <>
                        <Col lg={3} md={12} sm={12} className="d-flex align-items-center">
                          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} placeholder="Search List" />
                        </Col>

                        <Col lg={3} md={12} sm={12} className="d-flex align-items-center">
                          <div className="input-group">
                            {/* Pagination bit */}
                            <select
                              className="form-select"
                              value={currentPageSize}
                              onChange={(e) => {
                                setCurrentPageSize(Number(e.target.value));
                                setPageSize(Number(e.target.value));
                              }}
                            >
                              {pageSizeOptions.map((size) => (
                                <option key={size} value={size}>
                                  {size}
                                </option>
                              ))}
                            </select>

                            <div className="input-group-text">
                              {/* Sorting bit -> Always use customText comp to render all text. Please remember this */}
                              <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={600} text="/page" cNColor="primary" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={2} md={12} sm={12} className="d-flex align-items-center justify-content-end">
                          <CustomButton
                            variant="primary"
                            // type="submit"

                            text="Export to Excel"
                            // loading={}
                          />
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>

                  <Table responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                    {data.length > 0 ? (
                      <>
                        <thead className="table-light text-center">
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                  {column.render("Header")}

                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <SortDownSvg style={{ height: 20 }} />
                                    ) : (
                                      <SortUpSvg style={{ height: 20 }} />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()} className="text-center">
                                {row.cells.map((cell) => {
                                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </Table>

                  {data.length > 0 ? (
                    <Pagination
                      previousPage={previousPage}
                      pageCount={pageCount}
                      pageIndex={pageIndex}
                      gotoPage={gotoPage}
                      nextPage={nextPage}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default ShopperTransactions;
