import React from "react";
import { Col } from "react-bootstrap";
import { CustomText } from "./CustomText";
import ProductCardImage from "./productCardImage/ProductCardImage";

const CustomDetailGroup = ({
  itemKey,
  keyClassName = "fw-bold",
  valueClassName,
  itemValue,
  className,
  valueType,
  fontFamily,
  fontWeight,
  keyStyleColor,
  valueStyleColor,
  keyFontSize,
  valueFontSize,
  imageSrc,
}) => {
  return (
    itemValue && (
      <Col lg={12} md={12} className={className ? className : "px-4 py-2 d-flex gap-3 border-bottom align-items-center "}>
        <CustomText
          fontFamily={fontFamily ? fontFamily : "Poppins"}
          fontSize={keyFontSize ? keyFontSize : 14}
          fontWeight={fontWeight ? fontWeight : 500}
          textClassName={keyClassName}
          text={itemKey}
          styleColor={keyStyleColor}
        />
        {valueType === "picture" ? (
          typeof imageSrc == "string" ? (
            <ProductCardImage src={imageSrc} />
          ) : (
            imageSrc
          )
        ) : (
          <CustomText
            fontFamily={fontFamily ? fontFamily : "Poppins"}
            fontSize={valueFontSize ? valueFontSize : 14}
            fontWeight={fontWeight ? fontWeight : 400}
            textClassName={valueClassName}
            text={itemValue}
            cNColor="primary"
            styleColor={valueStyleColor}
          />
        )}
      </Col>
    )
  );
};

export default CustomDetailGroup;
