import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../features/url";
import customFetchBase from "../customFetchBaseQuery";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customFetchBase,

  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => {
        return {
          url: "admins/signin",
          method: "POST",
          body: data,
        };
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: "admins/logout",
          method: "POST",
        };
      },
    }),
    createAdmin: builder.mutation({
      query: (data) => {
        return {
          url: "admins/signup",
          method: "POST",
          body: data,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: "admins/forgot-password",
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "admins/reset-password",
        method: "PATCH",
        body: data,
      }),
    }),
    updatePassword: builder.mutation({
      query: ({ id, data }) => ({
        url: `admins/update-password/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    assignAdminRole: builder.mutation({
      query: ({ id, data }) => ({
        url: `admins/assign-role${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    getAdmins: builder.mutation({
      query: () => ({
        url: `admins/`,
        method: "GET",
      }),
    }),
    getAdmin: builder.mutation({
      query: (id) => ({
        url: `admins/${id}`,
        method: "GET",
      }),
    }),
    updateAdmin: builder.mutation({
      query: ({ id, data }) => ({
        url: `admins/update/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteAdmin: builder.mutation({
      query: (id) => ({
        url: `admins/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useCreateAdminMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdateAdminMutation,
  useDeleteAdminMutation,
  useAssignAdminRoleMutation,
  useGetAdminsMutation,
  useGetAdminMutation,
  useUpdatePasswordMutation,
} = authApi;

