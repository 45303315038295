import React from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { BASE_URL } from "redux/features/url";
import CustomButton from "./CustomButton";

export const ImageModal = ({ toggleModal, showModal, size, url }) => {
  return (
    <Modal show={showModal} onHide={toggleModal} size={size}>
      <Modal.Body>{url && <Image src={url?.includes("http") ? url : BASE_URL.slice(0, -4) + url} width="100%" height="100%" />}</Modal.Body>
      <Modal.Footer>
        <CustomButton variant="secondary" onClick={toggleModal}>
          Close
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};
