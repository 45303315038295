// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Form, Tab, Nav, Spinner, Image } from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";
// import Category from './../../componentsx/dashboard/cms/Category
// import sub components
// import data files

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import { TrashFill } from "react-bootstrap-icons";
import { EditCountry } from "./components/EditCountry";
import { AddNewCountry } from "./components/AddNewCountry";
import NoData from "components/Nodata";
import CustomButton from "components/CustomButton";
import { saveCountries } from "redux/features/auth/authSlice";
import { useDeleteCountryMutation, useGetCountriesMutation } from "redux/features/user/userApi";
import moment from "moment";
import Loader from "components/Loader";
import { ConfirmDeleteModal } from "components/ConfirmDeleteModal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { TableHeader } from "components/TableHeader";
import { TruncateText } from "helper/formatText";
import { sortedDataByUpdatedAt } from "helper/formatArray";

const Countries = () => {
  const dispatch = useDispatch();
  const [showEdit, setEditCountry] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [countryDetails, setCountryDetails] = useState([]);
  const handleCloseAddNew = () => {
    setShowNew(false);
    setRefresh(!refresh);
  };
  const handleCloseEdit = () => {
    setEditCountry(false);
    setRefresh(!refresh);
  };
  const handleShowAddNew = () => {
    setShowNew(true);
  };
  const handleShowEditCountry = (country) => {
    setCountryDetails(country);
    setEditCountry(true);
  };

  const { uoms, countries, user, categories, markets } = useSelector((state) => state.userAuth);

  const [getCountries, { isLoading }] = useGetCountriesMutation();
  const [deleteCountry, { isLoading: isDeleting }] = useDeleteCountryMutation();

  const [selected, setSelected] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);

  const fetchList = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDelete = async () => {
    const res = await deleteCountry(selected?._id);
    if (res?.data) {
      toast.success(res?.data?.message || "Deleted successfully");
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return value ? "enabled" : "disabled";
        },
      },
      {
        accessor: "flags",
        Header: "Flag",
        Cell: ({ value, row }) => {
          return <Image src={value?.png || value?.svg} width={30} />;
        },
      },
      {
        accessor: "delivery_fee_by_kg",
        Header: "Delivery Price/Kg",
        Cell: ({ value, row }) => {
          return `${row?.original?.currency?.symbol}${value}`;
        },
      },
      {
        accessor: "conversion_rate",
        Header: "Conversion Rate",
      },
      {
        accessor: "country_tax",
        Header: "Country Tax (%)",
        Cell: ({ value, row }) => {
          return `${value}%`;
        },
      },
      {
        accessor: "vat",
        Header: "Vat (%)",
        Cell: ({ value, row }) => {
          return `${value}%`;
        },
      },
      {
        accessor: "other_charges",
        Header: "Other Charges",
        Cell: ({ value, row }) => {
          return `${row?.original?.currency?.symbol}${value}`;
        },
      },
      {
        accessor: "contact_name",
        Header: "Contact name",
      },
      {
        accessor: "contact_phone",
        Header: "Contact Phone",
      },
      {
        accessor: "states",
        Header: "States",
        Cell: ({ value, row }) => {
          return <TruncateText fullText={`${value?.map((x) => x?.name)}`} truncateTo={10} />;
        },
      },

      {
        accessor: "id",
        Header: "Action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-around align-items-center">
              <CustomButton
                variant="primary"
                onClick={() => {
                  handleShowEditCountry(row?.original);
                }}
              >
                Edit
              </CustomButton>
              {/* <TrashFill
                size={24}
                color="#FFB800"
                onClick={() => {
                  setSelected(row?.original);
                  setDeleteShow(true);
                }}
              /> */}
            </div>
          );
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = countries || [];
    filtered = sortedDataByUpdatedAt(filtered);

    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });
    return filtered;
  }, [countries]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <TableHeader
              title={"Countries"}
              data={data}
              searchable={true}
              paginated={true}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              currentPageSize={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              actionButtons={true}
              actionButton1={
                <DownloadTableExcel filename="FD_Countries" sheet="Countries" currentTableRef={tableRef.current}>
                  <CustomButton variant="primary" type="button" text="Export" />
                </DownloadTableExcel>
              }
              actionButton2={
                <CustomButton
                  variant="primary"
                  // type="submit"
                  onClick={handleShowAddNew}
                  text="Add New "
                  // loading={}
                />
              }
            />
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                  {data.length > 0 ? (
                    <>
                      <thead className="table-light text-center">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                {column.render("Header")}

                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownSvg style={{ height: 20 }} />
                                  ) : (
                                    <SortUpSvg style={{ height: 20 }} />
                                  )
                                ) : (
                                  ""
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className="text-center">
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", maxWidth: 250 }}>
                                      {cell.render("Cell")}
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <NoData />
                  )}
                </Table>

                {data.length > 0 ? (
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddNewCountry showNew={showNew} handleCloseAddNew={handleCloseAddNew} />

      <EditCountry showEdit={showEdit} handleCloseEdit={handleCloseEdit} defaultValues={countryDetails} />
      <ConfirmDeleteModal
        deleteShow={deleteShow}
        handleDelete={handleDelete}
        handleDeleteClose={() => setDeleteShow(false)}
        name={selected?.name + " Country"}
        text="Deleting this country will affect all products and user under this country, proceed with caution"
      />
    </Fragment>
  );
};

export default Countries;
