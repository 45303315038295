import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useUpdateUomMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";

export const EditUnit = ({ showEdit, handleCloseEdit, defaultValues }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").typeError("Must be text"),
    description: Yup.string().max(50).required("Required").typeError("Must be text"),
  });

  const formik = useFormik({
    initialValues: {
      name: defaultValues?.name || "",
      description: defaultValues?.description || "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      handleEdit(values);
    },
  });
  const [updateUom, { isLoading }] = useUpdateUomMutation();

  const handleEdit = async (values) => {
    const resolveValues = values;
    const res = await updateUom({ id: defaultValues?._id, data: resolveValues });
    if (res?.data?.status) {
      handleCloseEdit();
      toast.success(res?.data?.message || "Updated successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };
  return (
    <Modal show={showEdit} onHide={handleCloseEdit}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Edit Unit"} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Unit Name"}
                id={"name"}
                placeholder={"Unit Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.name}
                value={formik?.values?.name}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Unit description"}
                id={"description"}
                placeholder={"Unit description"}
                onChange={formik?.handleChange}
                error={formik?.errors?.description}
                value={formik?.values?.description}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={isLoading || !formik?.isValid}
          button2onClick={formik.handleSubmit}
          button2Text={"Confirm"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading}
          button1Text={"Cancel"}
          button1onClick={handleCloseEdit}
        />
      </Modal.Footer>
    </Modal>
  );
};
