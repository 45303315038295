import {SVGFlag} from 'use-flags';
import countrylist from './CountryList.json';
const CountryFlag = prop => {
  return (
    <>
      <SVGFlag country={countrylist[prop.country]} flagWidth='25' />
    </>
  );
};

export default CountryFlag;
