import React, { useEffect, useState } from "react";

export default function MultiKeySearch({ setGlobalFilter, setFilter, columns, placeholder = "Search by key(e.g name:bola)" }) {
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleSearchKeywordChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  useEffect(() => {
    if (searchKeyword?.includes(":")) {
      const searchValues = searchKeyword.split(",").map((keyword) => keyword.trim());
      const filters = searchValues.map((searchValue) => {
        const [columnId, value] = searchValue.split(":").map((str) => str.trim());
        const column = columns.find((col) => col.accessor === columnId);
        if (column) {
          return {
            id: columnId,
            value,
          };
        }
        return null;
      });
      setFilter(filters.filter((filter) => filter !== null));
    } else {
      setGlobalFilter(searchKeyword);
    }
  }, [searchKeyword, setGlobalFilter, columns]);

  return (
    <input
      id="searchInput"
      type="search"
      className="form-control"
      placeholder={placeholder}
      value={searchKeyword || ""}
      onChange={handleSearchKeywordChange}
    />
  );
}
