// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as MoneyCardSvg } from "assets/svgs/money-card.svg";

import { Link, useHistory } from "react-router-dom";

import { Col, Row, Spinner, FormSelect } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { useSelector } from "react-redux";

import TransactionTable from "components/TransactionTable";
import { isInCurrentMonth } from "helper/computations";
import { useBulkUpdateTransactionsStatusMutation, useGetTransactionsMutation } from "redux/features/user/userApi";
import MultiSelectionTable from "components/MultiSelectionTable";
import { sortedDataByUpdatedAt } from "helper/formatArray";
import { capitalize, dateString, formatCurrency } from "helper/formatText";
import moment from "moment";
import DataTable from "components/DataTable";
import { TextSearchFilter } from "components/advance-table/filters";
import CustomButton from "components/CustomButton";
import { ChangeTransactionStatus } from "./components/ChangeTransactionStatus";
import { convertJsonStringToObject } from "helper/formatObject";

const Transactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { countries } = useSelector((state) => state.userAuth);
  const [statusModal, setStatusModal] = useState(false);
  const [currentType, setCurrentType] = useState("all");
  const typeOptions = [
    { value: "all", label: "All" },
    { value: "shopper_withdrawal", label: "Shopper Withdrawal" },
    { value: "shopper_commission", label: "Shopper Commission" },
    { value: "vendor_payment", label: "Vendor Payment" },
    { value: "order_payment", label: "Order Payment" },
  ];
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [stats, setStats] = useState({ pending: 0, settled: 0, settledThisMonth: 0 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [getTransactions, { isLoading }] = useGetTransactionsMutation();
  const [bulkUpdateTransactionsStatus, { isBulkUpdating }] = useBulkUpdateTransactionsStatusMutation();

  const fetchList = async () => {
    const res = await getTransactions();

    if (res?.data?.status === "success") {
      setList(
        res?.data?.data?.map((x, index) => {
          return convertJsonStringToObject(JSON.parse(JSON.stringify(x)));
        }),
      );

      setStats({
        pending: res?.data?.data?.filter((x) => x?.status == "pending")?.length,
        settled: res?.data?.data?.filter((x) => x?.status == "settled")?.length,
        settledThisMonth: res?.data?.data?.filter((x) => x?.status == "settled" && isInCurrentMonth(x?.updatedAt))?.length,
      });
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const updateTransactionStatus = async (status) => {
    console.log("withdrawals", {
      withdrawals: selectedRows?.filter((x) => x?.type == "shopper_withdrawal"),
    });
    // const res = await bulkUpdateTransactionsStatus({
    //   ids: selectedRows?.map((x) => {
    //     return x?.id;
    //   }),
    //   value: status,
    // });
    // if (res?.data?.status === "success") {
    //   toast.success(res?.data?.message || "Bulk update was successful", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setStatusModal(false);
    //   setRefresh(!refresh);
    // } else {
    //   toast.error(res?.error?.data?.message || "Something went wrong", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      //   { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "Name",
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter",
      },
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return capitalize(value);
        },
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter",
      },
      {
        accessor: "amount",
        Header: "Amount",
        Cell: ({ value, row }) => {
          const currency = row?.original?.order_id?.country ? convertJsonStringToObject(row?.original?.order_id?.country)?.currency : "#";
          return `${formatCurrency(value, 2, row?.original?.type == "order_payment" ? currency?.symbol : undefined)}`;
        },
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter",
      },
      {
        accessor: "order_id",
        Header: "Order ID",
        Cell: ({ value, row }) => {
          return value?.order_id;
        },
      },
      {
        accessor: "market",
        Header: "Market",
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter",
      },
      {
        accessor: "userName",
        Header: "Sender/Recipient",
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter",
      },
      {
        accessor: "userPhone",
        Header: "Phone number",
      },
      {
        accessor: "userAccountNumber",
        Header: "Account number",
      },
      {
        accessor: "userBankName",
        Header: "Bank",
      },
      {
        accessor: "userAccountName",
        Header: "Account name",
      },
      {
        accessor: "user_model_name",
        Header: "User Type",
      },
      {
        accessor: "entry",
        Header: "Entry",
        Cell: ({ value, row }) => {
          return capitalize(value);
        },
      },
      {
        accessor: "payment_gateway",
        Header: "Payment method",
        Cell: ({ value, row }) => {
          return capitalize(value?.replace("_", " "));
        },
      },
      {
        accessor: "reference",
        Header: "Reference",
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter",
      },
      {
        accessor: "created_At",
        Header: "Date created",
        Cell: ({ value, row }) => {
          return moment(value).format("ddd MMM DD, yyyy");
        },
      },
      {
        accessor: "updated_At",
        Header: "Last updated",
        Cell: ({ value, row }) => {
          return moment(value).format("ddd MMM DD, yyyy");
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = list || [];
    if (!!currentType) {
      if (currentType !== "all") {
        filtered = filtered?.filter((x) => x?.type === currentType);
      }
    }
    filtered = sortedDataByUpdatedAt(filtered);
    filtered = filtered?.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        name: capitalize(x?.type?.replace("_", " ")),
        userName: x?.user_id?.firstName + " " + x?.user_id?.lastName,
        userEmail: x?.user_id?.email,
        userPhone: x?.user_id?.phoneNumber?.toString(),
        userBankName: x?.user_id?.bank_name,
        userAccountNumber: x?.user_id?.account_number?.toString(),
        userAccountName: x?.user_id?.account_name,
        market: x?.user_id?.market_id?.name || x?.order_id?.market?.name,
        created_At: dateString(x?.createdAt),
        updated_At: dateString(x?.updatedAt),
      };
    });
    return filtered;
  }, [list, currentType]);

  useEffect(() => {
    setStatusModal(false);
  }, [selectedRows]);

  const toggleStatusModal = () => {
    setStatusModal(!statusModal);
  };

  const handleTransactionStatusChange = async (status) => {
    await updateTransactionStatus(status);
  };

  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: "#e0bf00" }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <div className="overflow-hidden justify-content-space-around">
        <Row>
          <Col xl={4} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Pending Transactions"
              value={stats.pending}
              iconComponent={<MoneyCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={4} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Settled Transactions"
              value={stats.settled}
              iconComponent={<MoneyCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={4} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Settled this month"
              value={stats.settledThisMonth}
              iconComponent={<MoneyCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        </Row>
      </div>
      <Row>
        {/* <TransactionTable items={list} title="Transactions" detailsRedirect="/transactions/:id" /> */}
        <DataTable
          nonSelective={true}
          items={list}
          title="Transactions"
          detailsRedirect="/transactions/:id"
          columns={columns}
          data={data}
          excelFileName={"FD_Transactions"}
          excelSheetName={"Transactions"}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          // externalActionButton={
          //   selectedRows?.length > 0 && <CustomButton variant="primary" type="button" text="Update Status" onClick={toggleStatusModal} />
          // }
          externalActionButton1={
            <Col className="d-flex align-items-center">
              {/* Pagination bit */}
              <FormSelect
                value={currentType}
                onChange={(e) => {
                  setCurrentType(e.target.value);
                }}
              >
                {typeOptions.map((size) => (
                  <option key={size?.value} value={size?.value}>
                    {size?.label}
                  </option>
                ))}
              </FormSelect>
            </Col>
          }
        />
      </Row>
      <ChangeTransactionStatus
        isBulk={true}
        show={statusModal && selectedRows?.length > 0}
        close={toggleStatusModal}
        onConfirm={handleTransactionStatusChange}
        isLoading={isBulkUpdating}
      />
    </Fragment>
  );
};

export default Transactions;
