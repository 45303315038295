import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useCreateCountryMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";

export const AddNewCountry = ({ showNew, handleCloseAddNew }) => {
  const [validateOnChange, setValidateOnChange] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").typeError("Must be text"),
    status: Yup.boolean().required("Required").typeError("Must be text"),
    delivery_fee_by_kg: Yup.number().required("Required"),
    other_charges: Yup.number().min(0).required("Required"),
    vat: Yup.number().required("Required"),
    conversion_rate: Yup.number().required("Required"),
    country_tax: Yup.number().min(0).max(15).required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      contact_name: "",
      contact_phone: "",
      name: "",
      name: "",
      conversion_rate: "",
      country_tax: "",
      vat: "",
      other_charges: "",
      delivery_fee_by_kg: "",
      status: "",
    },
    validateOnMount: false,
    validateOnChange: validateOnChange,
    validationSchema,
    onSubmit: (values) => {
      handleAddNew(values);
    },
  });
  const [createCountry, { isLoading }] = useCreateCountryMutation();

  const handleAddNew = async (values) => {
    const resolveValues = values;
    const res = await createCountry(resolveValues);
    if (res?.data?.status) {
      handleCloseAddNew();
      toast.success(res?.data?.message || "Created successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };

  return (
    <Modal show={showNew} onHide={handleCloseAddNew}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Add Country"} />
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={() => {
            setValidateOnChange(true);
            formik.handleSubmit();
          }}
        >
          <Row>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Name"}
                id={"name"}
                placeholder={"Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.name}
                value={formik?.values?.name}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Delivery price/Kg"}
                id={"delivery_fee_by_kg"}
                placeholder={"Delivery price"}
                onChange={formik?.handleChange}
                error={formik?.errors?.delivery_fee_by_kg}
                value={formik?.values?.delivery_fee_by_kg}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Conversion Rate"}
                id={"conversion_rate"}
                placeholder={"Conversion Rate"}
                onChange={formik?.handleChange}
                error={formik?.errors?.conversion_rate}
                value={formik?.values?.conversion_rate}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Country Tax(%)"}
                id={"country_tax"}
                placeholder={"Country Tax"}
                onChange={formik?.handleChange}
                error={formik?.errors?.country_tax}
                value={formik?.values?.country_tax}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Vat(%)"}
                id={"vat"}
                placeholder={"Vat"}
                onChange={formik?.handleChange}
                error={formik?.errors?.vat}
                value={formik?.values?.vat}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Other charges"}
                id={"other_charges"}
                placeholder={"Other charges"}
                onChange={formik?.handleChange}
                error={formik?.errors?.other_charges}
                value={formik?.values?.other_charges}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                inputType={"select"}
                selectOptions={[
                  { label: "Enabled", value: true, id: "true" },
                  { label: "Disabled", value: false, id: "false" },
                ]}
                required={true}
                label={"Status"}
                id={"status"}
                name={"status"}
                placeholder={"Status"}
                onChange={(e) => {
                  formik.setFieldValue("status", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.status}
                value={formik.values.status}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Contact Name"}
                id={"contact_name"}
                placeholder={"Contact Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.contact_name}
                value={formik?.values?.contact_name}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Contact Phone"}
                id={"contact_phone"}
                placeholder={"Contact Phone"}
                onChange={formik?.handleChange}
                error={formik?.errors?.contact_phone}
                value={formik?.values?.contact_phone}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={isLoading || !formik?.isValid}
          button2onClick={() => {
            setValidateOnChange(true);
            formik.handleSubmit();
          }}
          button2Text={"Create"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading}
          button1Text={"Cancel"}
          button1onClick={handleCloseAddNew}
        />
      </Modal.Footer>
    </Modal>
  );
};
