import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { isSubstringInArray } from "helper/formatArray";
import { logOut, logout, setCredentials } from "../features/auth/authSlice";

import { BASE_URL } from "./url";
const baseUrl = BASE_URL;
const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    // get token from useAuth reducer
    const data = getState().userAuth.token;
    if (data) {
      headers.set("Authorization", `Bearer ${data}`);
    }
    return headers;
  },
});

const customFetchBase = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (isSubstringInArray(result?.error?.data?.message || "##", ["expired", "unauthorised", "malformed", "invalid signature"])) {
    const logoutResult = await baseQuery(
      {
        credentials: "omit",
        url: "admins/logout",
        method: "POST",
      },
      api,
      extraOptions,
    );
    api.dispatch(logOut());
    // result = logoutResult;
    result.error.data.message = "Login expired";
  }
  return result;
};

export default customFetchBase;
