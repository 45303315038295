import CustomButton from "components/CustomButton";
import CustomDetailGroup from "components/CustomDetailGroup";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import Loader from "components/Loader";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { calculateAveragePrice } from "helper/formatArray";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteProductMutation, useGetProductMutation } from "redux/features/user/userApi";
import tomatopng from "../../assets/images/png/tomato.png";
import { DeleteProduct } from "./components/DeleteProduct";
import { ImageCarousel } from "./components/ImageCarousel";
const ProductDetails = () => {
  const history = useHistory();
  const location = useLocation();
  const [product, setProduct] = useState(location?.state);
  const [show, Setshow] = useState(false);
  const [getProduct, { isLoading }] = useGetProductMutation();
  const [deleteProduct, { isLoading: isLoadingDeleteProduct }] = useDeleteProductMutation();
  const fetchOne = async () => {
    const res = await getProduct(location?.state?._id);

    if (res?.data?.status === "success") {
      setProduct({ ...product, ...res?.data?.data });
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
      history.goBack();
    }
  };
  const handleDelete = async () => {
    const res = await deleteProduct(location?.state?._id);

    if (res?.data) {
      toast.success(res?.data?.message || "Product deleted successfully");
      Setshow(false);
      history.goBack();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchOne();
  }, []);

  const handleShow = () => {
    Setshow(true);
  };
  const handleClose = () => {
    Setshow(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Row>
      <Card>
        <Row id="comptitle">
          <Col className=" p-3 d-flex align-items-center justify-content-between">
            <Col>
              <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={product?.name} cNColor="primary" />
            </Col>
            <Col className="d-flex justify-content-end align-items-center gap-3">
              <Link
                to={{
                  pathname: product?.country_id?.name?.toLowerCase()?.includes("nigeria") ? "/add-product" : "/add-global-product",
                  state: product,
                }}
              >
                <CustomButton
                  variant="primary"
                  type="submit"
                  text="Update"
                  // loading={}
                />
              </Link>
              {/* <TrashFill onClick={handleShow} size={30} color="#000000" /> */}
            </Col>
          </Col>
        </Row>

        <Row className="py-1">
          <Row className="justify-content-center p-0" style={{ width: "100%" }}>
            <Row className="align-content-center " style={{ width: 500 }}>
              {
                <ImageCarousel
                  images={
                    product?.images?.length > 0
                      ? [product?.imageCover, ...product?.images]?.map((x, index) => {
                          return { id: index + 1, src: x };
                        })
                      : [product?.imageCover]?.map((x, index) => {
                          return { id: index + 1, src: x };
                        })
                  }
                />
              }
            </Row>
          </Row>
          <CustomDetailGroup itemKey="Category:" itemValue={product?.category_id?.name} />
          <CustomDetailGroup
            itemKey="Average Price:"
            itemValue={`${product?.currency}${calculateAveragePrice(product?.priceByMarketId, "price")}`}
          />
          <CustomDetailGroup
            itemKey={"Cost price - Selling price \n- Market - Uom - Kg:"}
            itemValue={product?.priceByMarketId?.map((x) => {
              return (
                <p className="mb-0">
                  {x?.cost_price + "/" + x?.price + "  -  " + x?.market_id?.name + "   -   " + x?.uom_id?.name + "   -   " + x?.kg + "kg"}
                </p>
              );
            })}
          />
          <CustomDetailGroup itemKey="Brand:" itemValue={product?.brand} />
          <CustomDetailGroup itemKey="Description:" itemValue={product?.description} />
          <CustomDetailGroup itemKey="Country:" itemValue={product?.country_id?.name} />
          <CustomDetailGroup itemKey="Availability:" itemValue={product?.available} />
        </Row>
      </Card>
      <DeleteProduct show={show} close={handleClose} product={product} handleDelete={handleDelete} isLoading={isLoadingDeleteProduct} />
    </Row>
  );
};

export default ProductDetails;
