import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useGetCountriesMutation, useGetMarketsMutation, useUpdateAreaMutation, useUpdateUomMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";
import { saveCountries, saveMarkets } from "redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader2 from "components/Loader2";

export const EditArea = ({ showEdit, handleCloseEdit, defaultValues }) => {
  const dispatch = useDispatch();
  const { markets, countries } = useSelector((state) => state.userAuth);
  const [refresh, setRefresh] = useState(false);
  const [marketOptions, setMarketOptions] = useState(
    markets?.map((market) => {
      return { label: market?.name, value: market._id, id: market?._id };
    }),
  );
  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").typeError("Must be text"),
    lga: Yup.string().required("Required").typeError("Must be text"),
    country: Yup.mixed().required("Required").typeError("Must be text"),
    status: Yup.mixed().required("Required").typeError("Must be text"),
    state: Yup.mixed().required("Required").typeError("Must be text"),
    market: Yup.mixed().required("Required").typeError("Must be text"),
  });
  const formik = useFormik({
    initialValues: {
      name: defaultValues?.name || "",
      lga: defaultValues?.lga || "",
      country:
        countries
          ?.map((x) => {
            return { label: x?.name, value: x?._id, id: x?._id };
          })
          ?.find((x) => x?.value === defaultValues?.country?._id) || "",
      state:
        countries
          ?.find((x) => x?._id === defaultValues.country?._id)
          ?.states?.map((x) => {
            return { label: x?.name, value: x?._id, id: x?._id };
          })
          .find((x) => x?.id === defaultValues?.state) || "",
      market:
        markets
          ?.map((x) => {
            return { label: x?.name, value: x?._id, id: x?._id };
          })
          ?.find((x) => x?.value === defaultValues?.market?._id) || "",

      status:
        (defaultValues?.status ? { label: "Enabled", value: true, id: "true" } : { label: "Disabled", value: false, id: "false" }) || "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      let extrCont;
      let extrState;
      let extrMrkt;

      if (typeof values.market === "string") {
        extrMrkt = markets?.find((x) => x?._id === values?.market);
      } else {
        extrMrkt = markets?.find((x) => x?._id === values?.market?.id);
      }
      let market = extrMrkt?._id;
      let market_name = extrMrkt?.name;
      let market_code = extrMrkt?.country_code;
      if (typeof values.country === "string") {
        extrCont = countries?.find((x) => x?._id === values?.country);
      } else {
        extrCont = countries?.find((x) => x?._id === values?.country?.id);
      }
      let country = extrCont?._id;
      let country_name = extrCont?.name;
      let country_code = extrCont?.country_code;
      if (typeof values.state === "string") {
        extrState = extrCont.states?.find((x) => x?._id === values?.state);
      } else {
        extrState = extrCont.states?.find((x) => x?._id === values?.state?.id);
      }
      let state = extrState?._id;
      let state_name = extrState?.name;
      let state_code = extrState?.state_code;
      if (!market_name || !market_code || !country || !market || !state || !country_name || !country_code || !state_name || !state_code) {
        toast.error("Form is invalid or incomplete", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      // const { name: state_name||!state_code } = extrState;
      const resolveValues = {
        ...values,
        market_name,
        market,
        state,
        country,
        market_code,
        country_name,
        country_code,
        state_name,
        state_code,
      };
      handleEdit(resolveValues);
    },
  });

  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [updateArea, { isLoading }] = useUpdateAreaMutation();

  const handleEdit = async (values) => {
    let status;
    if (typeof values?.status === "boolean") {
      status = values?.status;
    } else {
      status = values?.status?.value;
    }
    const resolveValues = { ...values, status };

    const res = await updateArea({ id: defaultValues?._id, data: resolveValues });
    if (res?.data?.status) {
      handleCloseEdit();
      toast.success(res?.data?.message || "Updated successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };

  const [getMarkets, { isLoading: isLoadingMarkets }] = useGetMarketsMutation();

  const fetchList = async () => {
    const res = await getMarkets();
    if (res?.data?.status === "success") {
      dispatch(saveMarkets(res?.data?.data));
      setMarketOptions(
        res?.data?.data?.map((market) => {
          return { label: market?.name, value: market._id, id: market?._id };
        }),
      );
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchList();
    fetchCountries();
  }, []);

  useEffect(() => {
    markets?.map((market) => {
      return { label: market?.name, value: market._id, id: market?._id };
    });
  }, [markets]);
  const countryOptions = useMemo(() => {
    let filtered = countries?.map((x) => {
      return { label: x?.name, value: x?._id, id: x?._id };
    });
    return filtered;
  }, [countries, isLoadingCountries]);

  const stateOptions = useMemo(() => {
    let extrCont;
    if (typeof formik?.values.country === "string") {
      extrCont = countries?.find((x) => x?._id === formik?.values.country);
    } else {
      extrCont = countries?.find((x) => x?._id === formik?.values?.country?.id);
    }

    let filtered;

    if (extrCont?.states && extrCont?.states?.length > 0) {
      filtered = extrCont?.states?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      });
    } else {
      filtered = countries
        ?.find((x) => x?._id === defaultValues.country?._id)
        ?.states?.map((x) => {
          return { label: x?.name, value: x?._id, id: x?._id };
        });
    }
    if (filtered?.length > 0 && (formik?.values?.state == "" || !formik.values?.state)) {
      formik.setFieldValue("state", null);
    }
    return filtered || [];
  }, [countries, formik?.values?.country, countryOptions, isLoadingCountries]);

  return (
    <Modal show={showEdit} onHide={handleCloseEdit}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Edit Area"} />
      </Modal.Header>
      <Modal.Body>
        {isLoadingCountries ? (
          <Loader2 />
        ) : (
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Area"}
                  id={"name"}
                  placeholder={"Area"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.name}
                  value={formik?.values?.name}
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  inputType={"select"}
                  selectOptions={marketOptions}
                  required={true}
                  label={"Connected Market"}
                  id={"market"}
                  placeholder={"Connected Market"}
                  onChange={(e) => {
                    formik.setFieldValue("market", e);
                  }}
                  error={formik?.errors?.market}
                  value={formik?.values?.market}
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Local Govt Area"}
                  id={"lga"}
                  placeholder={"Local Govt Area"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.lga}
                  value={formik?.values?.lga}
                />
              </Col>
              <CustomInput
                as={"select"}
                inputType={"select"}
                selectOptions={countryOptions}
                required={true}
                label={"Country"}
                id={"country"}
                name={"country"}
                placeholder={"Country"}
                onChange={(e) => {
                  formik.setFieldValue("state", "");
                  formik.setFieldValue("country", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.country}
                value={formik.values.country}
              />
              <CustomInput
                as={"select"}
                inputType={"select"}
                selectOptions={stateOptions}
                required={true}
                label={"State"}
                id={"state"}
                name={"state"}
                placeholder={"State"}
                onChange={(e) => {
                  formik.setFieldValue("state", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.state}
                value={formik.values.state}
              />

              <CustomInput
                inputType={"select"}
                selectOptions={[
                  { label: "Enabled", value: true, id: "true" },
                  { label: "Disabled", value: false, id: "false" },
                ]}
                required={true}
                label={"Status"}
                id={"status"}
                name={"status"}
                placeholder={"Status"}
                onChange={(e) => {
                  formik.setFieldValue("status", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.status}
                value={formik.values.status}
              />
            </Row>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={isLoading || !formik?.isValid}
          button2onClick={formik.handleSubmit}
          button2Text={"Confirm"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading}
          button1Text={"Cancel"}
          button1onClick={handleCloseEdit}
        />
      </Modal.Footer>
    </Modal>
  );
};
