import CustomDetailGroup from "components/CustomDetailGroup";
import { CustomText } from "components/CustomText";
import StatRightIcon from "components/StatRightIcon";
import { useEffect, useState } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import CustomerOrders from "./components/CustomerOrders";
import moment from "moment";
import { useGetOrdersMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";
import { extractField, isSubstringInArray } from "helper/formatArray";
import OrderTable from "components/OrderTable";
const UserDetails = (props) => {
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState(props?.location?.state);
  const [refresh, setRefresh] = useState(false);
  const [list, setList] = useState([]);
  const [orders, setOrders] = useState([]);
  const [getOrders, { isLoading }] = useGetOrdersMutation();
  const [stats, setStats] = useState({ ongoing: 0, intransit: 0, total: 0 });

  const fetchList = async () => {
    const res = await getOrders({ customer_id: details.id });

    if (res?.data?.status === "success") {
      const orderList = res?.data?.data;
      setOrders(orderList);
      setStats({
        ongoing: orderList?.filter((x) => isSubstringInArray(x?.order_status, ["open", "accepted", "shopped", "intransit"]))?.length,
        intransit: orderList?.filter((x) => x?.order_status === "intransit")?.length,
        total: orderList?.length,
      });
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: "#e0bf00" }} />
      </Row>
    );
  }

  return (
    <>
      <Row className="m-0 p-0">
        <Col className="m-0 p-0">
          <Card>
            <Row id="comptitle">
              <Col className=" p-3 d-flex align-items-center justify-content-between">
                <CustomText
                  ml="20px"
                  fontFamily={"Montserrat"}
                  fontSize={16}
                  fontWeight={600}
                  text={"Customer Details"}
                  cNColor="primary"
                />
              </Col>
            </Row>

            <Row className="py-1" lg={8} md={12} sm={12}>
              <Col lg={12} md={12} sm={12}>
                <CustomDetailGroup itemKey="First name:" itemValue={details?.firstName} />
              </Col>
              <Col>
                <CustomDetailGroup itemKey="Last name" itemValue={details?.lastName} />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <CustomDetailGroup itemKey="Email Address: " itemValue={details?.email} />
              </Col>

              <Col lg={12} md={12} sm={12}>
                <CustomDetailGroup itemKey="Phone number" itemValue={details?.phoneNumber} />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <CustomDetailGroup itemKey="Primary Address" itemValue={details?.address} />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <CustomDetailGroup itemKey="Market" itemValue={details?.marketArea} />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <CustomDetailGroup itemKey="Date Created" itemValue={moment(details?.createdAt).format("ddd MMM DD YYYY")} />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <CustomDetailGroup itemKey="Last Updated" itemValue={moment(details?.updatedAt).format("ddd MMM DD YYYY")} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={4} lg={4} md={12} className="mt-3">
          <Col>
            <StatRightIcon
              title="Ongoing Orders"
              value={stats.ongoing}
              iconComponent={<StoreCardSvg />}
              iconColorVariant="primary"
              classValue="mb-3"
            />
          </Col>

          <Col>
            <StatRightIcon
              title="Orders In Transit"
              value={stats.intransit}
              iconComponent={<StoreCardSvg />}
              iconColorVariant="primary"
              classValue="mb-3"
            />
          </Col>

          <Col>
            <StatRightIcon
              title="Orders"
              value={stats.total}
              iconComponent={<StoreCardSvg />}
              iconColorVariant="primary"
              classValue="mb-3"
            />
          </Col>
        </Col>
      </Row>

      <OrderTable orders={orders} title="Customer Orders" />
    </>
  );
};

export default UserDetails;
