import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomText } from "components/CustomText";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import tomatopng from "../../assets/images/png/tomato.png";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Card, Col, Dropdown, DropdownButton, Form, FormLabel, FormSelect, Image, InputGroup, Row, Table } from "react-bootstrap";
import { Button } from "bootstrap";
import { useEffect, useMemo, useState } from "react";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { UpdateVerificationStatus } from "./components/UpdateVerificationStatus";
import ShopperOrders from "./components/ShopperOrders";
import ShopperTransactions from "./components/VendorTransactions";
import CustomDetailGroup from "components/CustomDetailGroup";
import moment from "moment";
import { useGetOrdersMutation, useGetTransactionsMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";
import { ImageModal } from "components/DisplayModal";
import { useGetVendorMutation, useUpdateVendorMutation } from "redux/features/user/userApi";
import VendorTransactions from "./components/VendorTransactions";
import { AddVendor } from "./components/AddVendor";
import TransactionTable from "components/TransactionTable";

const VendorDetails = (props) => {
  const [details, setDetails] = useState(props?.location?.state);

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);

  const handleCloseImageModal = () => setShowImageModal(false);

  // const [pagesize, Setpagesize] = useState(20);
  const validationSchema = Yup.object().shape({
    marketName: Yup.string().required("Required").typeError("Must be text"),
    maeketLocation: Yup.string().required("Required").typeError("Must be text"),
  });
  const [status, setStatus] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const handleCloseAdd = () => {
    setShowAdd(false);
    setRefresh(!refresh);
  };
  const handleAdd = () => {
    setShowAdd(true);
  };
  const handleCloseStatus = () => {
    setStatus(false);
  };
  const handleShowStatus = () => {
    setStatus(true);
  };

  const formik = useFormik({
    initialValues: {
      marketName: "",
      marketLocation: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {},
  });

  const [refresh, setRefresh] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [getVendor, { isLoading: isLoadingVendor }] = useGetVendorMutation();
  const [getOrders, { isLoading: isLoadingOrders }] = useGetOrdersMutation();
  const [updateVendor, { isLoading: isLoadingUpdateVendor }] = useUpdateVendorMutation();

  const fetchDetails = async () => {
    const res = await getVendor(details.id || details._id);

    if (res?.data?.status === "success") {
      setDetails(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const [getTransactions, { isLoading }] = useGetTransactionsMutation();

  const fetchTransactions = async () => {
    const res = await getTransactions({ user_id: details.id });
    if (res?.data?.status === "success") {
      const value = res?.data?.data;
      setTransactions(value);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchTransactions();
  }, [refresh]);

  const handleStatusChange = async (selection) => {
    const res = await updateVendor({ id: details.id || details._id, data: { account_verified: selection } });

    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Verification status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseStatus();
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = useMemo(
    () => [
      //   { accessor: "sn", Header: "S/N" },
      //   { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "price",
        Header: "Price",
      },
      {
        accessor: "unitOfMeasure",
        Header: "Unit of Measure",
      },
      // enableSorting: true,
      {
        accessor: "quantity",
        Header: "Quantity",
      },
      {
        accessor: "subTotal",
        Header: "Sub Total",
      },
    ],
    [],
  );

  return (
    <Row>
      <Card>
        <Row id="comptitle">
          <Col className=" p-3 d-flex align-items-center justify-content-between">
            <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Vendor's Details"} cNColor="primary" />
            <div className="d-flex align-items-center justify-content-end">
              <CustomButton
                variant="primary"
                type="button"
                style={{ marginRight: 20 }}
                onClick={handleShowStatus}
                text="Change Status"
                // loading={}
              />
              <CustomButton variant="primary" type="button" onClick={handleAdd} text="Edit Vendor" className={"text-nowrap"} />
            </div>
          </Col>
        </Row>
        <Row className="py-1">
          <CustomDetailGroup itemKey="Vendor code:" itemValue={details?.vendor_code} />
          <CustomDetailGroup
            itemKey="Market:"
            itemValue={`${details?.market_id?.name} (${details?.market_id?.state_name} - ${details?.market_id?.country_name})`}
          />
          <CustomDetailGroup itemKey="First name:" itemValue={details?.firstName} />
          <CustomDetailGroup itemKey="Last name:" itemValue={details?.lastName} />
          <CustomDetailGroup itemKey="Phone number:" itemValue={details?.phoneNumber} />
          <CustomDetailGroup
            itemKey="Verification status:"
            itemValue={details?.account_verified ? "verified" : "unverified"}
            valueClassName={"status"}
          />
          <CustomDetailGroup itemKey="Date Created:" itemValue={moment(details?.createdAt).format("ddd MMM DD YYYY")} />
          <CustomDetailGroup itemKey="Date Updated:" itemValue={moment(details?.updatedAt).format("ddd MMM DD YYYY")} />
        </Row>
        <Row className="py-1">
          <Card>
            <Col id="compsubtitle">
              <Col className=" p-3 ">
                <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Bank Details"} cNColor="primary" />
              </Col>
            </Col>
            <CustomDetailGroup itemKey="Account No:" itemValue={details?.account_number} />
            <CustomDetailGroup itemKey="Account Name:" itemValue={details?.account_name} />
            <CustomDetailGroup itemKey="Bank:" itemValue={details?.bank_name} />
          </Card>
        </Row>
      </Card>
      <TransactionTable items={transactions} title="Vendor Transactions" detailsRedirect="/transactions/:id" />
      <ImageModal
        showModal={showImageModal}
        toggleModal={() => setShowImageModal(!showImageModal)}
        url={selectedImage?.url}
        thumbnail={selectedImage?.thumbnail}
      />
      <UpdateVerificationStatus
        show={status}
        close={handleCloseStatus}
        onConfirm={handleStatusChange}
        initial={details?.account_verified}
      />
      <AddVendor showAdd={showAdd} handleCloseAdd={handleCloseAdd} initialValues={details} type={"edit"} />
    </Row>
  );
};

export default VendorDetails;
