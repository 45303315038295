import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomText } from "components/CustomText";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import { Card, Col, Dropdown, DropdownButton, Form, FormLabel, FormSelect, InputGroup, Row } from "react-bootstrap";
import { Button } from "bootstrap";
import { useState } from "react";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { Products } from "./components/Products";
import OrderSummary from "./components/OrderSummary";
const CreateNewOrder = () => {
  const validationSchema = Yup.object().shape({
    marketName: Yup.string().required("Required").typeError("Must be text"),
    maeketLocation: Yup.string().required("Required").typeError("Must be text"),
  });
  const formik = useFormik({
    initialValues: {
      marketName: "",
      marketLocation: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
    },
  });
  const measureOptions = [
    { label: "Pieces", value: "Pieces" },
    { label: "Paint Bucket", value: "Paint Bucket" },
    { label: "Derica", value: "Derica" },
    { label: "Basket", value: "Basket" },
  ];
  const marketOptions = [
    { label: "Default", value: "Default" },
    { label: "Oshodi", value: "Oshodi" },
    { label: "Yaba", value: "Yaba" },
    { label: "Agege", value: "Agege" },
    { label: "Mile 12", value: "Mile 12" },
  ];
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const [available, setAvailable] = useState(false);
  return (
    <Row>
      {/* <Row className="pb-2"> */}
      <Card className="mb-5">
        <Row id="comptitle">
          <Col className=" p-3 d-flex align-items-center justify-content-between">
            <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text="Create a New Order" cNColor="primary" />
          </Col>
        </Row>
        <Form onSubmit={formik.handleSubmit}>
          <Row className="p-3">
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Email Address"}
                id={"email"}
                placeholder={"Email Address"}
                onChange={formik?.handleChange}
                error={formik?.errors?.email}
                value={formik?.values?.email}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"First Name"}
                id={"firstName"}
                placeholder={"First Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.firstName}
                value={formik?.values?.firstName}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Last Name"}
                id={"lastName"}
                placeholder={"Last Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.lastName}
                value={formik?.values?.lastName}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Phone Number"}
                id={"phoneNumber"}
                placeholder={"Phone Number"}
                onChange={formik?.handleChange}
                error={formik?.errors?.phoneNumber}
                value={formik?.values?.phoneNumber}
              />
            </Col>

            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Delivery Address"}
                id={"deliveryAddress"}
                placeholder={"Delivery Address"}
                onChange={formik?.handleChange}
                error={formik?.errors?.deliveryAddress}
                value={formik?.values?.deliveryAddress}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Market"}
                id={"marketArea"}
                placeholder={"Market"}
                onChange={formik?.handleChange}
                error={formik?.errors?.marketArea}
                value={formik?.values?.marketArea}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Delivery Fee"}
                id={"delieryFee"}
                placeholder={"Delivery Fee"}
                onChange={formik?.handleChange}
                error={formik?.errors?.delieryFee}
                value={formik?.values?.delieryFee}
              />
            </Col>
          </Row>
        </Form>
      </Card>
      <Card className="p-0">
        <Row id="comptitle">
          <Col className=" p-3 px-5 d-flex align-items-center justify-content-between">
            <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text="Order Summary" cNColor="primary" />
            <CustomButton variant="primary" type="button" text="Add Product" onClick={handleShow} />
          </Col>
        </Row>
        {available ? (
          <Col>
            <OrderSummary />
          </Col>
        ) : (
          <Col className="p-6 d-flex text-center justify-content-center">
            <CustomText fontFamily={"Poppins"} fontSize={20} fontWeight={500} text="Empty Order List" cNColor="primary" />
          </Col>
        )}
      </Card>
      <Products show={show} close={handleClose} />
    </Row>
  );
};

export default CreateNewOrder;
