// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as MoneyCardSvg } from "assets/svgs/money-card.svg";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomButton from "components/CustomButton";
import {
  useBulkUpdateOrderTransactionsStatusMutation,
  useBulkUpdateShopperTransactionsStatusMutation,
  useGetTransactionsMutation,
} from "redux/features/user/userApi";
import { useSelector } from "react-redux";
import { isSubstringInArray, sortedDataByUpdatedAt } from "helper/formatArray";
import moment from "moment";
import { ChangeTransactionStatus } from "pages/transactions/components/ChangeTransactionStatus";
import DataTable from "components/DataTable";
import { capitalize, dateString, formatCurrency } from "helper/formatText";
import { TextSearchFilter } from "components/advance-table/filters";
import { convertJsonStringToObject } from "helper/formatObject";

const ShoppersTransactions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { countries, markets } = useSelector((state) => state.userAuth);
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [stats, setStats] = useState({
    pending: 0,
    refunded: 0,
    rejected: 0,
    approved: 0,
    settled: 0,
  });

  const [getTransactions, { isLoading }] = useGetTransactionsMutation();

  const fetchList = async () => {
    const res = await getTransactions({ user_model_name: "Shopper" });

    if (res?.data?.status === "success") {
      const value = res?.data?.data?.map((x, index) => {
        return convertJsonStringToObject(JSON.parse(JSON.stringify(x)));
      });
      setList(value);
      setStats({
        pending: value?.filter((x) => x?.status == "pending")?.length,
        refunded: value?.filter((x) => x?.status == "refunded")?.length,
        rejected: value?.filter((x) => x?.status == "rejected")?.length,
        approved: value?.filter((x) => x?.status == "approved")?.length,
        settled: value?.filter((x) => x?.status == "settled")?.length,
      });
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const [statusModal, setStatusModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkUpdateTransactionsStatus, { isLoading: isBulkUpdating }] = useBulkUpdateShopperTransactionsStatusMutation();
  const updateTransactionStatus = async (status) => {
    if (selectedRows?.filter((x) => x?.type == "shopper_withdrawal")?.length !== selectedRows?.length) {
      toast.error("Manually updating Shopper commissions/vouchers is not allowed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const commonStatus = selectedRows?.filter((x) => x?.status == selectedRows[0]?.status);
    if (commonStatus?.length !== selectedRows?.length) {
      toast.error("Bulk updating transactions with different statuses is not allowed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const res = await bulkUpdateTransactionsStatus({
      ids: selectedRows?.map((x) => {
        return x?.id;
      }),
      value: status,
    });
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Bulk update was successful", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setStatusModal(false);
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = useMemo(
    () => [
      // { accessor: "sn", Header: "S/N" },
      //   { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "Name",
        // Filter: TextSearchFilter,
        // filter: "rankedMatchSorter",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit " to={{ pathname: `/transactions/${row?.original?.id}`, state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return capitalize(value);
        },
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter",
      },
      {
        accessor: "Amount",
        Header: "Amount",
        // Filter: TextSearchFilter,
        // filter: "rankedMatchSorter",
      },
      {
        accessor: "market",
        Header: "Market",
        // Filter: TextSearchFilter,
        // filter: "rankedMatchSorter",
      },
      {
        accessor: "paymentGateway",
        Header: "Payment method",
      },
      {
        accessor: "userName",
        Header: "Sender/Recipient",
        // Filter: TextSearchFilter,
        // filter: "rankedMatchSorter",
      },
      {
        accessor: "userPhone",
        Header: "Phone number",
      },
      {
        accessor: "account_number",
        Header: "Account number",
      },
      {
        accessor: "account_name",
        Header: "Account name",
      },
      {
        accessor: "bank_name",
        Header: "Bank",
      },
      {
        accessor: "orderId",
        Header: "Related Order ID",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit " to={{ pathname: `/orders/${row?.original?.order_id?.id}`, state: row?.original?.order_id }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },

      {
        accessor: "user_model_name",
        Header: "User Type",
      },
      {
        accessor: "entry",
        Header: "Entry",
        Cell: ({ value, row }) => {
          return capitalize(value);
        },
      },
      {
        accessor: "reference",
        Header: "Reference",
        Filter: TextSearchFilter,
        filter: "rankedMatchSorter",
      },
      {
        accessor: "created_At",
        Header: "Date created",
      },
      {
        accessor: "updated_At",
        Header: "Last updated",
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = list || [];
    console.log("filtered", filtered);
    filtered = sortedDataByUpdatedAt(filtered);
    filtered = filtered?.map((x, index) => {
      const currency = countries?.find((y) => isSubstringInArray(y?.name, [x?.country || "Nigeria"]))?.currency;

      return {
        ...x,
        sn: index + 1,
        name: capitalize(x?.type?.replace("_", " ")),
        userName: x?.user_id?.firstName + " " + x?.user_id?.lastName,
        userEmail: x?.user_id?.email,
        userPhone: x?.user_id?.phoneNumber?.toString(),
        bank_name: x?.bank_details?.bank_name,
        account_name: x?.bank_details?.account_name,
        account_number: x?.bank_details?.account_number,
        market: x?.order_id?.market?.name || markets?.find((y) => y?.id === (x?.user_id?.market_id?.id || x?.user_id?.market_id))?.name,
        orderId: x?.order_id?.order_id,
        Amount: `${formatCurrency(x?.amount, 2, x?.type == "order_payment" ? currency?.symbol : undefined)}`,
        paymentGateway: capitalize(x?.payment_gateway?.replace("_", " ")),
        payment_status: capitalize(x?.order_id?.payment_status?.replace("_", " ")),
        created_At: moment(dateString(x?.createdAt)).format("ddd MMM DD, yyyy"),
        updated_At: moment(dateString(x?.updatedAt)).format("ddd MMM DD, yyyy"),
      };
    });
    return filtered;
  }, [list]);

  useEffect(() => {
    setStatusModal(false);
  }, [selectedRows]);

  const toggleStatusModal = () => {
    setStatusModal(!statusModal);
  };

  const handleTransactionStatusChange = async (status) => {
    await updateTransactionStatus(status);
  };

  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: "#e0bf00" }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <div className="overflow-hidden justify-content-space-around">
        <Row>
          <Col xl={4} lg={4} md={6} sm={12}>
            <StatRightIcon
              title="Pending Payments"
              value={stats.pending}
              iconComponent={<MoneyCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
            <StatRightIcon
              title="Settled Payments"
              value={stats.settled}
              iconComponent={<MoneyCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
            <StatRightIcon
              title="Refunded Payments"
              value={stats.refunded}
              iconComponent={<MoneyCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        </Row>
      </div>
      <Row>
        <DataTable
          items={list}
          title="Shoppers Transactions"
          detailsRedirect="/transactions/:id"
          columns={columns}
          data={data}
          excelFileName={"FD_Shoppers_Transactions"}
          excelSheetName={"Shoppers_Transactions"}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          externalActionButton={
            selectedRows?.length > 0 && <CustomButton variant="primary" type="button" text="Update Status" onClick={toggleStatusModal} />
          }
        />
      </Row>
      <ChangeTransactionStatus
        isBulk={true}
        initial={selectedRows[0]?.status}
        show={statusModal && selectedRows?.length > 0}
        close={toggleStatusModal}
        onConfirm={handleTransactionStatusChange}
        isLoading={isBulkUpdating}
        removedOptions={["rejected", "cancelled", "canceled", "approved"]}
      />
    </Fragment>
  );
};

export default ShoppersTransactions;
