// import node module libraries
import React, { useState, useEffect } from "react";

// import sub components
import NavbarVertical from "./NavbarVertical";
import HeaderDefault from "./HeaderDefault";
import { useGetAreasMutation, useGetCountriesMutation, useGetMarketsMutation } from "redux/features/user/userApi";
import { saveCountries, saveMarkets } from "redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const DashboardIndex = (props) => {
  const { children, className, overflowHidden } = props;
  const [showMenu, setShowMenu] = useState(true);
  const ToggleMenu = () => {
    return setShowMenu(!showMenu);
  };
  useEffect(() => {
    document.body.style.backgroundColor = "#f5f4f8";
  });
  const { countries, markets } = useSelector((state) => state.userAuth);

  const dispatch = useDispatch();

  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();
  const [getMarkets, { isLoading: isLoadingMarkets }] = useGetMarketsMutation();
  const [getAreas, { isLoading: isLoadingAreas }] = useGetAreasMutation();

  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchMarkets = async () => {
    const res = await getMarkets();
    if (res?.data?.status === "success") {
      dispatch(saveMarkets(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    console.log("children", "children");
    !countries && countries?.length && fetchCountries();
    !markets && markets?.length && fetchMarkets();
  }, [children]);

  return (
    <div id="db-wrapper" className={`${overflowHidden ? "chat-layout" : ""} ${showMenu ? "" : "toggled"}`}>
      <div className="navbar-vertical navbar">
        <NavbarVertical showMenu={showMenu} onClick={(value) => setShowMenu(value)} />
      </div>
      <div id="page-content">
        <div className="header">
          <HeaderDefault
            data={{
              showMenu: showMenu,
              SidebarToggleMenu: ToggleMenu,
            }}
          />
        </div>
        <div className={`container-fluid ${className ? className : "p-4"}`}>{children}</div>
      </div>
    </div>
  );
};
export default DashboardIndex;
