import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Image, Modal, Row } from "react-bootstrap";
import tomatopng from "assets/images/png/tomato.png";
import decrease from "assets/svgs/minus.svg";
import increase from "assets/svgs/plus.svg";
import GlobalFilter from "componentsx/elements/advance-table/GlobalFilter";
import Product from "./Product";
export const Products = ({ show, close }) => {
  const [search, setSearch] = useState("");

  const data = useMemo(() => {
    let filtered = Array(7).fill(null);
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        id: index + 1,
        amount: 0,
        sn: index + 1,
        name: "Tomato",
        category: Math.random() > 0.3 ? "Spices" : "Vegetables",
        unitprice: Math.random() > 0.5 ? "50" : "100",
        unitofmeasure: "Paint Bucket",
        availability: Math.random() > 0.3 ? "True" : "False",
        updatedAt: Date.now(),
      };
    });

    return filtered.filter((product) => {
      return product?.name?.toLowerCase().includes(search?.toLowerCase());
    });
  }, [search]);

  return (
    <Modal show={show} onHide={close}>
      <Card>
        <Col id="comptitle">
          <Col className=" p-3 d-flex align-items-center justify-content-between">
            <Col>
              <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text="Add Product" cNColor="primary" />
            </Col>
            <Col>
              <CustomInput
                type={"text"}
                required={true}
                label={" "}
                placeholder={"Search"}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Col>
          </Col>
        </Col>
        <Modal.Body>
          {data.map((product) => (
            <Product imageSrc={tomatopng} name={product.name} amount={product.amount} subTotal={product.unitprice} />
          ))}
        </Modal.Body>
      </Card>
    </Modal>
  );
};
