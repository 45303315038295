import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useUpdateCountryMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";
import { getValue } from "helper/formatObject";
import { getValueAsString } from "helper/formatArray";

export const EditCountry = ({ showEdit, handleCloseEdit, defaultValues }) => {
  const validationSchema = Yup.object().shape({
    contact_name: Yup.string().required("Required").typeError("Must be text"),
    contact_phone: Yup.string().required("Required").typeError("Must be text"),
    name: Yup.string().required("Required").typeError("Must be text"),
    status: Yup.mixed().required("Required").typeError("Must be text"),
    delivery_fee_by_kg: Yup.number().required("Required"),
    other_charges: Yup.number().min(0).required("Required"),
    vat: Yup.number().required("Required"),
    conversion_rate: Yup.number().required("Required"),
    country_tax: Yup.number().min(0).max(15).required("Required"),
  });
  const statusOptions = [
    { id: "1", label: "Enabled", value: true },
    { id: "2", label: "Disabled", value: false },
  ];
  console.log("default", defaultValues?.status);

  const formik = useFormik({
    initialValues: {
      contact_name: defaultValues?.contact_name || "",
      contact_phone: defaultValues?.contact_phone || "",
      name: defaultValues?.name || "",
      delivery_fee_by_kg: getValueAsString(defaultValues?.delivery_fee_by_kg) || "",
      conversion_rate: getValueAsString(defaultValues?.conversion_rate) || "",
      country_tax: getValueAsString(defaultValues?.country_tax) || "",
      vat: getValueAsString(defaultValues?.vat) || "",
      other_charges: getValueAsString(defaultValues?.other_charges) || "",
      status: defaultValues?.status ? { id: "1", label: "Enabled", value: true } : { id: "2", label: "Disabled", value: false } || "",
    },
    validateOnMount: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      handleEdit(values);
    },
  });
  const [updateCountry, { isLoading }] = useUpdateCountryMutation();

  const handleEdit = async (values) => {
    let status = getValue(values?.status);

    const resolveValues = { ...values, status };
    const res = await updateCountry({ id: defaultValues?._id, data: resolveValues });
    if (res?.data?.status === "success") {
      handleCloseEdit();
      toast.success(res?.data?.message || "Updated successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };

  return (
    <Modal show={showEdit} onHide={handleCloseEdit}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Edit Country"} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Name"}
                id={"name"}
                placeholder={"Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.name}
                value={formik?.values?.name}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Delivery price/Kg ($)"}
                id={"delivery_fee_by_kg"}
                placeholder={"Delivery price"}
                onChange={formik?.handleChange}
                error={formik?.errors?.delivery_fee_by_kg}
                value={formik?.values?.delivery_fee_by_kg}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Conversion Rate"}
                id={"conversion_rate"}
                placeholder={"Conversion Rate"}
                onChange={formik?.handleChange}
                error={formik?.errors?.conversion_rate}
                value={formik?.values?.conversion_rate}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Country Tax"}
                id={"country_tax"}
                placeholder={"Country Tax"}
                onChange={formik?.handleChange}
                error={formik?.errors?.country_tax}
                value={formik?.values?.country_tax}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Vat(%)"}
                id={"vat"}
                placeholder={"Vat"}
                onChange={formik?.handleChange}
                error={formik?.errors?.vat}
                value={formik?.values?.vat}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"number"}
                required={true}
                label={"Other charges"}
                id={"other_charges"}
                placeholder={"Other charges"}
                onChange={formik?.handleChange}
                error={formik?.errors?.other_charges}
                value={formik?.values?.other_charges}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                inputType="select"
                required={true}
                id="status"
                label="Status"
                placeholder="Select a status"
                onChange={(e) => formik?.setFieldValue("status", e)}
                error={formik?.errors?.status}
                value={formik?.values?.status}
                selectOptions={statusOptions}
                defaultValue={
                  defaultValues?.status ? { id: "1", label: "Enabled", value: true } : { id: "2", label: "Disabled", value: false }
                }
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Contact Name"}
                id={"contact_name"}
                placeholder={"Contact Name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.contact_name}
                value={formik?.values?.contact_name}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Contact Phone"}
                id={"contact_phone"}
                placeholder={"Contact Phone"}
                onChange={formik?.handleChange}
                error={formik?.errors?.contact_phone}
                value={formik?.values?.contact_phone}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={isLoading || !formik?.isValid}
          button2onClick={formik.handleSubmit}
          button2Text={"Confirm"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading}
          button1Text={"Cancel"}
          button1onClick={handleCloseEdit}
        />
      </Modal.Footer>
    </Modal>
  );
};
