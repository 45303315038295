import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomText } from "components/CustomText";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import tomatopng from "../../assets/images/png/tomato.png";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Card, Col, Dropdown, DropdownButton, Form, FormLabel, FormSelect, Image, InputGroup, Row, Table } from "react-bootstrap";
import { Button } from "bootstrap";
import { useEffect, useMemo, useState } from "react";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { UpdateVerificationStatus } from "./components/UpdateVerificationStatus";
import { EditShopper } from "./components/EditShopper";
import ShopperOrders from "./components/ShopperOrders";
import ShopperTransactions from "./components/ShopperTransactions";
import CustomDetailGroup from "components/CustomDetailGroup";
import moment from "moment";
import { toast } from "react-toastify";
import { ImageModal } from "components/DisplayModal";
import { useGetOrdersMutation, useGetShopperMutation, useUpdateShopperMutation } from "redux/features/user/userApi";

const AccountDetails = (props) => {
  const [details, setDetails] = useState(props?.location?.state);

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);

  const handleCloseImageModal = () => setShowImageModal(false);

  // const [pagesize, Setpagesize] = useState(20);
  const validationSchema = Yup.object().shape({
    marketName: Yup.string().required("Required").typeError("Must be text"),
    maeketLocation: Yup.string().required("Required").typeError("Must be text"),
  });
  const [status, setStatus] = useState(false);

  const handleCloseStatus = () => {
    setStatus(false);
  };
  const handleShowStatus = () => {
    setStatus(true);
  };

  const formik = useFormik({
    initialValues: {
      marketName: "",
      marketLocation: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {},
  });

  const [refresh, setRefresh] = useState(false);
  const [ordersList, setOrdersList] = useState([]);
  const [getShoppper, { isLoading: isLoadingShopper }] = useGetShopperMutation();
  const [getOrders, { isLoading: isLoadingOrders }] = useGetOrdersMutation();
  const [updateShopper, { isLoading: isLoadingUpdateShopper }] = useUpdateShopperMutation();

  const [showAdd, setShowAdd] = useState(false);

  const handleCloseAdd = () => {
    setShowAdd(false);
    setRefresh(!refresh);
  };
  const handleAdd = () => {
    setShowAdd(true);
  };

  const fetchDetails = async () => {
    const res = await getShoppper(details.id || details._id);

    if (res?.data?.status === "success") {
      setDetails(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchOrders = async () => {
    const res = await getOrders({ _id: details.id || details._id });

    if (res?.data?.status === "success") {
      setOrdersList(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchDetails();
    fetchOrders();
  }, [refresh]);

  const handleStatusChange = async (selection) => {
    const res = await updateShopper({ id: details.id || details._id, data: { account_verified: selection } });

    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Verification status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseStatus();
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Row>
      <Card>
        <Row id="comptitle">
          <Col className=" p-3 d-flex align-items-center justify-content-between">
            <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Shopper's Details"} cNColor="primary" />
            <div>
              <CustomButton
                variant="primary"
                // type="submit"
                onClick={handleAdd}
                text="Edit Details"
                className="mx-2"
              />
              <CustomButton
                variant="primary"
                // type="submit"
                onClick={handleShowStatus}
                text="Change Status"
                // loading={}
              />
            </div>
          </Col>
        </Row>
        <Form onSubmit={formik.handleSubmit}>
          <Row className="py-1">
            <CustomDetailGroup itemKey="First name:" itemValue={details?.firstName} />
            <CustomDetailGroup itemKey="Last name:" itemValue={details?.lastName} />
            <CustomDetailGroup itemKey="Email Address:" itemValue={details?.email} />
            <CustomDetailGroup itemKey="Phone number:" itemValue={details?.phoneNumber} />
            <CustomDetailGroup itemKey="Street Address:" itemValue={details?.streetAddress} />
            <CustomDetailGroup itemKey="Apartment:" itemValue={details?.apartment} />
            <CustomDetailGroup itemKey="State:" itemValue={details?.state} />
            <CustomDetailGroup
              itemKey="Verification status:"
              itemValue={details?.account_verified ? "verified" : "unverified"}
              valueClassName={"status"}
            />
            <CustomDetailGroup itemKey="Market:" itemValue={details?.market_id?.name} />
            <CustomDetailGroup itemKey="Date Created:" itemValue={moment(details?.createdAt).format("ddd MMM DD YYYY")} />
            <CustomDetailGroup itemKey="Date Updated:" itemValue={moment(details?.updatedAt).format("ddd MMM DD YYYY")} />
          </Row>
          <Row className="py-1">
            <Col>
              <Card>
                <Col id="comptitle">
                  <Col className=" p-3 ">
                    <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Verification Details"} cNColor="primary" />
                  </Col>
                </Col>
                <CustomDetailGroup itemKey="Verification status:" itemValue={details?.account_verified ? "verified" : "unverified"} />
                <CustomDetailGroup
                  itemKey="Passport Photograph:"
                  valueType="picture"
                  imageSrc={
                    <Image
                      src={details?.passport}
                      alt="No image"
                      style={{ height: 200 }}
                      onClick={() => {
                        if (details?.passport) {
                          setSelectedImage({
                            title: "Passport Photograph",
                            url: details?.passport,
                          });
                          setShowImageModal(true);
                        }
                      }}
                    />
                  }
                />
                <CustomDetailGroup
                  itemKey="ID card:"
                  valueType="picture"
                  imageSrc={
                    <Image
                      src={details?.identity_card}
                      alt="No image"
                      style={{ height: 200 }}
                      onClick={() => {
                        if (details?.identity_card) {
                          setSelectedImage({
                            title: "ID card",
                            url: details?.identity_card,
                          });
                          setShowImageModal(true);
                        }
                      }}
                    />
                  }
                />
              </Card>
            </Col>
            <Col>
              <Card>
                <Col id="comptitle">
                  <Col className=" p-3 ">
                    <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} text={"Bank Details"} cNColor="primary" />
                  </Col>
                </Col>
                <CustomDetailGroup itemKey="Account No:" itemValue={details?.account_number} />
                <CustomDetailGroup itemKey="Account Name:" itemValue={details?.account_name} />
                <CustomDetailGroup itemKey="Bank:" itemValue={details?.bank_name} />
              </Card>
            </Col>
          </Row>
          <Row className="py-1">
            <Col>
              <Card>
                <Col>
                  <ShopperOrders />
                </Col>
              </Card>
            </Col>
          </Row>
          <Row className="py-1">
            <Col>
              <Card>
                <Col>
                  <ShopperTransactions />
                </Col>
              </Card>
            </Col>
          </Row>
        </Form>
      </Card>
      <ImageModal
        showModal={showImageModal}
        toggleModal={() => setShowImageModal(!showImageModal)}
        url={selectedImage?.url}
        thumbnail={selectedImage?.thumbnail}
      />
      <UpdateVerificationStatus
        show={status}
        close={handleCloseStatus}
        onConfirm={handleStatusChange}
        initial={details?.account_verified}
      />
      <EditShopper showAdd={showAdd} handleCloseAdd={handleCloseAdd} initialValues={details} type={"edit"} />
    </Row>
  );
};

export default AccountDetails;
