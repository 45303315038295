// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Tab, Nav, Spinner } from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";

// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomText } from "components/CustomText";
import { useGetCustomersMutation, useGetVendorsMutation } from "redux/features/user/userApi";

import NoData from "components/Nodata";
import CustomButton from "components/CustomButton";
import { AddVendor } from "./components/AddVendor";
import moment from "moment";
import { TableHeader } from "components/TableHeader";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { sortedDataByUpdatedAt } from "helper/formatArray";
import { dateString } from "helper/formatText";
const VendorsAccounts = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [actionType, setActionType] = useState("add");
  const [adminDetails, setAdminDetails] = useState([]);
  const handleCloseAdd = () => {
    setShowAdd(false);
    setRefresh(!refresh);
  };
  const handleAdd = () => {
    setShowAdd(true);
  };
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [stats, setStats] = useState({ verified: 0, unverified: 0, total: 0, signUpsThisMonth: 0 });

  const [getVendors, { isLoading }] = useGetVendorsMutation();

  const fetchList = async () => {
    const res = await getVendors({ country: "Nigeria" });

    if (res?.data?.status === "success") {
      setList(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      {
        accessor: "vendor_code",
        Header: "Vendor Code",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit " to={{ pathname: "/vendors/accounts/details", state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "market",
        Header: "Market of Operation",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit " to={{ pathname: "/vendors/accounts/details", state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "firstName",
        Header: "FIRST NAME",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit " to={{ pathname: "/vendors/accounts/details", state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "lastName",
        Header: "Last Name",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit" to={{ pathname: "/vendors/accounts/details", state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "phoneNumber",
        Header: "Phone Number",
        Cell: ({ value, row }) => {
          return value;
        },
      },

      {
        accessor: "account_verified",
        Header: "Verification Status",
        Cell: ({ value }) => {
          return (
            <p className={`mb-0`} style={{ color: value == "Verified" ? "#039D00" : "#DC0000" }}>
              {value}
            </p>
          );
        },
      },
      {
        accessor: "created_At",
        Header: "Date Created",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: "updated_At",
        Header: "Last Updated",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = list;
    filtered = sortedDataByUpdatedAt(filtered);

    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        market: x?.market_id?.name,
        account_status: x?.account_verified ? "Verified" : "Unverified",
        created_At: dateString(x?.createdAt),
        updated_At: dateString(x?.updatedAt),
      };
    });
    setStats({
      verified: filtered?.filter((x) => x?.account_verified)?.length,
      unverified: filtered?.filter((x) => !x?.account_verified)?.length,
      total: filtered?.length,
      signUpsThisMonth: filtered?.filter((x) => moment(x?.createdAt).isSame(new Date(), "month"))?.length,
    });
    return filtered;
  }, [list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);
  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: "#e0bf00" }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <div className="overflow-hidden justify-content-space-around">
        <Row>
          <Col xl={3} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Signups this month"
              value={stats?.signUpsThisMonth}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={3} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Verified Vendors"
              value={stats?.verified}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={3} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Unverified Vendors"
              value={stats?.unverified}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
          <Col xl={3} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Vendors"
              value={stats?.total}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <TableHeader
              title={"Vendor Accounts"}
              data={data}
              searchable={true}
              paginated={true}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              currentPageSize={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              actionButtons={true}
              actionButton1={
                <DownloadTableExcel filename="FD_Vendor Accounts" sheet="Vendor Accounts" currentTableRef={tableRef.current}>
                  <CustomButton variant="primary" type="button" text="Export" />
                </DownloadTableExcel>
              }
              actionButton2={
                <CustomButton variant="primary" type="button" onClick={handleAdd} text="Add Vendor" className={"w-100 text-nowrap"} />
              }
            />
            <Card.Body className="p-0">
              <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                {data.length > 0 ? (
                  <>
                    <thead className="table-light text-center">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                              {column.render("Header")}

                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownSvg style={{ height: 20 }} />
                                ) : (
                                  <SortUpSvg style={{ height: 20 }} />
                                )
                              ) : (
                                ""
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="text-center align-item-center">
                            {row.cells.map((cell) => {
                              return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                ) : (
                  <NoData />
                )}
              </Table>
              {/* Pagination @ Footer */}

              {data.length > 0 ? (
                <Pagination
                  previousPage={previousPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                  nextPage={nextPage}
                />
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddVendor showAdd={showAdd} handleCloseAdd={handleCloseAdd} initialValues={adminDetails} type={actionType} />
    </Fragment>
  );
};

export default VendorsAccounts;
