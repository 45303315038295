import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { useCreateCategoryMutation } from "redux/features/user/userApi";
import { logFormData, objectToFormData } from "helper/objectToFormData";
import { toast } from "react-toastify";

export const AddNewCategory = ({ showNew, handleCloseAddNew }) => {
  const [createCategory, { isLoading }] = useCreateCategoryMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").typeError("Must be text"),
    image: Yup.mixed().required("Image is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      image: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      handleAddNew(values);
    },
  });

  const handleAddNew = async (values) => {
    const resolveValues = objectToFormData(values);
    logFormData(resolveValues);
    const res = await createCategory(resolveValues);
    if (res?.data?.status) {
      handleCloseAddNew();
      toast.success(res?.data?.message || "Created successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };

  return (
    <Modal show={showNew} onHide={handleCloseAddNew}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Create new Category"} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Col lg={12} md={12} className="mb-1">
            <CustomInput
              type={"text"}
              required={true}
              label={" "}
              id={"name"}
              placeholder={"Category"}
              onChange={formik?.handleChange}
              error={formik?.errors?.name}
              value={formik?.values?.name}
            />
          </Col>
          <Col lg={12} md={12} style={{ display: "flex", flexWrap: "wrap" }}>
            {formik?.values?.image && <ProductCardImage src={URL.createObjectURL(formik?.values?.image)} alt="Product" width={"200px"} />}
          </Col>
          <Col lg={12} md={12} className="mb-1">
            <CustomInput
              type={"file"}
              label={"Image"}
              id="image"
              accept="image/*"
              onChange={(event) => {
                formik.setFieldValue("image", event.currentTarget.files[0]);
              }}
            />
          </Col>
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={isLoading || !formik?.isValid}
          button2onClick={formik.handleSubmit}
          button2Text={"Create"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading}
          button1Text={"Cancel"}
          button1onClick={handleCloseAddNew}
        />
      </Modal.Footer>
    </Modal>
  );
};
