// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link } from "react-router-dom";

import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Tab, Nav, Spinner } from "react-bootstrap";

import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";

// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useGetCustomersMutation } from "redux/features/user/userApi";
import moment from "moment";

import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import CountryFlag from "components/CountryFlag/CountryFlag";
import NoData from "components/Nodata";
import CustomButton from "components/CustomButton";
import { TableHeader } from "components/TableHeader";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { sortedDataByUpdatedAt } from "helper/formatArray";

const GlobalUsers = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [pagesize] = useState(20);
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [stats, setStats] = useState({ verified: 0, unverified: 0, total: 0, signUpsThisMonth: 0 });

  const [getCustomers, { isLoading }] = useGetCustomersMutation();

  const fetchList = async () => {
    const res = await getCustomers();

    if (res?.data?.status === "success") {
      setList(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      {
        accessor: "firstName",
        Header: "FIRST NAME",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit" to={{ pathname: "/users/global/details", state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "lastName",
        Header: "Last Name",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit" to={{ pathname: "/users/global/details", state: row?.original }}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "email",
        Header: "Email Address",
        Cell: ({ value, row }) => {
          return value;
        },
      },
      {
        accessor: "phoneNumber",
        Header: "Phone Number",
        Cell: ({ value, row }) => {
          return value;
        },
      },

      {
        accessor: "country",
        Header: "Country",
        Cell: ({ value }) => {
          return (
            <div className="d-flex align-items-left justify-content-between">
              <p className="mb-0 mx-2">{value}</p>
              <CountryFlag country={value} />
            </div>
          );
        },
      },

      {
        accessor: "created_at",
        Header: "Date Created",
        Cell: ({ value }) => {
          return new Date().toDateString();
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = list;
    filtered = sortedDataByUpdatedAt(filtered);

    filtered = filtered?.filter((x) => x.country !== "Nigeria");
    filtered = filtered?.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });
    setStats({
      total: filtered?.length,
      signUpsThisMonth: filtered?.filter((x) => moment(x?.createdAt).isSame(new Date(), "month"))?.length,
    });
    return filtered;
  }, [list]);
  const pagesizes = [20, 50, 100];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      //   pageSize: pagesize,
      initialState: {
        pageSize: pagesize,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;
  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);
  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: "#e0bf00" }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <div className="overflow-hidden justify-content-space-around">
        <Row>
          <Col xl={6} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Signups this month"
              value={stats?.signUpsThisMonth}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={6} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Global Customers"
              value={stats?.total}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <TableHeader
            title={"Global Customers"}
            data={data}
            searchable={true}
            paginated={true}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            setPageSize={setPageSize}
            pageSizeOptions={pageSizeOptions}
            actionButtons={true}
            actionButton1={
              <DownloadTableExcel filename="FD_Global Customers" sheet="Global Customers" currentTableRef={tableRef.current}>
                <CustomButton variant="primary" type="button" text="Export" />
              </DownloadTableExcel>
            }
          />
          <Card>
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                  {data.length > 0 ? (
                    <>
                      <thead className="table-light text-center">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                {column.render("Header")}

                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownSvg style={{ height: 20 }} />
                                  ) : (
                                    <SortUpSvg style={{ height: 20 }} />
                                  )
                                ) : (
                                  ""
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className="text-center align-item-center">
                              {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <NoData />
                  )}
                </Table>
                {/* Pagination @ Footer */}

                {data.length > 0 ? (
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    pageSize={pagesize}
                    nextPage={nextPage}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default GlobalUsers;
