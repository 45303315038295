import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBaseQuery";
import { BASE_URL } from "../url";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getCustomers: builder.mutation({
      query: (params) => ({
        url: `customers`,
        method: "GET",
        params: params,
      }),
    }),
    getCustomer: builder.mutation({
      query: (id) => ({
        url: `customers/${id}`,
        method: "GET",
      }),
    }),
    updateCustomer: builder.mutation({
      query: ({ id, data }) => ({
        url: `customers/update/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteCustomer: builder.mutation({
      query: (id) => ({
        url: `customers/${id}`,
        method: "DELETE",
      }),
    }),
    getShoppers: builder.mutation({
      query: (params) => ({
        url: `shoppers`,
        method: "GET",
        params,
      }),
    }),
    getShopper: builder.mutation({
      query: (id) => ({
        url: `shoppers/admin/${id}`,
        method: "GET",
      }),
    }),
    updateShopper: builder.mutation({
      query: ({ id, data }) => ({
        url: `shoppers/admin/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteShopper: builder.mutation({
      query: (id) => ({
        url: `shoppers/admin/${id}`,
        method: "DELETE",
      }),
    }),
    getAreas: builder.mutation({
      query: (params) => ({
        url: `areas`,
        method: "GET",
        params: params,
      }),
    }),
    getArea: builder.mutation({
      query: (id) => ({
        url: `areas/${id}`,
        method: "GET",
      }),
    }),
    createArea: builder.mutation({
      query: (data) => ({
        url: `areas`,
        method: "POST",
        body: data,
      }),
    }),
    updateArea: builder.mutation({
      query: ({ id, data }) => ({
        url: `areas/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteArea: builder.mutation({
      query: (id) => ({
        url: `areas/${id}`,
        method: "DELETE",
      }),
    }),
    getCategories: builder.mutation({
      query: (params) => ({
        url: `categories`,
        method: "GET",
        params: params,
      }),
    }),
    getCategory: builder.mutation({
      query: (id) => ({
        url: `categories/${id}`,
        method: "GET",
      }),
    }),
    createCategory: builder.mutation({
      query: (data) => ({
        url: `categories`,
        method: "POST",
        body: data,
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ id, data }) => ({
        url: `categories/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `categories/${id}`,
        method: "DELETE",
      }),
    }),
    getProducts: builder.mutation({
      query: (params) => ({
        url: `products/admin`,
        method: "GET",
        params: params,
      }),
    }),
    getProduct: builder.mutation({
      query: (id) => ({
        url: `products/${id}`,
        method: "GET",
      }),
    }),
    createProduct: builder.mutation({
      query: (data) => ({
        url: `products`,
        method: "POST",
        body: data,
      }),
    }),
    updateProduct: builder.mutation({
      query: ({ id, data }) => ({
        url: `products/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `products/${id}`,
        method: "DELETE",
      }),
    }),
    getMarkets: builder.mutation({
      query: (params) => ({
        url: `markets`,
        method: "GET",
        params: params,
      }),
    }),
    getMarket: builder.mutation({
      query: (id) => ({
        url: `markets/${id}`,
        method: "GET",
      }),
    }),
    createMarket: builder.mutation({
      query: (data) => ({
        url: `markets`,
        method: "POST",
        body: data,
      }),
    }),
    updateMarket: builder.mutation({
      query: ({ id, data }) => ({
        url: `markets/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteMarket: builder.mutation({
      query: (id) => ({
        url: `markets/${id}`,
        method: "DELETE",
      }),
    }),
    getSupports: builder.mutation({
      query: (params) => ({
        url: `supports`,
        method: "GET",
        params: params,
      }),
    }),
    getSupport: builder.mutation({
      query: (id) => ({
        url: `supports/${id}`,
        method: "GET",
      }),
    }),
    createSupport: builder.mutation({
      query: (data) => ({
        url: `supports`,
        method: "POST",
        body: data,
      }),
    }),
    updateSupport: builder.mutation({
      query: ({ id, data }) => ({
        url: `supports/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteSupport: builder.mutation({
      query: (id) => ({
        url: `supports/${id}`,
        method: "DELETE",
      }),
    }),
    getCountries: builder.mutation({
      query: (params) => ({
        url: `countries`,
        method: "GET",
        params: params,
      }),
    }),
    getCountry: builder.mutation({
      query: (id) => ({
        url: `countries/${id}`,
        method: "GET",
      }),
    }),
    createCountry: builder.mutation({
      query: (data) => ({
        url: `countries`,
        method: "POST",
        body: data,
      }),
    }),
    updateCountry: builder.mutation({
      query: ({ id, data }) => ({
        url: `countries/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteCountry: builder.mutation({
      query: (id) => ({
        url: `countries/${id}`,
        method: "DELETE",
      }),
    }),
    getUoms: builder.mutation({
      query: (params) => ({
        url: `uoms`,
        method: "GET",
        params: params,
      }),
    }),
    getUom: builder.mutation({
      query: (id) => ({
        url: `uoms/${id}`,
        method: "GET",
      }),
    }),
    createUom: builder.mutation({
      query: (data) => ({
        url: `uoms`,
        method: "POST",
        body: data,
      }),
    }),
    updateUom: builder.mutation({
      query: ({ id, data }) => ({
        url: `uoms/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteUom: builder.mutation({
      query: (id) => ({
        url: `uoms/${id}`,
        method: "DELETE",
      }),
    }),
    getBanks: builder.mutation({
      query: () => ({
        url: "banks",
        method: "GET",
      }),
    }),
    verifyAccountNumber: builder.mutation({
      query: (data) => ({
        url: "shoppers/verify-account-number",
        method: "POST",
        body: data,
      }),
    }),
    getPriceByMarkets: builder.mutation({
      query: (params) => ({
        url: `price-by-markets`,
        method: "GET",
        params: params,
      }),
    }),
    getPriceByMarket: builder.mutation({
      query: (id) => ({
        url: `price-by-markets/${id}`,
        method: "GET",
      }),
    }),
    createPriceByMarket: builder.mutation({
      query: (data) => ({
        url: `price-by-market`,
        method: "POST",
        body: data,
      }),
    }),
    updatePriceByMarket: builder.mutation({
      query: ({ id, data }) => ({
        url: `price-by-market/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deletePriceByMarket: builder.mutation({
      query: (id) => ({
        url: `price-by-market/${id}`,
        method: "DELETE",
      }),
    }),
    createVendor: builder.mutation({
      query: (data) => {
        return {
          url: "vendors/create",
          method: "POST",
          body: data,
        };
      },
    }),
    updateVendor: builder.mutation({
      query: ({ id, data }) => ({
        url: `vendors/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    getVendors: builder.mutation({
      query: () => ({
        url: `vendors`,
        method: "GET",
      }),
    }),
    getVendor: builder.mutation({
      query: (id) => ({
        url: `vendors/${id}`,
        method: "GET",
      }),
    }),
    getVouchers: builder.mutation({
      query: (params) => ({
        url: `vouchers`,
        method: "GET",
        params: params,
      }),
    }),
    getOrders: builder.mutation({
      query: (params) => ({
        url: `orders/admin`,
        method: "GET",
        params: params,
      }),
    }),
    getOrder: builder.mutation({
      query: (id) => ({
        url: `orders/admin/${id}`,
        method: "GET",
      }),
    }),
    createOrder: builder.mutation({
      query: (data) => ({
        url: `orders/admin`,
        method: "POST",
        body: data,
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `orders/admin/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateOrderStatus: builder.mutation({
      query: ({ id, data }) => ({
        url: `orders/admin/status/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    assignOrderShopper: builder.mutation({
      query: ({ id, data }) => ({
        url: `orders/admin/shopper/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    unassignOrderShopper: builder.mutation({
      query: ({ id, data }) => ({
        url: `orders/admin/shopper/remove/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `orders/admin/${id}`,
        method: "DELETE",
      }),
    }),
    getTransactions: builder.mutation({
      query: (params) => ({
        url: `transactions`,
        method: "GET",
        params: params,
      }),
    }),
    bulkUpdateTransactionsStatus: builder.mutation({
      query: (data) => ({
        url: `transactions/status/bulk`,
        method: "PATCH",
        body: data,
      }),
    }),
    bulkUpdateOrderTransactionsStatus: builder.mutation({
      query: (data) => ({
        url: `transactions/status/bulk/orders`,
        method: "PATCH",
        body: data,
      }),
    }),
    bulkUpdateShopperTransactionsStatus: builder.mutation({
      query: (data) => ({
        url: `transactions/status/bulk/shoppers`,
        method: "PATCH",
        body: data,
      }),
    }),
    bulkUpdateVendorTransactionsStatus: builder.mutation({
      query: (data) => ({
        url: `transactions/status/bulk/vendors`,
        method: "PATCH",
        body: data,
      }),
    }),
    getTransaction: builder.mutation({
      query: (id) => ({
        url: `transactions/${id}`,
        method: "GET",
      }),
    }),
    getDashboardStats: builder.mutation({
      query: (id) => ({
        url: `statistics/dashboard`,
        method: "GET",
      }),
    }),
    getOrderStatsByInterval: builder.mutation({
      query: (params) => ({
        url: `statistics/dashboard/orders`,
        method: "GET",
        params: params,
      }),
    }),
  }),
});

export const {
  useGetCustomersMutation,
  useGetCustomerMutation,
  useDeleteCustomerMutation,
  useUpdateCustomerMutation,
  useGetShoppersMutation,
  useGetShopperMutation,
  useDeleteShopperMutation,
  useUpdateShopperMutation,
  useCreateAreaMutation,
  useDeleteAreaMutation,
  useUpdateAreaMutation,
  useGetAreaMutation,
  useGetAreasMutation,
  useCreateMarketMutation,
  useDeleteMarketMutation,
  useUpdateMarketMutation,
  useGetMarketMutation,
  useGetMarketsMutation,
  useCreateSupportMutation,
  useDeleteSupportMutation,
  useUpdateSupportMutation,
  useGetSupportMutation,
  useGetSupportsMutation,
  useCreateUomMutation,
  useDeleteUomMutation,
  useUpdateUomMutation,
  useGetUomMutation,
  useGetUomsMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useUpdateProductMutation,
  useGetProductMutation,
  useGetProductsMutation,
  useCreateCountryMutation,
  useDeleteCountryMutation,
  useUpdateCountryMutation,
  useGetCountryMutation,
  useGetCountriesMutation,
  useCreatePriceByMarketMutation,
  useDeletePriceByMarketMutation,
  useUpdatePriceByMarketMutation,
  useGetPriceByMarketMutation,
  useGetPriceByMarketsMutation,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryMutation,
  useGetCategoriesMutation,
  useGetBanksMutation,
  useVerifyAccountNumberMutation,
  useCreateVendorMutation,
  useUpdateVendorMutation,
  useGetVendorsMutation,
  useGetVendorMutation,
  useGetVouchersMutation,
  useGetOrderMutation,
  useGetOrdersMutation,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useUpdateOrderMutation,
  useUpdateOrderStatusMutation,
  useAssignOrderShopperMutation,
  useUnassignOrderShopperMutation,
  useGetTransactionMutation,
  useGetTransactionsMutation,
  useBulkUpdateTransactionsStatusMutation,
  useBulkUpdateOrderTransactionsStatusMutation,
  useBulkUpdateShopperTransactionsStatusMutation,
  useBulkUpdateVendorTransactionsStatusMutation,
  useGetDashboardStatsMutation,
  useGetOrderStatsByIntervalMutation,
} = userApi;
