import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomText } from "components/CustomText";

export const DeleteProduct = ({ show, close, product, handleDelete, isLoading }) => {
  return (
    <Modal show={show} onHide={close} centered>
      <Modal.Header>
        <Modal.Title>
          <CustomText fontFamily={"Poppins"} fontSize={20} fontWeight={600} text={"Remove Products"} cNColor="primary" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <CustomText
              fontFamily={"Poppins"}
              fontSize={14}
              fontWeight={600}
              text={`Are you sure you want to delete ${product?.name} from Products list`}
              cNColor="primary"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-between p-1">
        <CustomButton
          variant="secondary"
          text="Cancel"
          onClick={close}
          // loading={}
        />

        <CustomButton variant="primary" text="Delete" onClick={handleDelete} isLoading={isLoading} />
      </Modal.Footer>
    </Modal>
  );
};
