// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Tab, Nav, Spinner, Form, InputGroup } from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";

// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import { AddNewAdmin } from "./components/AddNewAdmin";
import { ShowAdminDetails } from "./components/ShowAdminDetails";

import { useGetAdminsMutation } from "redux/features/auth/authApi";

import NoData from "components/Nodata";
import CustomButton from "components/CustomButton";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { TableHeader } from "components/TableHeader";
import { sortedDataByUpdatedAt } from "helper/formatArray";

const Admins = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [list, setList] = useState([]);
  const [adminDetails, setAdminDetails] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showAdd, setAddNewAdmin] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [stats, setStats] = useState({ active: 0, inactive: 0, total: 0, createdThisMonth: 0 });

  const [getAdmins, { isLoading }] = useGetAdminsMutation();

  const fetchList = async () => {
    const res = await getAdmins();

    if (res?.data?.status === "success") {
      setList(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      //   { accessor: "id", Header: "ID" },
      {
        accessor: "firstName",
        Header: "FIRST NAME",
        Cell: ({ value, row }) => {
          return (
            <p className="mb-0" onClick={() => handleShowAdminDetails(row?.original)}>
              {value}
            </p>
          );
        },
      },
      {
        accessor: "lastName",
        Header: "Last Name",
        Cell: ({ value, row }) => {
          return (
            <p className="mb-0" onClick={() => handleShowAdminDetails(row?.original)}>
              {value}
            </p>
          );
        },
      },
      {
        accessor: "role",
        Header: "Role",
        Cell: ({ value, row }) => {
          return <p className="mb-0 text-capitalize">{value}</p>;
        },
      },
      {
        accessor: "email",
        Header: "Email",
      },
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return <p className={`mb-0 text-capitalize ${value == "active" ? "green" : "red"}`}>{value}</p>;
        },
      },
      {
        accessor: "updatedAt",
        Header: "Last Updated",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: "createdAt",
        Header: "Date Created",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: "action",
        Header: "Action",
        Cell: ({ value, row }) => {
          return (
            <CustomButton variant="primary" onClick={() => handleShowEdit(row?.original)}>
              Edit
            </CustomButton>
          );
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = list;
    filtered = sortedDataByUpdatedAt(filtered);

    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });
    setStats({
      active: filtered?.filter((x) => x?.status == "active")?.length,
      inactive: filtered?.filter((x) => x?.status == "inactive")?.length,
      total: filtered?.length,
      createdThisMonth: filtered?.filter((x) => moment(x?.createdAt).isSame(new Date(), "month"))?.length,
    });
    return filtered;
  }, [list, search]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // pageSize: pagesize,
      initialState: {
        pageSize: 20,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];

  const [actionType, setActionType] = useState("add");
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);
  const handleCloseAdd = () => {
    setAddNewAdmin(false);
    setRefresh(!refresh);
  };
  const handleAdd = () => {
    setAddNewAdmin(true);
  };
  const handleCloseDetails = () => {
    setShowDetails(false);
    setRefresh(!refresh);
  };
  const handleShowEdit = (admin) => {
    setActionType("edit");
    setAdminDetails(admin);
    setAddNewAdmin(true);
  };
  const handleShowAdminDetails = (admin) => {
    setAdminDetails(admin);
    setShowDetails(true);
  };

  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: "#e0bf00" }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <div className="overflow-hidden justify-content-space-around">
        <Row>
          <Col xl={3} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Created this month"
              value={stats?.createdThisMonth}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={3} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Active Admins"
              value={stats?.active}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>

          <Col xl={3} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Inactive Admin"
              value={stats?.inactive}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
          <Col xl={3} lg={6} md={6} sm={12}>
            <StatRightIcon
              title="Admins"
              value={stats?.total}
              iconComponent={<UserCardSvg />}
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <TableHeader
              title={"Admins"}
              data={data}
              searchable={true}
              paginated={true}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              currentPageSize={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              actionButtons={true}
              actionButton1={
                <DownloadTableExcel filename="FD_Admins" sheet="Admins" currentTableRef={tableRef.current}>
                  <CustomButton variant="primary" type="button" text="Export" />
                </DownloadTableExcel>
              }
              actionButton2={<CustomButton variant="primary" type="button" text="Add New" onClick={handleAdd} />}
            />
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                  {data.length > 0 ? (
                    <>
                      <thead className="table-light text-center">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                {column.render("Header")}

                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownSvg style={{ height: 20 }} />
                                  ) : (
                                    <SortUpSvg style={{ height: 20 }} />
                                  )
                                ) : (
                                  ""
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className="text-center align-item-center">
                              {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <NoData />
                  )}
                </Table>
                {/* Pagination @ Footer */}

                {data.length > 0 ? (
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ShowAdminDetails showDetails={showDetails} handleCloseDetails={handleCloseDetails} defaultValues={adminDetails} />
      <AddNewAdmin showAdd={showAdd} handleCloseAdd={handleCloseAdd} initialValues={adminDetails} type={actionType} />
    </Fragment>
  );
};

export default Admins;
