import { Route, Switch, Redirect } from "react-router-dom";

import "assets/scss/theme.scss";

import { useSelector } from "react-redux";
import Login from "pages/auth/Login";
import AuthLayout from "layoutsx/dashboard/AuthLayout";
import DashboardIndex from "layouts/DashboardIndex";
import Dashboard from "pages/dashboard/Dashboard";
import LocalUsers from "pages/users/LocalUsers";
import GlobalUsers from "pages/users/GlobalUsers";
import Admins from "pages/users/Admins";
import GlobalProducts from "pages/stores/GlobalProducts";
import LocalProducts from "pages/stores/LocalProducts";
import GlobalOrders from "pages/orders/GlobalOrders";

import Vouchers from "pages/vouchers/Vouchers";
import ShoppersTransactions from "pages/shoppers/ShoppersTransactions";
import ShoppersAccounts from "pages/shoppers/ShoppersAccounts";
import Market from "pages/ContentManagement/Market";
import UnitTypes from "pages/ContentManagement/UnitTypes";
import Countries from "pages/ContentManagement/Countries";
import UserDetails from "pages/users/UserDetails";
import ProductDetails from "pages/stores/ProductDetails";
import OrderDetails from "pages/orders/OrderDetails";
import AccountDetails from "pages/shoppers/AccountDetails";
import TransactionDetails from "pages/transactions/TransactionDetails";
import AddProduct from "pages/stores/AddProduct";
import CreateNewOrder from "pages/orders/CreateNewOrder";
import Category from "pages/ContentManagement/Category";
import Areas from "pages/ContentManagement/Areas";
import VendorsAccounts from "pages/vendors/VendorsAccounts";
import VendorsTransactions from "pages/vendors/VendorsTransactions";
import VendorDetails from "pages/vendors/VendorDetails";
import AddGlobalProduct from "pages/stores/AddGlobalProduct";
import LocalOrders from "pages/orders/LocalOrders";
import Transactions from "pages/transactions/Transactions";
import Support from "pages/ContentManagement/Support";
import LocalOrdersTransactions from "pages/orders/LocalOrdersTransactions";
import GlobalOrdersTransactions from "pages/orders/GlobalOrdersTransactions";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);

const ProtectedAppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { user, token, isAuthenticated, role } = useSelector((state) => state.userAuth);
  const { path } = rest;
  return token ? (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props}></Component>
        </Layout>
      )}
    ></Route>
  ) : (
    <Redirect to="/login" />
  );
};

function AllRoutes() {
  return (
    <Switch>
      <AppRoute exact path="/login" layout={AuthLayout} component={Login} />
      <ProtectedAppRoute exact path="/" layout={DashboardIndex} component={Dashboard} />
      <ProtectedAppRoute exact path="/users/local" layout={DashboardIndex} component={LocalUsers} />
      <ProtectedAppRoute exact path="/users/local/details" layout={DashboardIndex} component={UserDetails} />
      <ProtectedAppRoute exact path="/users/global" layout={DashboardIndex} component={GlobalUsers} />
      <ProtectedAppRoute exact path="/users/global/details" layout={DashboardIndex} component={UserDetails} />
      <ProtectedAppRoute exact path="/users/admin" layout={DashboardIndex} component={Admins} />
      <ProtectedAppRoute exact path="/store/global" layout={DashboardIndex} component={GlobalProducts} />
      <ProtectedAppRoute exact path="/store/global/:id" layout={DashboardIndex} component={ProductDetails} />
      <ProtectedAppRoute exact path="/add-product" layout={DashboardIndex} component={AddProduct} />
      <ProtectedAppRoute exact path="/add-global-product" layout={DashboardIndex} component={AddGlobalProduct} />
      <ProtectedAppRoute exact path="/store/local" layout={DashboardIndex} component={LocalProducts} />
      <ProtectedAppRoute exact path="/store/local/:id" layout={DashboardIndex} component={ProductDetails} />
      <ProtectedAppRoute exact path="/orders/global" layout={DashboardIndex} component={GlobalOrders} />
      <ProtectedAppRoute exact path="/orders/" layout={DashboardIndex} component={LocalOrders} />
      <ProtectedAppRoute exact path="/orders/add-new-order" layout={DashboardIndex} component={CreateNewOrder} />
      <ProtectedAppRoute exact path="/orders/local/transactions" layout={DashboardIndex} component={LocalOrdersTransactions} />
      <ProtectedAppRoute exact path="/orders/global/transactions" layout={DashboardIndex} component={GlobalOrdersTransactions} />
      <ProtectedAppRoute exact path="/orders/:id" layout={DashboardIndex} component={OrderDetails} />
      <ProtectedAppRoute exact path="/transactions" layout={DashboardIndex} component={Transactions} />

      <ProtectedAppRoute exact path="/shoppers/accounts" layout={DashboardIndex} component={ShoppersAccounts} />
      <ProtectedAppRoute exact path="/shoppers/accounts/:id" layout={DashboardIndex} component={AccountDetails} />
      <ProtectedAppRoute exact path="/shoppers/transactions" layout={DashboardIndex} component={ShoppersTransactions} />
      <ProtectedAppRoute exact path="/transactions/:id" layout={DashboardIndex} component={TransactionDetails} />

      <ProtectedAppRoute exact path="/vendors/accounts" layout={DashboardIndex} component={VendorsAccounts} />
      <ProtectedAppRoute exact path="/vendors/accounts/:id" layout={DashboardIndex} component={VendorDetails} />
      <ProtectedAppRoute exact path="/vendors/transactions" layout={DashboardIndex} component={VendorsTransactions} />

      <ProtectedAppRoute exact path="/vouchers" layout={DashboardIndex} component={Vouchers} />

      <ProtectedAppRoute exact path="/content-management/support" layout={DashboardIndex} component={Support} />
      <ProtectedAppRoute exact path="/content-management/markets" layout={DashboardIndex} component={Market} />
      <ProtectedAppRoute exact path="/content-management/unit-types" layout={DashboardIndex} component={UnitTypes} />
      <ProtectedAppRoute exact path="/content-management/countries" layout={DashboardIndex} component={Countries} />
      <ProtectedAppRoute exact path="/content-management/category" layout={DashboardIndex} component={Category} />
      <ProtectedAppRoute exact path="/content-management/areas" layout={DashboardIndex} component={Areas} />

      <Redirect to="/login" />
    </Switch>
  );
}

export default AllRoutes;
