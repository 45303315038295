import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../customFetchBaseQuery";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getOrders: builder.mutation({
      query: (params) => ({
        url: `orders/admin`,
        method: "GET",
        params: params,
      }),
    }),
    getOrder: builder.mutation({
      query: (id) => ({
        url: `orders/admin/${id}`,
        method: "GET",
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `orders/admin/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `orders/admin/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useGetOrderMutation, useGetOrdersMutation, useUpdateOrderMutation, useDeleteOrderMutation } = ordersApi;
