import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useForgotPasswordMutation, useResetPasswordMutation, useUpdatePasswordMutation } from "redux/features/auth/authApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CustomText } from "components/CustomText";

export const ForgotPassword = ({ showAdd, handleCloseAdd }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
  });
  const validationSchema1 = Yup.object().shape({
    token: Yup.string().min(6).required("Required"),
    password: Yup.string().min(8).required("Required"),
    passwordConfirm: Yup.string().min(8).required("Required"),
  });

  const { user } = useSelector((state) => state.userAuth);

  const [page, setPage] = useState(1);

  const formik = useFormik({
    initialValues: {
      token: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema: page === 1 ? validationSchema : validationSchema1,
    onSubmit: (values) => {
      const { email, ...others } = values;
      if (page === 1) {
        handleForgotPassword({ email });
      } else {
        handleResetPassword(others);
      }
    },
  });

  useEffect(() => {
    setPage(1);
    formik.resetForm();
  }, [showAdd]);

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [resetPassword, { isLoading: isResetting }] = useResetPasswordMutation();

  const handleForgotPassword = async (values) => {
    const res = await forgotPassword(values);
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setPage(2);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleResetPassword = async (values) => {
    const res = await resetPassword(values);
    if (res?.data?.status === "success") {
      toast.success("Password reset successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseAdd();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={showAdd} onHide={handleCloseAdd} centered backdrop="static" keyboard={false}>
      <Modal.Header id="comptitle">
        <CustomText
          text={(page === 1 ? "Forgot" : "Reset") + " Password"}
          fontSize={14}
          fontWeight={500}
          fontFamily={"Poppins"}
          cNColor="text-black"
        />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          {page === 1 ? (
            <Row>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Email Address"}
                  id={"email"}
                  placeholder={"example@example.com"}
                  onChange={formik?.handleChange}
                  value={formik?.values?.email}
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"OTP"}
                  id={"token"}
                  placeholder={"123456"}
                  onChange={formik?.handleChange}
                  value={formik?.values?.token}
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"New Password"}
                  id={"password"}
                  placeholder={"***************"}
                  onChange={formik?.handleChange}
                  value={formik?.values?.password}
                  isPassword
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Confirm Password"}
                  id={"passwordConfirm"}
                  placeholder={"***************"}
                  onChange={formik?.handleChange}
                  value={formik?.values?.passwordConfirm}
                  isPassword
                />
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={!formik?.isValid || isLoading}
          button1disabled={!formik?.isValid || isLoading}
          button2Text={"Confirm"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1Text={"Cancel"}
          button1onClick={handleCloseAdd}
          button2onClick={formik.handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
};
