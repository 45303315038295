import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import tomatopng from "assets/images/png/tomato.png";
import { getValue } from "helper/formatObject";
export const ChangeOrderPaymentStatus = ({ initial, show, close = () => {}, onConfirm = () => {}, isLoading }) => {
  const statusOptions = [
    { label: "Unpaid", value: "unpaid" },
    { label: "Paid", value: "paid" },
  ];
  const [status, setStatus] = useState(statusOptions.find((x) => x.value == initial?.payment_status));

  useEffect(() => {
    setStatus(statusOptions.find((x) => x.value == initial?.payment_status));
  }, [initial, show]);

  const filteredOptions = useMemo(() => {
    let filtered = statusOptions || [];
    return filtered;
  }, [initial, statusOptions]);

  return (
    <Modal show={show} onHide={close} size={"sm"}>
      <Card>
        <Modal.Header>
          <Modal.Title>
            <CustomText fontFamily={"Poppins"} fontSize={16} fontWeight={600} text={"Change Status"} cNColor="primary" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomInput
            label="Order Payment Status"
            inputType="select"
            selectOptions={filteredOptions}
            onChange={(e) => setStatus(e)}
            value={status || statusOptions.find((x) => x.value == initial?.payment_status)}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <CustomButton
            variant="secondary"
            type="submit"
            //   disabled={!formik?.isValid}
            text="Cancel"
            onClick={close}
            // loading={}
          />

          <CustomButton
            variant="primary"
            type="submit"
            text="Confirm"
            onClick={() => {
              onConfirm(getValue(status));
            }}
            disabled={!filteredOptions?.find((x) => x.value == getValue(status))}
            loading={isLoading}
          />
        </Modal.Footer>
      </Card>
    </Modal>
  );
};
