import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useCreateSupportMutation, useUpdateSupportMutation } from "redux/features/user/userApi";
import { toast } from "react-toastify";
import { getValue } from "helper/formatObject";

export const supportOptions = [
  { label: "Telephone", value: "telephone", id: "telephone" },
  { label: "Live Chat", value: "live_chat", id: "live_chat" },
  { label: "Email", value: "email", id: "email" },
  { label: "Facebook Messenger", value: "facebook_messenger", id: "facebook_messenger" },
  { label: "Twitter", value: "twitter", id: "twitter" },
  { label: "WhatsApp", value: "whatsapp", id: "whatsapp" },
  { label: "Instagram Direct", value: "instagram_direct", id: "instagram_direct" },
  { label: "LinkedIn Messaging", value: "linkedin_messaging", id: "linkedin_messaging" },
  { label: "SMS", value: "sms", id: "sms" },
  { label: "Video Chat", value: "video_chat", id: "video_chat" },
  { label: "Helpdesk Ticket System", value: "helpdesk_ticket", id: "helpdesk_ticket" },
];
export const EditSupport = ({ showEdit, handleCloseEdit, defaultValues }) => {
  const typeInit = useMemo(() => {
    let filtered = supportOptions?.find((x) => x?.value === defaultValues?.type);
    return filtered;
  }, [defaultValues]);

  const statusInit = useMemo(() => {
    let filtered = defaultValues?.status ? { label: "Enable", value: true } : { label: "Disable", value: false };
    return filtered;
  }, [defaultValues]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").typeError("Must be text"),
    type: Yup.mixed().required("Required").typeError("Must be text"),
    description: Yup.string().optional().typeError("Must be text"),
    link: Yup.string().required().typeError("Must be text"),
    status: Yup.mixed().required("Required").typeError("Must be true or false"),
  });

  const formik = useFormik({
    initialValues: {
      name: defaultValues?.name || "",
      type: typeInit || "",
      description: defaultValues?.description || "",
      link: defaultValues?.link || "",
      status: statusInit || { label: "Enable", value: true },
    },
    validateOnMount: false,
    // validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      handleEdit(values);
    },
  });
  const [createSupport, { isLoading: isAdding }] = useCreateSupportMutation();
  const [updateSupport, { isLoading: isUpdating }] = useUpdateSupportMutation();

  const handleEdit = async (values) => {
    const resolveValues = { ...values, type: getValue(values?.type), status: getValue(values?.status) };
    const res = !defaultValues ? await createSupport(resolveValues) : await updateSupport({ id: defaultValues?._id, data: resolveValues });
    if (res?.data?.status) {
      handleCloseEdit();
      toast.success(res?.data?.message || "Updated successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };
  return (
    <Modal show={showEdit} onHide={handleCloseEdit}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={(!defaultValues ? "New" : "Edit") + " Support"} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Title/Link"}
                id={"name"}
                placeholder={"Title/Link"}
                onChange={formik?.handleChange}
                error={formik?.errors?.name}
                value={formik?.values?.name}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                as={"select"}
                inputType={"select"}
                selectOptions={supportOptions}
                required={true}
                label={"Type"}
                id={"type"}
                name={"type"}
                placeholder={"Type"}
                onChange={(e) => {
                  formik.setFieldValue("type", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.type}
                value={formik.values.type}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                as={"select"}
                inputType={"select"}
                selectOptions={[
                  { label: "Enable", value: true },
                  { label: "Disable", value: false },
                ]}
                required={true}
                label={"Status"}
                id={"status"}
                name={"status"}
                placeholder={"Status"}
                onChange={(e) => {
                  formik.setFieldValue("status", e);
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.status}
                value={statusInit || formik.values.status}
              />
            </Col>

            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Link"}
                id={"link"}
                placeholder={"Link"}
                onChange={formik?.handleChange}
                error={formik?.errors?.link}
                value={formik?.values?.link}
              />
            </Col>
            {/* <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"textarea"}
                required={true}
                label={"Description"}
                id={"description"}
                placeholder={"Description"}
                onChange={formik?.handleChange}
                error={formik?.errors?.description}
                value={formik?.values?.description}
              />
            </Col> */}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isAdding || isUpdating}
          button2disabled={isAdding || isUpdating || !formik?.isValid}
          button2onClick={formik.handleSubmit}
          button2Text={"Confirm"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isAdding || isUpdating}
          button1Text={"Cancel"}
          button1onClick={handleCloseEdit}
        />
      </Modal.Footer>
    </Modal>
  );
};
