// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Card, Form, Button, Image, InputGroup } from "react-bootstrap";

// import media files
import { useFormik } from "formik";
import { useLoginMutation } from "../../redux/features/auth/authApi";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { logout, setAuthState, setCredentials } from "../../redux/features/auth/authSlice";
import { Icon } from "react-icons-kit";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "assets/svgs/logo2.svg";
import BackOfficeSvg from "assets/svgs/BackOffice.svg";
import { ReactComponent as EmailSvg } from "assets/svgs/carbon_email.svg";
import { ReactComponent as KeySvg } from "assets/svgs/carbon_password.svg";
import { ReactComponent as EyeOnSvg } from "assets/svgs/eyeOpen.svg";
import { ReactComponent as EyeOffSvg } from "assets/svgs/eyeClose.svg";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import { ForgotPassword } from "./ForgotPassword";
import CustomButton from "components/CustomButton";

const Login = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const [login, { isLoading }] = useLoginMutation();

  const handleLogin = async (values) => {
    const res = await login(values);
    if (res?.data?.status === "success") {
      dispatch(
        setCredentials({
          user: res?.data?.user,
          token: res?.data?.token,
        }),
      );
      history.push("/");
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={6} md={9} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <Row className="align-items-md-center">
                <Col className="d-none d-md-block align-center" md={3}>
                  <Image src={Logo} alt="" className="img-fluid mb-3" />
                  <Image src={BackOfficeSvg} alt="" className="img-fluid" />
                </Col>
                <Col md={9}>
                  <div className="mb-4 justify-content-center">
                    <CustomText fontFamily={"Montserrat"} fontSize={32} text="Sign in" />
                    <CustomText fontFamily={"Montserrat"} fontSize={16} text="Sign in to Fooddy BackOffice" cNColor="text-secondary" />
                  </div>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col lg={12} md={12} className="mb-3">
                        {/* Username or email */}
                        <CustomInput
                          variant="auth"
                          type={"email"}
                          required={true}
                          id={"email"}
                          placeholder={"Email address"}
                          onChange={formik?.handleChange}
                          leftIcon={<EmailSvg />}
                          error={formik?.errors?.email}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <CustomInput
                          variant="auth"
                          type={passwordShown ? "text" : "password"}
                          required={true}
                          id={"password"}
                          placeholder={"***************"}
                          onChange={formik?.handleChange}
                          rightAction={togglePassword}
                          rightIcon={passwordShown ? <EyeOnSvg /> : <EyeOffSvg />}
                          leftIcon={<KeySvg />}
                        />
                      </Col>
                      <Col lg={12} md={12} className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <CustomButton
                            variant="primary"
                            type="submit"
                            text={"Sign in"}
                            className="w-50"
                            size="md"
                            loading={isLoading}
                            disabled={!formik?.isValid}
                          />

                          <Link to="#" onClick={() => setVisible(true)} className="text-secondary">
                            Forgot password?
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ForgotPassword showAdd={visible} handleCloseAdd={() => setVisible(false)} />
    </Fragment>
  );
};

export default Login;
