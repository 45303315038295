import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { toast } from "react-toastify";
import { useCreateMarketMutation, useGetCountriesMutation, useUpdateMarketMutation } from "redux/features/user/userApi";
import { useSelector } from "react-redux";
import { saveCountries } from "redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import Loader from "components/Loader";
import Loader2 from "components/Loader2";
import { CustomText } from "components/CustomText";
import { getValue } from "helper/formatObject";
import { getValueAsString } from "helper/formatArray";

export const EditMarket = ({ show, handleClose, initial }) => {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.userAuth);
  const [refresh, setRefresh] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [initialStates, setInitialStates] = useState(null);
  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();

  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status == "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    (!countries || countries?.length < 1) && fetchCountries();
  }, [refresh]);

  const [createMarket, { isLoading: isCreating }] = useCreateMarketMutation();
  const [updateMarket, { isLoading }] = useUpdateMarketMutation();
  const validationSchema = Yup.object().shape({
    contact_name: Yup.string().required("Required").typeError("Must be text"),
    contact_phone: Yup.string().min(12).max(14).required("Required").typeError("Must be text"),
    name: Yup.string().required("Required").typeError("Must be text"),
    country: Yup.mixed().required("Required").typeError("Must be text"),
    status: Yup.mixed().required("Required").typeError("Must be text"),
    state: Yup.mixed().required("Required").typeError("Must be text"),
    delivery_fee: Yup.number().required("Required").typeError("Must be Number"),
    minimum_order_value: Yup.number().required("Required").typeError("Must be Number"),
    shopper_commission: Yup.number().required("Required").typeError("Must be Number"),
    service_charge: Yup.number().required("Required").typeError("Must be Number"),
    pickup_available: Yup.boolean().required("Required").typeError("Must be True/False"),
    pickup_station_city: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_state: Yup.mixed().when("pickup_available", {
      is: true,
      then: Yup.mixed().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_country: Yup.mixed().when("pickup_available", {
      is: true,
      then: Yup.mixed().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_name: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_description: Yup.string().typeError("Must be text"),
    pickup_station_street_address: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_apartment: Yup.string(),
    pickup_station_additional_info: Yup.string(),
    pickup_station_postal_code: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_contact_name: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_contact_phone_prefix: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().min(2).max(3).required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
    pickup_station_contact_phone: Yup.string().when("pickup_available", {
      is: true,
      then: Yup.string().min(10).max(11).required("Required Field is mandatory when a pickup station exists.").typeError("Must be text"),
    }),
  });

  const countryInit = useMemo(() => {
    let filtered = countries
      ?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      })
      ?.find((x) => x?.value == initial?.country?._id);
    return filtered;
  }, [initial, countries]);
  const stateInit = useMemo(() => {
    let filtered = countries
      ?.find((x) => x?._id == initial?.country?._id)
      ?.states?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      })
      .find((x) => x?.label == initial?.state_name);
    return filtered;
  }, [initial, countries]);

  const pickupCountryInit = useMemo(() => {
    let filtered = countries
      ?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      })
      ?.find((x) => x?.value == initial?.pickup_station_country?.id);
    return filtered;
  }, [initial, countries]);
  const pickupStateInit = useMemo(() => {
    let filtered = countries
      ?.find((x) => x?.name == initial?.pickup_station_country_name)
      ?.states?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      })
      .find((x) => x?.id == initial?.pickup_station_state);

    return filtered;
  }, [initial, countries]);

  const statusOptions = [
    { id: "1", label: "Enabled", value: true },
    { id: "2", label: "Disabled", value: false },
  ];

  const formik = useFormik({
    initialValues: {
      contact_name: initial?.contact_name || "",
      contact_phone: initial?.contact_phone || "",
      name: initial?.name || "",
      country: countryInit || "",
      state: stateInit || "",
      shopper_commission: initial?.shopper_commission || "",
      service_charge: initial?.service_charge || "",
      delivery_fee: getValueAsString(initial?.delivery_fee) || "",
      minimum_order_value: getValueAsString(initial?.minimum_order_value) || "",
      status: initial?.status ? { id: "1", label: "Enabled", value: true } : { id: "2", label: "Disabled", value: false } || "",
      pickup_available:
        !!initial?.pickup_station_street_address ||
        !!initial?.pickup_station_country ||
        !!initial?.pickup_station_city ||
        !!initial?.pickup_station_state ||
        !!initial?.pickup_station_postal_code,
      pickup_station_city: initial?.pickup_station_city || "",
      pickup_station_state: pickupStateInit || "",
      pickup_station_country: pickupCountryInit || "",
      pickup_station_name: initial?.pickup_station_name || "",
      pickup_station_description: initial?.pickup_station_description || "",
      pickup_station_street_address: initial?.pickup_station_street_address || initial?.pickup_station || "",
      pickup_station_apartment: initial?.pickup_station_apartment || "",
      pickup_station_additional_info: initial?.pickup_station_additional_info || "",
      pickup_station_postal_code: initial?.pickup_station_postal_code || "",
      pickup_station_contact_name: initial?.pickup_station_contact_name || "",
      pickup_station_contact_phone: initial?.pickup_station_contact_phone || "",
      pickup_station_contact_phone_prefix: initial?.pickup_station_contact_phone_prefix || "",
    },
    validateOnMount: false,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let extrCont;
      if (typeof values.country == "string") {
        extrCont = countries?.find((x) => x?._id == values?.country);
      } else {
        extrCont = countries?.find((x) => x?._id == values?.country?.id);
      }
      let country = extrCont?._id;
      let country_name = extrCont?.name;
      let country_code = extrCont?.country_code;
      let extrState;
      if (typeof values.state == "string") {
        extrState = extrCont.states?.find((x) => x?._id == values?.state);
      } else {
        extrState = extrCont.states?.find((x) => x?._id == values?.state?.id);
      }
      let state = extrState?._id;
      let state_name = extrState?.name;
      let state_code = extrState?.state_code;
      if (values.pickup_available) {
        let extrPickupCont;
        if (typeof values.pickup_station_country == "string") {
          extrPickupCont = countries?.find((x) => x?._id == values?.pickup_station_country);
        } else {
          extrPickupCont = countries?.find((x) => x?._id == values?.pickup_station_country?.id);
        }
        let pickup_station_country = extrPickupCont?._id;
        let pickup_station_country_name = extrPickupCont?.name;
        let pickup_station_country_code = extrPickupCont?.country_short_code;
        let extrPickupState;
        if (typeof values.pickup_station_state == "string") {
          extrPickupState = extrPickupCont.states?.find((x) => x?._id == values?.pickup_station_state);
        } else {
          extrPickupState = extrPickupCont.states?.find((x) => x?._id == values?.pickup_station_state?.id);
        }
        let pickup_station_state = extrPickupState?._id;
        let pickup_station_state_name = extrPickupState?.name;
        let pickup_station_state_code = extrPickupState?.state;
        const resolveValues = {
          ...values,
          country,
          country_name,
          country_code,
          state_name,
          state_code,
          state,
          pickup_station_country,
          pickup_station_country_name,
          pickup_station_country_code,
          pickup_station_state_name,
          pickup_station_state_code,
          pickup_station_state,
        };
        handleEdit(resolveValues);
      } else {
        const resolveValues = {
          ...values,
          country,
          country_name,
          country_code,
          state_name,
          state_code,
          state,
        };
        handleEdit(resolveValues);
      }
    },
  });

  const handleEdit = async (values) => {
    let status;
    status = getValue(values?.status);

    if (!values?.pickup_available) {
      delete values?.pickup_station_state;
      delete values?.pickup_station_country;
    }

    const resolveValues = { ...values, status };
    const res = !initial ? await createMarket(resolveValues) : await updateMarket({ id: initial?.id || initial?._id, data: resolveValues });
    if (res?.data?.status) {
      handleClose();
      toast.success(res?.data?.message || !!initial ? "Updated successfully" : "Created successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };

  useEffect(() => {
    formik.setFieldValue(
      "pickup_available",
      !!formik.values?.pickup_station_name ||
        !!formik.values?.pickup_station_additional_info ||
        !!formik.values?.pickup_station_description ||
        !!formik.values?.pickup_station_apartment ||
        !!formik.values?.pickup_station_street_address ||
        !!formik.values?.pickup_station_country ||
        !!formik.values?.pickup_station_city ||
        !!formik.values?.pickup_station_state ||
        !!formik.values?.pickup_station_contact_name ||
        !!formik.values?.pickup_station_contact_phone ||
        !!formik.values?.pickup_station_postal_code,
    );
  }, [
    formik.values?.pickup_station_name,
    formik.values?.pickup_station_additional_info,
    formik.values?.pickup_station_description,
    formik.values?.pickup_station_apartment,
    formik.values?.pickup_station_street_address,
    formik.values?.pickup_station_country,
    formik.values?.pickup_station_city,
    formik.values?.pickup_station_state,
    formik.values?.pickup_station_contact_name,
    formik.values?.pickup_station_contact_phone,
    formik.values?.pickup_station_postal_code,
  ]);

  const countryOptions = useMemo(() => {
    let filtered = countries?.map((x) => {
      return { label: x?.name, value: x?._id, id: x?._id };
    });
    return filtered;
  }, [countries, isLoadingCountries]);

  const stateOptions = useMemo(() => {
    let extrCont;
    if (typeof formik?.values.country == "string") {
      extrCont = countries?.find((x) => x?._id == formik?.values.country);
    } else {
      extrCont = countries?.find((x) => x?._id == formik?.values?.country?.id);
    }
    let filtered;

    if (extrCont?.states && extrCont?.states?.length > 0) {
      filtered = extrCont?.states?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      });
    } else {
      filtered = countries
        ?.find((x) => x?._id == initial?.country?._id)
        ?.states?.map((x) => {
          return { label: x?.name, value: x?._id, id: x?._id };
        });
    }
    if (filtered?.length > 0 && (formik?.values?.state == "" || !formik.values?.state)) {
      formik.setFieldValue("state", null);
    }
    return filtered || [];
  }, [countries, formik?.values?.country, countryOptions, isLoadingCountries]);

  const pickupCountryOptions = useMemo(() => {
    let filtered = countries?.map((x) => {
      return { label: x?.name, value: x?._id, id: x?._id };
    });
    return filtered;
  }, [countries, isLoadingCountries]);

  const pickupStateOptions = useMemo(() => {
    let extrCont;
    if (typeof formik?.values.pickup_station_country == "string") {
      extrCont = countries?.find((x) => x?._id == formik?.values.pickup_station_country);
    } else {
      extrCont = countries?.find((x) => x?._id == formik?.values?.pickup_station_country?.id);
    }
    let filtered;

    if (extrCont?.states && extrCont?.states?.length > 0) {
      filtered = extrCont?.states?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      });
    } else {
      filtered = countries
        ?.find((x) => x?._id == initial?.pickup_station_country?._id)
        ?.states?.map((x) => {
          return { label: x?.name, value: x?._id, id: x?._id };
        });
    }
    if (filtered?.length > 0 && (formik?.values?.pickup_station_state == "" || !formik.values?.pickup_station_state)) {
      formik.setFieldValue("pickup_station_state", null);
    }
    return filtered || [];
  }, [countries, formik?.values?.pickup_station_country, countryOptions, isLoadingCountries]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={!initial ? "Create Market" : "Edit Market"} />
      </Modal.Header>
      <Modal.Body>
        {isLoadingCountries ? (
          <Loader2 />
        ) : (
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Name"}
                  id={"name"}
                  placeholder={"Name"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.name}
                  value={formik?.values?.name}
                />
                <CustomInput
                  as={"select"}
                  inputType={"select"}
                  selectOptions={countryOptions}
                  required={true}
                  label={"Country"}
                  id={"country"}
                  name={"country"}
                  placeholder={"Country"}
                  onChange={(e) => {
                    formik.setFieldValue("state", "");
                    formik.setFieldValue("country", e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.errors.country}
                  value={countryInit || formik.values.country}
                />
                <CustomInput
                  as={"select"}
                  inputType={"select"}
                  selectOptions={stateOptions}
                  required={true}
                  label={"State"}
                  id={"state"}
                  name={"state"}
                  placeholder={"State"}
                  onChange={(e) => {
                    formik.setFieldValue("state", e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.errors.state}
                  value={stateInit || formik.values.state}
                />

                <CustomInput
                  type={"number"}
                  required={true}
                  label={"Delivery fee"}
                  id={"delivery_fee"}
                  placeholder={"Delivery fee"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.delivery_fee}
                  value={formik?.values?.delivery_fee}
                />
                <CustomInput
                  type={"number"}
                  required={true}
                  label={"Minimum Order Value"}
                  id={"minimum_order_value"}
                  placeholder={"Minimum Order Value"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.minimum_order_value}
                  value={formik?.values?.minimum_order_value}
                />
                <CustomInput
                  type={"number"}
                  required={true}
                  label={"Shopper Commission(%)"}
                  id={"shopper_commission"}
                  placeholder={"Shopper Commission(%)"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.shopper_commission}
                  value={formik?.values?.shopper_commission}
                />
                <CustomInput
                  type={"number"}
                  required={true}
                  label={"Service Charge"}
                  id={"service_charge"}
                  placeholder={"Service Charge"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.service_charge}
                  value={formik?.values?.service_charge}
                />
                <CustomInput
                  inputType={"select"}
                  selectOptions={statusOptions}
                  required={true}
                  label={"Status"}
                  id={"status"}
                  name={"status"}
                  placeholder={"Status"}
                  onChange={(e) => {
                    formik.setFieldValue("status", e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.errors.status}
                  value={statusOptions?.find((x) => initial?.status == x?.value)}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Contact Name"}
                  id={"contact_name"}
                  placeholder={"Contact Name"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.contact_name}
                  value={formik?.values?.contact_name}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Contact Phone"}
                  id={"contact_phone"}
                  placeholder={"Contact Phone"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.contact_phone}
                  value={formik?.values?.contact_phone}
                />
                <Col style={{ marginTop: 30, marginBottom: 10 }}>
                  <CustomText
                    text="Pickup Station Information (Optional)"
                    fontFamily={"Poppins"}
                    fontSize={14}
                    fontWeight={700}
                    textClassName="text-capitalize text-truncate"
                  />
                </Col>

                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Station Name"}
                  id={"pickup_station_name"}
                  placeholder={"Pickup Station Name"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.pickup_station_name}
                  value={formik?.values?.pickup_station_name}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Station Description"}
                  id={"pickup_station_description"}
                  placeholder={"Pickup Station Description"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.pickup_station_description}
                  value={formik?.values?.pickup_station_description}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Street Address"}
                  id={"pickup_station_street_address"}
                  placeholder={"Pickup Street Address"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.pickup_station_street_address}
                  value={formik?.values?.pickup_station_street_address}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Apartment/Suite"}
                  id={"pickup_station_apartment"}
                  placeholder={"Pickup Apartment/Suite"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.pickup_station_apartment}
                  value={formik?.values?.pickup_station_apartment}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Additional Info"}
                  id={"pickup_station_additional_info"}
                  placeholder={"Pickup Additional Info"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.pickup_station_additional_info}
                  value={formik?.values?.pickup_station_additional_info}
                />
                <CustomInput
                  as={"select"}
                  inputType={"select"}
                  selectOptions={pickupCountryOptions}
                  required={true}
                  label={"Pickup Station Country"}
                  id={"pickup_station_country"}
                  name={"pickup_station_country"}
                  placeholder={"Pickup Station Country"}
                  onChange={(e) => {
                    formik.setFieldValue("pickup_station_state", "");
                    formik.setFieldValue("pickup_station_country", e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.errors.pickup_station_country}
                  value={pickupCountryInit || formik.values.pickup_station_country}
                />
                <CustomInput
                  as={"select"}
                  inputType={"select"}
                  selectOptions={pickupStateOptions}
                  required={true}
                  label={"Pickup Station State"}
                  id={"pickup_station_state"}
                  name={"pickup_station_state"}
                  placeholder={"Pickup Station State"}
                  onChange={(e) => {
                    formik.setFieldValue("pickup_station_state", e);
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.errors.pickup_station_state}
                  value={pickupStateInit || formik.values.pickup_station_state}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Station City"}
                  id={"pickup_station_city"}
                  placeholder={"Pickup Station City"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.pickup_station_city}
                  value={formik?.values?.pickup_station_city}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Station Postal Code"}
                  id={"pickup_station_postal_code"}
                  placeholder={"Pickup Station Postal Code"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.pickup_station_postal_code}
                  value={formik?.values?.pickup_station_postal_code}
                />
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Station Contact Name "}
                  id={"pickup_station_contact_name"}
                  placeholder={"Pickup Station Contact Name"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.pickup_station_contact_name}
                  value={formik?.values?.pickup_station_contact_name}
                />

                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Pickup Station Contact Phone"}
                  id={"pickup_station_contact_phone"}
                  placeholder={"Pickup Station Contact Phone"}
                  onChange={(e) => {
                    formik?.setFieldValue(
                      "pickup_station_contact_phone",
                      e.target?.value.charAt(0) == "0" ? e?.target?.value?.replace("0", "") : e?.target?.value,
                    );
                  }}
                  error={formik?.errors?.pickup_station_contact_phone || formik?.errors?.pickup_station_contact_phone_prefix}
                  value={formik?.values?.pickup_station_contact_phone}
                  leftComp={
                    <Form.Control
                      type={"text"}
                      required={true}
                      label={null}
                      id={"pickup_station_contact_phone_prefix"}
                      placeholder={"Prefix"}
                      onChange={formik?.handleChange}
                      error={formik?.errors?.pickup_station_contact_phone_prefix}
                      value={formik?.values?.pickup_station_contact_phone_prefix}
                      style={{
                        backgroundColor: "transparent",
                        borderColor: "#d9d9d9",
                        height: "40px",
                        maxWidth: "100px",
                      }}
                    ></Form.Control>
                  }
                />
              </Col>
            </Row>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={isLoading || !formik?.isValid}
          button2onClick={formik.handleSubmit}
          button2Text={!initial ? "Confirm" : "Update"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading}
          button1Text={"Cancel"}
          button1onClick={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
