export function objectToFormData(obj, formData = new FormData(), parentKey) {
  for (const key in obj) {
    if (obj[key] instanceof FileList) {
      for (let i = 0; i < obj[key].length; i++) {
        formData.append(`${key}[]`, obj[key][i]);
      }
    } else if (Array.isArray(obj[key]) && !isFileList(obj[key]) && key !== "images") {
      obj[key].forEach((item, index) => {
        const newKey = parentKey ? `${parentKey}[${index}][${key}]` : `${key}[${index}]`;
        if (item !== null && typeof item === "object") {
          objectToFormData(item, formData, newKey);
        } else {
          formData.append(newKey, item);
        }
      });
    } else if (obj[key] instanceof File) {
      formData.append(key, obj[key]);
    } else {
      const newKey = parentKey ? `${parentKey}[${key}]` : key;
      formData.append(newKey, obj[key]);
    }
  }

  return formData;
}

function isFileList(value) {
  return Object.prototype.toString.call(value) === "[object FileList]";
}

export function logFormData(formData) {
  for (let [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }
}
