// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";

import { TrashFill } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Form, Tab, Nav, Spinner, Image } from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";
// import Category from './../../componentsx/dashboard/cms/Category
// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import { AddNewUnit } from "./components/AddNewUnit";
import { EditUnit } from "./components/EditUnit";
import { AddNewCategory } from "./components/AddNewCategory";
import { EditCategory } from "./components/EditCategory";
import NoData from "components/Nodata";
import CustomButton from "components/CustomButton";
import { useSelector } from "react-redux";
import { useDeleteCategoryMutation, useGetCategoriesMutation } from "redux/features/user/userApi";
import { saveCategories } from "redux/features/auth/authSlice";
import moment from "moment";
import Loader from "components/Loader";
import { ConfirmDeleteModal } from "components/ConfirmDeleteModal";
import { TableHeader } from "components/TableHeader";
import { DownloadTableExcel } from "react-export-table-to-excel";

const Category = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [categoryDetails, setUnitDetails] = useState([]);
  const [newCategory, setNewCategory] = useState(false);
  const [editCategory, setEditType] = useState(false);
  const [selected, setSelected] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const handleCloseNewCategory = () => {
    setNewCategory(false);
    setRefresh(!refresh);
  };
  const handleCloseEditCategory = () => {
    setEditType(false);
    setRefresh(!refresh);
  };
  const handleShowNewCategory = () => {
    setNewCategory(true);
  };
  const handleShowEdit = (category) => {
    setEditType(true);
    setUnitDetails(category);
  };

  const { categories, user } = useSelector((state) => state.userAuth);

  const [getCategories, { isLoading }] = useGetCategoriesMutation();
  const [deleteCategory, { isLoading: isDeleting }] = useDeleteCategoryMutation();

  const fetchCategories = async () => {
    const res = await getCategories();
    if (res?.data?.status === "success") {
      dispatch(saveCategories(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDelete = async () => {
    const res = await deleteCategory(selected?._id);
    if (res?.data) {
      toast.success(res?.data?.message || "Deleted successfully");
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      // { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "image",
        Header: "Image",
        Cell: ({ value }) => {
          return <Image src={value} style={{ width: 130 }} />;
        },
      },
      {
        accessor: "action",
        Header: "Action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-around align-items-center">
              <CustomButton variant="primary" onClick={() => handleShowEdit(row?.original)}>
                Edit
              </CustomButton>

              {/* <TrashFill
                size={24}
                color="black"
                onClick={() => {
                  setSelected(row?.original);
                  setDeleteShow(true);
                }}
              /> */}
            </div>
          );
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = categories || [];
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });

    return filtered;
  }, [categories]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  if (isLoading || isDeleting) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <TableHeader
              title={"Category"}
              data={data}
              searchable={true}
              paginated={true}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              currentPageSize={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              actionButtons={true}
              actionButton1={
                <DownloadTableExcel filename="FD_Category" sheet="categories" currentTableRef={tableRef.current}>
                  <CustomButton variant="primary" type="button" text="Export" />
                </DownloadTableExcel>
              }
              actionButton2={
                <CustomButton
                  variant="primary"
                  // type="submit"
                  onClick={handleShowNewCategory}
                  text="Create New "
                  // loading={}
                />
              }
            />
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                  {data.length > 0 ? (
                    <>
                      <thead className="table-light text-center">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                {column.render("Header")}

                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownSvg style={{ height: 20 }} />
                                  ) : (
                                    <SortUpSvg style={{ height: 20 }} />
                                  )
                                ) : (
                                  ""
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className="text-center">
                              {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <NoData />
                  )}
                </Table>

                {data.length > 0 ? (
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddNewCategory showNew={newCategory} handleCloseAddNew={handleCloseNewCategory} />

      <EditCategory showEdit={editCategory} handleCloseEdit={handleCloseEditCategory} defaultValues={categoryDetails} />

      <ConfirmDeleteModal
        deleteShow={deleteShow}
        handleDelete={handleDelete}
        handleDeleteClose={() => setDeleteShow(false)}
        name={selected?.name + " Category"}
        text="Deleting this category will affect all products under this category, proceed with caution"
      />
    </Fragment>
  );
};

export default Category;
