import * as Yup from "yup";
import { FieldArray, useFormik } from "formik";
import { CustomText } from "components/CustomText";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useMemo, useState } from "react";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateProductMutation,
  useGetCategoriesMutation,
  useGetCountriesMutation,
  useGetMarketsMutation,
  useGetUomsMutation,
  useUpdateProductMutation,
} from "redux/features/user/userApi";
import { saveCategories, saveCountries, saveMarkets, saveUoms } from "redux/features/auth/authSlice";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loader from "components/Loader";
import { AddNewMarket } from "pages/ContentManagement/components/AddNewMarket";
import { AddNewUnit } from "pages/ContentManagement/components/AddNewUnit";

import { Cloudinary } from "@cloudinary/url-gen";
import { useHistory, useLocation } from "react-router-dom";
import { getValue } from "helper/formatObject";

import { MinusCircle } from "react-feather";

const AddProduct = () => {
  const history = useHistory();
  const location = useLocation();
  const initial = location?.state;
  const dispatch = useDispatch();
  const { uoms, countries, categories, markets } = useSelector((state) => state.userAuth);

  const [initialLoad, setInitialLoad] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [showNewUnit, setShowNewUnit] = useState(false);
  const [showNewMarket, setShowNewMarket] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);

  const [createProduct, { isLoading }] = useCreateProductMutation();
  const [updateProduct, { isLoading: isLoadingUpdateProduct }] = useUpdateProductMutation();
  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();
  const [getUoms, { isLoading: isLoadingUoms }] = useGetUomsMutation();
  const [getMarkets, { isLoading: isLoadingMarkets }] = useGetMarketsMutation();
  const [getCategories, { isLoading: isLoadingCategories }] = useGetCategoriesMutation();

  const fetchUoms = async () => {
    const res = await getUoms();
    if (res?.data?.status === "success") {
      dispatch(saveUoms(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchMarkets = async () => {
    const res = await getMarkets();
    if (res?.data?.status === "success") {
      dispatch(saveMarkets(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchCategories = async () => {
    const res = await getCategories();
    if (res?.data?.status === "success") {
      dispatch(saveCategories(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchCountries();
    fetchCategories();
    fetchUoms();
    fetchMarkets();
    setInitialLoad(false);
  }, [refresh]);

  const countryOptions = useMemo(() => {
    let filtered = countries || [];
    filtered = filtered?.filter((x) => x?.name?.toLowerCase()?.includes("nigeria"));
    filtered = filtered?.map((x) => {
      return { label: x?.name, value: x?._id };
    });
    return filtered;
  }, [countries]);

  const categoryOptions = useMemo(() => {
    let filtered = categories || [];
    filtered = filtered?.map((x) => {
      return { label: x?.name, value: x?._id };
    });
    return filtered;
  }, [categories]);

  const uomOptions = useMemo(() => {
    let filtered = uoms || [];
    filtered = filtered?.map((x) => {
      return { label: x?.name, value: x?._id };
    });
    return filtered;
  }, [uoms]);

  const marketOptions = useMemo(() => {
    let filtered = markets || [];
    filtered = filtered?.filter((x) => x?.country_name?.toLowerCase()?.includes("nigeria"));
    filtered = filtered?.map((x) => {
      return { label: x?.name, value: x?._id };
    });
    return filtered;
  }, [markets]);

  const availabilityOptions = useMemo(() => {
    return [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ];
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    category_id: Yup.mixed().required("Category ID is required"),
    country_id: Yup.mixed().required("Country ID is required"),
    images: Yup.array().required("Images are required"),
    imageCover: Yup.string().required("Image Cover is required"),
    available: Yup.mixed().required("Availability is required"),
    brand: Yup.string().label("Brand"),
    priceByMarket: Yup.array()
      .of(
        Yup.object().shape({
          cost_price: Yup.number().required("Cost Price is required"),
          kg: Yup.number().required("Weight is required"),
          price: Yup.number().required("Price is required"),
          market_id: Yup.mixed().required("Market is required"),
          uom_id: Yup.mixed().required("UOM is required"),
        }),
      )
      .required("Price is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: initial?.name || "",
      description: initial?.description || "",
      category_id: categoryOptions?.find((x) => x?.value == initial?.category_id?._id) || "",
      country_id:
        countryOptions?.find((x) => x?.value == initial?.country_id?._id) ||
        countryOptions?.find((x) => x?.label?.toLowerCase().includes("nigeria")) ||
        "",
      images: initial?.images || [],
      imageCover: initial?.imageCover || "",
      available:
        availabilityOptions?.find((x) => x?.value == initial?.available) || availabilityOptions?.find((x) => x?.value == "yes") || "",
      brand: initial?.brand || "",
      kg: initial?.kg || "",
      priceByMarket: initial?.priceByMarketId?.map((x) => {
        return {
          cost_price: x?.cost_price,
          price: x?.price,
          kg: x?.kg,
          market_id: marketOptions?.find((y) => y?.value == x?.market_id?._id),
          uom_id: uomOptions?.find((y) => y?.value == x?.uom_id?._id),
        };
      }) || [{ cost_price: "", price: "", market_id: "", uom_id: "", kg: "" }],
    },
    validationSchema: validationSchema,
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnChange,
    validateOnMount: false,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const resolveValues = {
        ...values,
        category_id: getValue(values?.category_id),
        country_id: getValue(values?.country_id),
        available: getValue(values?.available),
        priceByMarket: values?.priceByMarket?.map((item) => {
          return {
            cost_price: item?.cost_price,
            price: item?.price,
            market_id: getValue(item?.market_id),
            uom_id: getValue(item?.uom_id),
            kg: item?.kg,
          };
        }),
      };
      const res = !initial ? await createProduct(resolveValues) : await updateProduct({ id: initial?._id, data: resolveValues });
      if (!initial) {
        if (res?.data?.status) {
          toast.success(res?.data?.message || "Product created successfully");
        } else {
          toast.error(res?.error?.data?.message);
        }
      } else {
        if (res?.data?.status) {
          toast.success(res?.data?.message || "Product updated successfully");
          history.goBack();
        } else {
          toast.error(res?.error?.data?.message);
        }
      }
      setSubmitting(false);
    },
  });

  const removePriceByMarket = (index) => {
    const priceByMarket = [...formik.values.priceByMarket];
    priceByMarket.splice(index, 1);
    formik.setFieldValue("priceByMarket", priceByMarket);
  };

  const pushPriceByMarket = () => {
    const priceByMarket = [...formik.values.priceByMarket];
    priceByMarket.push({
      market_id: "",
      uom_id: "",
      price: "",
    });
    formik.setFieldValue("priceByMarket", priceByMarket);
  };

  const setFieldValueForMarketId = (index, value) => {
    const updatedPriceByMarket = [...formik.values.priceByMarket];
    updatedPriceByMarket[index] = {
      ...updatedPriceByMarket[index],
      market_id: value,
    };
    formik.setFieldValue("priceByMarket", updatedPriceByMarket);
  };

  const setFieldValueForUomId = (index, value) => {
    const updatedPriceByMarket = [...formik.values.priceByMarket];
    updatedPriceByMarket[index] = {
      ...updatedPriceByMarket[index],
      uom_id: value,
    };
    formik.setFieldValue("priceByMarket", updatedPriceByMarket);
  };

  const handleAddImageUrl = (url) => {
    formik.setFieldValue("images", formik?.values?.images?.length > 0 ? [...formik?.values?.images, ...url] : url);
  };

  const handleRemoveImageUrl = (index) => {
    const newImageUrls = [...formik?.values?.images];
    newImageUrls?.splice(index, 1);
    formik?.setFieldValue("images", newImageUrls);
  };

  const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_API_KEY, REACT_APP_CLOUDINARY_SECRET } = process.env;

  const cld = new Cloudinary({
    cloud: {
      cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
      apiKey: REACT_APP_CLOUDINARY_API_KEY,
      apiSecret: REACT_APP_CLOUDINARY_SECRET,
    },
  });

  const handleImageUpload = async (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "hlx4uinq");

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cld?.cloudinaryConfig?.cloud?.cloudName}/image/upload`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    formik.setFieldValue("imageCover", data.secure_url);
  };
  const handleImagesUpload = async (event) => {
    const formData = new FormData();
    const files = event.target.files;
    const urls = [];

    for (let i = 0; i < files?.length; i++) {
      formData.append("file", files[i]);
      formData.append("upload_preset", "hlx4uinq");

      const response = await fetch(`https://api.cloudinary.com/v1_1/${cld?.cloudinaryConfig?.cloud?.cloudName}/image/upload`, {
        method: "POST",
        body: formData,
      });

      const data = await response?.json();
      urls.push(data?.secure_url);
      formData.delete("file");
    }

    handleAddImageUrl(urls);
  };

  if ((isLoadingCategories || isLoadingMarkets || isLoadingUoms || isLoadingCountries) && initialLoad) {
    return <Loader />;
  }

  return (
    <>
      <Form>
        <Row>
          <Card>
            <Row id="comptitle">
              <Col className=" p-3 d-flex align-items-center justify-content-between">
                <CustomText
                  fontFamily={"Montserrat"}
                  fontSize={16}
                  fontWeight={600}
                  text={(!initial ? "Add" : "Update") + " Local Product"}
                  cNColor="primary"
                />
              </Col>
            </Row>

            <Row className="p-3">
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Name"}
                  id={"name"}
                  placeholder={"Name"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.name}
                  value={formik?.values?.name}
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  inputType={"select"}
                  selectOptions={categoryOptions}
                  required={true}
                  label={"Category"}
                  id={"category_id"}
                  placeholder={"Category"}
                  onChange={(e) => formik.setFieldValue("category_id", e)}
                  error={formik?.errors?.category_id}
                  value={formik?.values?.category_id}
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Description"}
                  id={"description"}
                  placeholder={"Description"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.description}
                  value={formik?.values?.description}
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  label={"Brand"}
                  id={"brand"}
                  placeholder={"Brand"}
                  onChange={formik?.handleChange}
                  error={formik?.errors?.brand}
                  value={formik?.values?.brand}
                />
              </Col>

              <Col lg={12} md={12} style={{ display: "flex", flexWrap: "wrap" }}>
                {formik?.values?.imageCover && <ProductCardImage src={formik?.values?.imageCover} alt="Product" width={"200px"} />}
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"file"}
                  required={!initial?.imageCover}
                  label={"Cover Image"}
                  id="imageCover"
                  accept="image/*"
                  onChange={(event) => {
                    handleImageUpload(event);
                  }}
                  error={!initial?.imageCover && formik?.errors?.imageCover}
                />
              </Col>
              <Col lg={12} md={12} style={{ display: "flex", flexWrap: "wrap" }}>
                {formik?.values?.images?.map((image, index) => (
                  <Col>
                    <div className="position-absolute" onClick={() => handleRemoveImageUrl(index)}>
                      <MinusCircle size={20} color="black" style={{ top: 0, right: -10, cursor: "pointer", width: 30, height: 30 }} />
                    </div>
                    <ProductCardImage src={image} key={index} alt="Product" width={"200px"} />
                  </Col>
                ))}
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"file"}
                  label={"Images"}
                  id="images"
                  required={!initial?.images}
                  accept="image/*"
                  multiple={true}
                  onChange={(event) => {
                    handleImagesUpload(event);
                  }}
                  error={!initial?.images && formik?.errors?.images}
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  inputType={"select"}
                  selectOptions={availabilityOptions}
                  required={true}
                  label={"Availability"}
                  id={"available"}
                  placeholder={"Availability"}
                  onChange={(e) => formik.setFieldValue("available", e)}
                  error={formik?.errors?.available}
                  value={formik?.values?.available}
                />
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  inputType={"select"}
                  selectOptions={countryOptions}
                  required={true}
                  label={"Country"}
                  id={"country_id"}
                  placeholder={"Country"}
                  onChange={(e) => formik.setFieldValue("country_id", e)}
                  error={formik?.errors?.country_id}
                  value={formik?.values?.country_id}
                />
              </Col>

              {formik?.values?.priceByMarket?.map((priceByMarket, index) => {
                const marketFieldName = `priceByMarket[${index}].market_id`;
                const uomFieldName = `priceByMarket[${index}].uom_id`;
                const priceFieldName = `priceByMarket[${index}].price`;
                const costPriceFieldName = `priceByMarket[${index}].cost_price`;
                const kgFieldName = `priceByMarket[${index}].kg`;
                return (
                  <Row key={`${index}`} style={{ justifyContent: "center", alignItems: "flex-start" }}>
                    <Col>
                      <CustomInput
                        type="number"
                        required={true}
                        label={"Cost Price(₦-NGN)"}
                        id={costPriceFieldName}
                        name={costPriceFieldName}
                        placeholder={"Cost Price"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.priceByMarket?.[index]?.cost_price}
                        value={formik.values.priceByMarket?.[index]?.cost_price}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        type="number"
                        required={true}
                        label={"Price(₦-Naira)"}
                        id={priceFieldName}
                        name={priceFieldName}
                        placeholder={"Price"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.priceByMarket?.[index]?.price}
                        value={formik.values.priceByMarket?.[index]?.price}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        as={"select"}
                        inputType={"select"}
                        selectOptions={marketOptions}
                        required={true}
                        label={"Market"}
                        id={marketFieldName}
                        name={marketFieldName}
                        placeholder={"Market"}
                        onChange={(e) => setFieldValueForMarketId(index, e)}
                        onBlur={formik.handleBlur}
                        error={formik.errors.priceByMarket?.[index]?.market_id}
                        value={formik.values.priceByMarket?.[index]?.market_id}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        as={"select"}
                        inputType={"select"}
                        selectOptions={uomOptions}
                        required={true}
                        label={"UOM"}
                        id={uomFieldName}
                        name={uomFieldName}
                        placeholder={"Unit"}
                        onChange={(e) => setFieldValueForUomId(index, e)}
                        onBlur={formik.handleBlur}
                        error={formik.errors.priceByMarket?.[index]?.uom_id}
                        value={formik.values.priceByMarket?.[index]?.uom_id}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        type="number"
                        required={true}
                        label={"Weight in kg"}
                        id={kgFieldName}
                        name={kgFieldName}
                        placeholder={"Weight in kg"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.priceByMarket?.[index]?.kg}
                        value={formik.values.priceByMarket?.[index]?.kg}
                      />
                    </Col>
                    <Col
                      xs="auto"
                      style={{
                        marginBottom: 10,
                        justifyContent: "center",
                        alignSelf: "flex-end",
                      }}
                    >
                      <CustomButton variant="danger" onClick={() => removePriceByMarket(index)}>
                        -
                      </CustomButton>
                    </Col>
                  </Row>
                );
              })}

              <Row>
                <Col lg={12} md={12} className="mb-1">
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <CustomButton
                        variant="primary"
                        text="Add New Price-Unit"
                        onClick={() => pushPriceByMarket({ market_id: "", uom_id: "", price: "" })}
                      />
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <CustomButton variant="primary" text="Add New Market" onClick={() => setShowNewMarket(true)} />
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <CustomButton variant="primary" text="Add New Unit" onClick={() => setShowNewUnit(true)} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row></Row>
              <CustomButton
                className={"mt-4"}
                variant="primary"
                disabled={!formik?.isValid}
                text="Submit"
                loading={isLoading || isLoadingUpdateProduct}
                onClick={(e) => {
                  e.preventDefault();
                  setValidateOnChange(true);
                  formik.handleSubmit();
                }}
              />
            </Row>
          </Card>
        </Row>
      </Form>
      <AddNewMarket
        showNew={showNewMarket}
        handleCloseAddNew={() => {
          setShowNewMarket(false);
          setRefresh(!refresh);
        }}
      />
      <AddNewUnit
        showNew={showNewUnit}
        handleCloseAddNew={() => {
          setShowNewUnit(false);
          setRefresh(!refresh);
        }}
      />
    </>
  );
};

export default AddProduct;
