import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { isValidUrl } from "helper/formatText";
import { useCreateCategoryMutation, useUpdateCategoryMutation } from "redux/features/user/userApi";
import { objectToFormData } from "helper/objectToFormData";
import { toast } from "react-toastify";

export const EditCategory = ({ showEdit, handleCloseEdit, defaultValues }) => {
  const [updateCategory, { isLoading }] = useUpdateCategoryMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").typeError("Must be text"),
    image: Yup.mixed().required("Image is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: defaultValues?.name || "",
      image: defaultValues?.image || "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleEdit(values);
    },
  });

  const handleEdit = async (values) => {
    const resolveValues = objectToFormData(values);
    const res = await updateCategory({ id: defaultValues?._id, data: resolveValues });
    if (res?.data?.status) {
      handleCloseEdit();
      toast.success(res?.data?.message || "Updated successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };

  return (
    <Modal show={showEdit} onHide={handleCloseEdit}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Edit Category"} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Col lg={12} md={12} className="mb-1">
            <CustomInput
              type={"text"}
              required={true}
              label={"Category Name"}
              id={"name"}
              placeholder={"Category Name"}
              onChange={formik?.handleChange}
              error={formik?.errors?.name}
              value={formik?.values?.name}
            />
          </Col>
          <Col lg={12} md={12} style={{ display: "flex", flexWrap: "wrap" }}>
            {formik?.values?.image && (
              <ProductCardImage
                src={isValidUrl(formik?.values?.image) ? formik?.values?.image : URL.createObjectURL(formik?.values?.image)}
                alt="Product"
                width={"200px"}
              />
            )}
          </Col>
          <Col lg={12} md={12} className="mb-1">
            <CustomInput
              type={"file"}
              label={"Image"}
              id="image"
              accept="image/*"
              onChange={(event) => {
                formik.setFieldValue("image", event.currentTarget.files[0]);
              }}
            />
          </Col>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button1Variant={"primary"}
          button1Type={"submit"}
          button2Loading={isLoading}
          button1onClick={formik.handleSubmit}
          button1disabled={!formik?.isValid || isLoading}
          button1Text={"Confirm"}
          button2Variant={"secondary"}
          button2Type={"cancel"}
          button2disabled={isLoading}
          button2Text={"Cancel"}
          button2onClick={handleCloseEdit}
        />
      </Modal.Footer>
    </Modal>
  );
};
