import React from "react";
import { Row, Spinner } from "react-bootstrap";

const Loader2 = () => {
  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Spinner style={{ color: "#e0bf00" }} />
    </Row>
  );
};

export default Loader2;
