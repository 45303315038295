import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import { CustomText } from "components/CustomText";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import tomatopng from "assets/images/png/tomato.png";
import { getValue } from "helper/formatObject";
export const ChangeOrderStatus = ({ initial, show, close = () => {}, onConfirm = () => {}, isLoading }) => {
  const statusOptions = [
    { label: "Open", value: "open" },
    { label: "Accepted", value: "accepted" },
    { label: "Shopped", value: "shopped" },
    { label: "In Transit", value: "intransit" },
    { label: "Delivered", value: "delivered" },
    { label: "Cancelled", value: "cancelled" },
  ];
  const [status, setStatus] = useState(statusOptions.find((x) => x.value == initial?.order_status));

  useEffect(() => {
    setStatus(statusOptions.find((x) => x.value == initial?.order_status));
  }, [initial, show]);

  const filteredOptions = useMemo(() => {
    let filtered = statusOptions || [];
    filtered = filtered.filter((x) => x?.value !== "open" && x?.value !== "accepted");
    if (initial?.country?.name?.toLowerCase() == "nigeria") {
      filtered = filtered.filter((x) => x?.value !== "shopped");
    }
    if (initial?.order_status == "intransit") {
      filtered = filtered.filter((x) => x?.value !== "shopped");
    }
    if (initial?.order_status == "delivered" || initial?.order_status == "cancelled") {
      filtered = filtered.filter((x) => x?.value !== "shopped" && x?.value !== "intransit");
    }
    filtered = filtered.filter((x) => x?.value !== initial?.order_status);
    return filtered;
  }, [initial, statusOptions]);

  return (
    <Modal show={show} onHide={close} size={"sm"}>
      <Card>
        <Modal.Header>
          <Modal.Title>
            <CustomText fontFamily={"Poppins"} fontSize={16} fontWeight={600} text={"Change Status"} cNColor="primary" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomInput
            label="Order Status"
            inputType="select"
            selectOptions={filteredOptions}
            onChange={(e) => setStatus(e)}
            value={status}
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <CustomButton
            variant="secondary"
            type="submit"
            //   disabled={!formik?.isValid}
            text="Cancel"
            onClick={close}
            // loading={}
          />

          <CustomButton
            variant="primary"
            type="submit"
            text="Confirm"
            onClick={() => {
              onConfirm(getValue(status));
            }}
            disabled={!filteredOptions?.find((x) => x.value == getValue(status))}
            loading={isLoading}
          />
        </Modal.Footer>
      </Card>
    </Modal>
  );
};
