import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useMemo } from "react";
import { saveAreas, saveCountries, saveMarkets } from "redux/features/auth/authSlice";
import {
  useCreateVendorMutation,
  useGetAreasMutation,
  useGetCountriesMutation,
  useGetMarketsMutation,
  useUpdateShopperMutation,
  useUpdateVendorMutation,
  useVerifyAccountNumberMutation,
} from "redux/features/user/userApi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Cloudinary } from "@cloudinary/url-gen";
import { CustomText } from "components/CustomText";
import { getValue, removeKeysWithValues } from "helper/formatObject";
import { toast } from "react-toastify";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import { isSubstringInArray, sortByProperty } from "helper/formatArray";
const yourhandle = require("countrycitystatejson");

export const EditShopper = ({ showAdd, handleCloseAdd, type = "edit", initialValues }) => {
  const [statusOptions] = useState([
    { id: "1", label: "Active", value: "active" },
    { id: "2", label: "Inactive", value: "inactive" },
  ]);
  const [genderOptions] = useState([
    { id: "1", label: "Male", value: "male" },
    { id: "2", label: "Female", value: "female" },
  ]);
  const [accountVerifiedOptions] = useState([
    { id: "1", label: "Verified", value: true },
    { id: "2", label: "Unverified", value: false },
  ]);
  const dispatch = useDispatch();
  const { markets, countries, areas } = useSelector((state) => state.userAuth);

  const [imageLoading, setImageLoading] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState(
    countries
      ?.find((x) => isSubstringInArray("nigeria", [x?.name]))
      ?.states?.map((y) => {
        return { label: y?.name, value: y?.name, id: y?._id || y?.id };
      }),
  );
  const [marketOptions, setMarketOptions] = useState(
    markets?.map((market) => {
      return { label: `${market?.name} (${market?.state_name} - ${market?.country_name})`, value: market._id, id: market?.market_code };
    }),
  );

  const [getMarkets, { isLoading: isLoadingMarkets }] = useGetMarketsMutation();
  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();
  const [getAreas, { isLoading: isLoadingAreas }] = useGetAreasMutation();

  const fetchAreas = async () => {
    const res = await getAreas();
    if (res?.data?.status === "success") {
      dispatch(saveAreas(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };
  const fetchMarkets = async () => {
    const res = await getMarkets();
    if (res?.data?.status === "success") {
      dispatch(saveMarkets(res?.data?.data));
      setMarketOptions(
        res?.data?.data
          ?.filter((x) => x?.country_name?.toLowerCase()?.includes("nigeria"))
          ?.filter((x) => !x?.name?.toLowerCase()?.includes("default"))
          ?.map((market) => {
            return {
              label: `${market?.name} (${market?.state_name} - ${market?.country_name})`,
              value: market._id,
              id: market?.market_code,
            };
          }),
      );
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };
  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
      setStateOptions(
        res?.data?.data
          ?.find((x) => isSubstringInArray("nigeria", [x?.name]))
          ?.states?.map((state) => {
            return { label: state?.name, value: state?.name, id: state?.name };
          }),
      );
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchAreas();
    fetchMarkets();
    fetchCountries();
  }, []);

  const [updateShopper, { isLoading: isUpdating }] = useUpdateShopperMutation();

  const handleEdit = async (values) => {
    const resolveValues = values;
    const res = await updateShopper({ id: initialValues?.id, data: resolveValues });
    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Shopper updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseAdd();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    identity_card: Yup.string().required("Required"),
    phoneNumber: Yup.number().required("Required"),
    city: Yup.mixed().required("Required"),
    state: Yup.mixed().required("Required"),
    street_address: Yup.string().required("Required"),
    apartment: Yup.string(),
    gender: Yup.mixed(),
    status: Yup.mixed().required("Required"),
    market_id: Yup.mixed().required("Required"),
    account_verified: Yup.mixed().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: initialValues.firstName || "",
      lastName: initialValues.lastName || "",
      phoneNumber: initialValues.phoneNumber || "",
      city: initialValues.city || "",
      street_address: initialValues.street_address || "",
      apartment: initialValues.apartment || "",
      identity_card: initialValues.identity_card || "",
      gender: genderOptions?.find((x) => x?.value?.toLowerCase() == initialValues?.gender?.toLowerCase()) || "",
      status: statusOptions?.find((x) => x?.value == initialValues.status) || statusOptions[0],
      market_id: marketOptions?.find((x) => x?.value == initialValues.market_id?._id) || "",
      state: stateOptions?.find((x) => x?.value == initialValues.state) || "",
      city: initialValues.city || "",
      account_verified: accountVerifiedOptions?.find((x) => x?.value == initialValues.account_verified) || accountVerifiedOptions[0],
    },
    enableReinitialize: true,
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const resolveValues = removeKeysWithValues(
        {
          ...values,
          state: getValue(values?.state),
          market_id: getValue(values?.market_id),
          status: getValue(values?.status),
          gender: getValue(values?.gender),
          account_verified: getValue(values?.account_verified),
        },
        [null, ""],
      );
      await handleEdit(resolveValues);
    },
  });

  useEffect(() => {
    showAdd && formik.setFieldValue("identity_card", initialValues?.identity_card);
    showAdd &&
      formik.setFieldValue(
        "state",
        stateOptions?.find((x) => x?.value == initialValues?.state),
      );
  }, [initialValues, showAdd, stateOptions]);

  useEffect(() => {
    const cities = sortByProperty(
      yourhandle.getCities("NG", initialValues?.state?.replace(" State", ""))?.map((x) => {
        return {
          label: x,
          value: x,
          id: x,
        };
      }),
      "label",
    );

    setCityOptions(cities);
    // showAdd &&
    //   formik.values?.state &&
    //   cities?.length > 0 &&
    //   formik.setFieldValue("city", cities?.find((x) => x?.value == initialValues.city) || cities[0]);
  }, [initialValues?.state, showAdd]);

  const handleCityChange = async (state) => {
    const cities = sortByProperty(
      yourhandle.getCities("NG", getValue(state)?.replace(" State", ""))?.map((x) => {
        return {
          label: x,
          value: x,
          id: x,
        };
      }),
      "label",
    );

    setCityOptions(cities);
    // await formik.setFieldValue("city", cities?.find((x) => x?.value == initialValues.city) || cities[0]);
  };

  const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_API_KEY, REACT_APP_CLOUDINARY_SECRET } = process.env;

  const cld = new Cloudinary({
    cloud: {
      cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
      apiKey: REACT_APP_CLOUDINARY_API_KEY,
      apiSecret: REACT_APP_CLOUDINARY_SECRET,
    },
  });

  const handleImageUpload = async (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("upload_preset", "fooddy_shopper_accounts");
    setImageLoading(true);
    const response = await fetch(`https://api.cloudinary.com/v1_1/${cld?.cloudinaryConfig?.cloud?.cloudName}/image/upload`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    formik.setFieldValue("identity_card", data.secure_url);
    setImageLoading(false);
  };

  const handleMarketNumber = async (e) => {
    await formik.setFieldValue("market_id", e?.value);
  };

  return (
    <Modal show={showAdd} onHide={handleCloseAdd} size="lg">
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Edit Vendor"} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomText fontFamily={"Montserrat"} fontSize={16} fontWeight={600} mb={2} text="Identity information" />
              <Col lg={12} md={12} style={{ display: "flex", flexWrap: "wrap" }}>
                {formik?.values?.identity_card && <ProductCardImage src={formik?.values?.identity_card} alt="Product" width={"200px"} />}
              </Col>
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"file"}
                  required={true}
                  label={"Identity Card"}
                  id="identity_card"
                  accept="image/*"
                  onChange={(event) => {
                    handleImageUpload(event);
                  }}
                  error={formik?.errors?.identity_card}
                />
              </Col>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomText fontFamily={"Montserrat"} fontSize={16} mb={2} fontWeight={600} text="Market information" />

              <CustomInput
                inputType={"select"}
                selectOptions={marketOptions}
                required={true}
                returnObj={true}
                label={"Market of Operation"}
                id={"market_id"}
                placeholder={"Market of Operation"}
                onChange={(e) => {
                  handleMarketNumber(e);
                }}
                error={formik?.errors?.market_id}
                value={formik?.values?.market_id}
              />

              <CustomInput
                inputType={"select"}
                selectOptions={accountVerifiedOptions}
                required={true}
                label={"Verification status"}
                id={"account_verified"}
                placeholder={"Is Account Details Verified"}
                onChange={(e) => {
                  formik.setFieldValue("account_verified", e);
                }}
                error={formik?.errors?.account_verified}
                value={formik?.values?.account_verified}
              />
              <CustomInput
                inputType={"select"}
                selectOptions={statusOptions}
                required={true}
                label={"Status"}
                id={"status"}
                placeholder={"Status"}
                onChange={(e) => {
                  formik.setFieldValue("status", e);
                }}
                error={formik?.errors?.status}
                value={formik?.values?.status}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <CustomText fontFamily={"Montserrat"} fontSize={16} mb={2} fontWeight={600} text="Personal information" />

            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"First Name"}
                id={"firstName"}
                placeholder={"First name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.firstName}
                value={formik?.values?.firstName}
              />

              <CustomInput
                type={"text"}
                required={true}
                label={"Last Name"}
                id={"lastName"}
                placeholder={"Last name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.lastName}
                value={formik?.values?.lastName}
              />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Phone Number"}
                id={"phoneNumber"}
                placeholder={"Phone Number"}
                onChange={formik?.handleChange}
                error={formik?.errors?.phoneNumber}
                value={formik?.values?.phoneNumber}
              />
              <CustomInput
                inputType={"select"}
                selectOptions={genderOptions}
                required={true}
                label={"Gender"}
                id={"gender"}
                placeholder={"Gender"}
                onChange={(e) => {
                  formik.setFieldValue("gender", e);
                }}
                error={formik?.errors?.gender}
                value={formik?.values?.gender}
              />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Street address"}
                id={"street_address"}
                placeholder={"Street address"}
                onChange={formik?.handleChange}
                error={formik?.errors?.street_address}
                value={formik?.values?.street_address}
              />
              <CustomInput
                type={"text"}
                required={true}
                label={"Apartment"}
                id={"apartment"}
                placeholder={"Apartment"}
                onChange={formik?.handleChange}
                error={formik?.errors?.apartment}
                value={formik?.values?.apartment}
              />
            </Col>
            <Col sm={12} md={12} lg={6} xl={6} className="mb-1">
              <CustomInput
                inputType={"select"}
                selectOptions={stateOptions}
                required={true}
                returnObj={true}
                label={"State"}
                id={"state"}
                placeholder={"State"}
                onChange={(e) => {
                  formik.setFieldValue("state", e);
                  // handleCityChange(e);
                }}
                error={formik?.errors?.state}
                value={formik?.values?.state}
              />
              {/* {cityOptions?.length > 0 ? (
                <CustomInput
                  inputType={"select"}
                  selectOptions={cityOptions}
                  required={true}
                  returnObj={true}
                  label={"City"}
                  id={"city"}
                  placeholder={"City"}
                  onChange={(e) => {
                    formik.setFieldValue("city", e);
                  }}
                  error={formik?.errors?.city}
                  value={formik?.values?.city}
                />
              ) : (
                <></>
              )} */}
              <CustomInput
                type={"text"}
                required={true}
                label={"City"}
                id={"city"}
                placeholder={"City"}
                onChange={formik?.handleChange}
                error={formik?.errors?.city}
                value={formik?.values?.city}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isUpdating}
          button2onClick={formik.handleSubmit}
          button2disabled={!formik?.isValid || isUpdating || imageLoading}
          button2Text={"Update"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isUpdating}
          button1Text={"Cancel"}
          button1onClick={handleCloseAdd}
        />
      </Modal.Footer>
    </Modal>
  );
};
