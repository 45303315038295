export const flattenedArray = (originalArray) => {
  return originalArray.reduce((acc, innerArray) => {
    return [...acc, ...innerArray];
  }, []);
};

export function removeDeletedObjs(arr) {
  return arr.filter((obj) => obj.deleted_at === null) || [];
}

export function getPropertyArray(array, property) {
  return array.map(function (obj) {
    return obj[property];
  });
}

export function isSubstringInArray(substring, stringArray) {
  if (!substring || !stringArray || !Array.isArray(stringArray)) {
    return false;
  }
  const upperCaseSubstring = substring?.toUpperCase();
  for (let i = 0; i < stringArray.length; i++) {
    const upperCaseString = stringArray[i]?.toUpperCase();
    if (upperCaseString.includes(upperCaseSubstring) || upperCaseSubstring.includes(upperCaseString)) {
      return true;
    }
  }
  return false;
}

export function arrangedMasterClassVideos(array) {
  return [...array]?.sort((a, b) => {
    const numA = parseInt(a.title.match(/\d+$/)[0]);
    const numB = parseInt(b.title.match(/\d+$/)[0]);
    return numA - numB;
  });
}

export function sortWeeksArray(array) {
  return [...array].sort((a, b) => {
    const weekA = parseInt(a.match(/(\d+)/)[1]);
    const weekB = parseInt(b.match(/(\d+)/)[1]);
    return weekA > weekB || weekA - weekB || a.localeCompare(b);
  });
}

export function sortAscending(arr) {
  return [...arr]?.sort((a, b) => a.localeCompare(b));
}

export const sortedDataByUpdatedAt = (data) =>
  [...data]?.sort((a, b) => {
    return new Date(b?.updatedAt) - new Date(a?.updatedAt);
  });
export const sortedDataByCreatedAt = (data) =>
  [...data]?.sort((a, b) => {
    return new Date(b?.createdAt) - new Date(a?.createdAt);
  });

export function sortByProperty(array, property, order = "asc") {
  const sortOrder = order === "desc" ? -1 : 1;
  if (array.length == 0) {
    return array;
  } else {
    return [...array]?.sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];
      if (aValue < bValue) {
        return -1 * sortOrder;
      }
      if (aValue > bValue) {
        return 1 * sortOrder;
      }
      return 0;
    });
  }
}

export function generateNaturalNumbers(start, end) {
  let result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

export function updateObjects(arr, condition, propName, propValue) {
  let newArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (condition(arr[i])) {
      let newObj = { ...arr[i], [propName]: propValue };
      newArr.push(newObj);
    } else {
      newArr.push(arr[i]);
    }
  }
  return newArr;
}

export function getAdjacentObject(array, currentPropValue, propName, direction) {
  const currentIndex = array.findIndex((obj) => obj[propName] === currentPropValue);
  const adjacentIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;
  return array[adjacentIndex] || null;
}

export function isLastObjectByProperty(arr, obj, prop) {
  const lastIndex = arr.length - 1;
  const lastElement = arr[lastIndex];
  return lastElement[prop] === obj[prop];
}

export function getFilenames(fileList) {
  let filenames = [];
  for (let i = 0; i < fileList.length; i++) {
    filenames.push(fileList[i].name);
  }
  return filenames;
}

export function extractPropsByInputArray(arr, inPropArr, inProp, outProp) {
  const extProps = [];
  for (const obj of arr) {
    if (inPropArr?.includes(obj[inProp])) {
      extProps?.push(obj[outProp]);
    }
  }
  return extProps;
}

export function areFileListsEqual(fileList1, fileList2) {
  if (fileList1?.length !== fileList2?.length) {
    return false;
  }

  for (let i = 0; i < fileList1?.length; i++) {
    const file1 = fileList1?.item(i);
    let found = false;
    for (let j = 0; j < fileList2?.length; j++) {
      const file2 = fileList2?.item(j);
      if (file1?.name === file2?.name && file1?.size === file2?.size) {
        found = true;
        break;
      }
    }
    if (!found) {
      return false;
    }
  }

  return true;
}

export function sortByTagName(array) {
  return [...array].sort((a, b) => {
    const tagA = a?.tags[0]?.name?.toUpperCase();
    const tagB = b?.tags[0]?.name?.toUpperCase();

    if (tagA < tagB) {
      return -1;
    }
    if (tagA > tagB) {
      return 1;
    }

    return 0;
  });

  return array;
}

export function calculateAveragePrice(data, key, dp = 2) {
  let total_price = 0;
  const num_items = data.length;

  for (let i = 0; i < num_items; i++) {
    total_price += data[i][key];
  }

  const avg_price = total_price / num_items;

  return avg_price?.toFixed(dp);
}

export function getCreatedThisMonth(data) {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  return data?.filter((x) => new Date(x.createdAt) >= startOfMonth && x.role === "customer").length || 0;
}

export function extractField(array, field, value) {
  return array.filter((obj) => obj[field] === value);
}

export function findLowestFreightRate(rates, divisor = 100) {
  let lowestTotal = rates[0].total.value / divisor;
  let lowestService = rates[0];
  for (const item of rates) {
    const totalValue = parseFloat(item.total.value) / divisor;
    if (totalValue < lowestTotal) {
      lowestTotal = totalValue;
      lowestService = item;
    }
  }

  return { lowestTotal, lowestService };
}

export function isNotNullUndefinedEmpty(property) {
  return (
    property !== null &&
    property !== undefined &&
    !(typeof property === "string" && property.trim() === "") &&
    !(Array.isArray(property) && property.length === 0)
  );
}

export function getValueAsString(value) {
  if (value !== null && value !== undefined) {
    return String(value);
  } else {
    return null;
  }
}
