import { Container } from "react-bootstrap";

const ProductCardImage = (props) => {
  return (
    <Container className="img" style={{ width: props?.width, height: props?.height }}>
      <img src={props?.src} alt="Card-img" style={{ width: "100%", height: "100%", ...props?.imgStyle }}></img>
    </Container>
  );
};

export default ProductCardImage;
