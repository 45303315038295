import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { useCreateAdminMutation, useUpdateAdminMutation } from "redux/features/auth/authApi";
import { toast } from "react-toastify";

export const AddNewAdmin = ({ showAdd, handleCloseAdd, type = "add", initialValues }) => {
  const [statusOptions] = useState([
    { id: "1", label: "Active", value: "active" },
    { id: "2", label: "Inactive", value: "inactive" },
  ]);
  const [roleOptions] = useState([
    { id: "1", label: "Super Admin", value: "super admin" },
    { id: "2", label: "Local Admin", value: "local admin" },
    { id: "3", label: "Local Store Admin", value: "local store admin" },
    { id: "4", label: "Global Admin", value: "global admin" },
    { id: "5", label: "Global Store Admin", value: "global store admin" },
    { id: "6", label: "Shopper Admin", value: "shopper admin" },
    { id: "7", label: "Finance Admin", value: "finance admin" },
    { id: "8", label: "Market Admin", value: "market admin" },
  ]);

  const [createAdmin, { isLoading }] = useCreateAdminMutation();
  const [updateAdmin, { isLoading: isUpdating }] = useUpdateAdminMutation();

  const handleCreate = async (values) => {
    const res = await createAdmin(values);

    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Admin created successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseAdd();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleEdit = async (values) => {
    const resolveValues = { ...values, status: typeof values.status === "string" ? values.status : values.status?.value };
    delete resolveValues.password;
    delete resolveValues.passwordConfirm;
    const res = await updateAdmin({ id: initialValues?.id, data: resolveValues });

    if (res?.data?.status === "success") {
      toast.success(res?.data?.message || "Admin updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleCloseAdd();
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    role: Yup.string().required("Required"),
    status: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
    passwordConfirm: Yup.string().required("Required"),
  });
  const validationSchema1 = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    role: Yup.string().required("Required"),
    status: Yup.mixed().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: initialValues.firstName || "",
      lastName: initialValues.lastName || "",
      email: initialValues.email || "",
      role: initialValues.role || "",
      status: statusOptions?.find((x) => x?.value == initialValues.status) || "",
      password: "",
      passwordConfirm: "",
    },
    enableReinitialize: true,
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema: type == "add" ? validationSchema : validationSchema1,
    onSubmit: (values) => {
      type === "add" ? handleCreate(values) : handleEdit(values);
    },
  });

  return (
    <Modal show={showAdd} onHide={handleCloseAdd}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={type == "add" ? "Add new Admin" : "Edit Admin"} />
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"First Name"}
                id={"firstName"}
                placeholder={"First name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.email}
                value={formik?.values?.firstName}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Last Name"}
                id={"lastName"}
                placeholder={"Last name"}
                onChange={formik?.handleChange}
                error={formik?.errors?.email}
                value={formik?.values?.lastName}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"email"}
                required={true}
                id={"email"}
                label={"Email address"}
                placeholder={"Email address"}
                onChange={formik?.handleChange}
                error={formik?.errors?.email}
                value={formik?.values?.email}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                inputType="select"
                required={true}
                id="role"
                label="Role"
                placeholder="Select a role"
                onChange={(e) => formik?.setFieldValue("role", e)}
                error={formik?.errors?.role}
                value={roleOptions?.find((x) => initialValues?.role == x?.value)}
                selectOptions={roleOptions}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                inputType="select"
                required={true}
                id="status"
                label="Status"
                placeholder="Select a status"
                onChange={(e) => formik?.setFieldValue("status", e)}
                error={formik?.errors?.status}
                value={formik?.values?.status || statusOptions?.find((x) => initialValues?.status == x?.value)}
                selectOptions={statusOptions}
              />
            </Col>
            {type == "add" && (
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Password"}
                  id={"password"}
                  placeholder={"***************"}
                  onChange={formik?.handleChange}
                  value={formik?.values?.password}
                  isPassword
                />
              </Col>
            )}
            {type == "add" && (
              <Col lg={12} md={12} className="mb-1">
                <CustomInput
                  type={"text"}
                  required={true}
                  label={"Confirm password"}
                  id={"passwordConfirm"}
                  placeholder={"***************"}
                  onChange={formik?.handleChange}
                  value={formik?.values?.passwordConfirm}
                  isPassword
                />
              </Col>
            )}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading || isUpdating}
          button2onClick={formik.handleSubmit}
          button2disabled={!formik?.isValid || isLoading || isUpdating}
          button2Text={type == "add" ? "Create" : "Update"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading || isUpdating}
          button1Text={"Cancel"}
          button1onClick={handleCloseAdd}
        />
      </Modal.Footer>
    </Modal>
  );
};
