import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomModalHeading from "components/CustomModalHeading";
import CustomModalFooter from "components/CustomModalFooter";
import { toast } from "react-toastify";
import { useCreateAreaMutation, useCreateUomMutation, useGetCountriesMutation, useGetMarketsMutation } from "redux/features/user/userApi";
import { saveCountries, saveMarkets } from "redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

export const AddNewArea = ({ showNew, handleCloseAddNew }) => {
  const dispatch = useDispatch();
  const { markets, countries } = useSelector((state) => state.userAuth);
  const [refresh, setRefresh] = useState(false);

  const [getCountries, { isLoading: isLoadingCountries }] = useGetCountriesMutation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required").typeError("Must be text"),
    lga: Yup.string().required("Required").typeError("Must be text"),
    country: Yup.string().required("Required").typeError("Must be text"),
    state: Yup.string().required("Required").typeError("Must be text"),
    market: Yup.string().required("Required").typeError("Must be text"),
    status: Yup.boolean().required("Required").typeError("Must be text"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      lga: "",
      country: "",
      state: "",
      market: "",
      status: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      const extrMrkt = markets?.find((x) => x?.id == values?.market);
      const extrCont = countries?.find((x) => x?.id == values?.country);
      let market_name = extrMrkt?.name;
      let market_code = extrMrkt?.market_code;
      let country_name = extrCont?.name;
      let country_code = extrCont?.country_code;
      const extrState = extrCont.states?.find((x) => x?._id == values?.state);
      let state_name = extrState?.name;
      let state_code = extrState?.state_code;
      if (!market_name || !market_code || !country_name || !country_code || !state_name || !state_code) {
        toast.error("Form is invalid or incomplete", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      const resolveValues = { ...values, market_name, market_code, country_name, country_code, state_name, state_code };
      handleAddNew(resolveValues);
    },
  });

  const fetchCountries = async () => {
    const res = await getCountries();
    if (res?.data?.status === "success") {
      dispatch(saveCountries(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [createArea, { isLoading }] = useCreateAreaMutation();

  const handleAddNew = async (values) => {
    const resolveValues = values;
    const res = await createArea(resolveValues);
    if (res?.data?.status) {
      handleCloseAddNew();
      toast.success(res?.data?.message || "Created successfully");
    } else {
      toast.error(res?.error?.data?.message);
    }
  };

  const [getMarkets, { isLoading: isLoadingMarkets }] = useGetMarketsMutation();

  const fetchMarkets = async () => {
    const res = await getMarkets();
    if (res?.data?.status === "success") {
      dispatch(saveMarkets(res?.data?.data));
      setMarketOptions(
        res?.data?.data?.map((market) => {
          return { label: market?.name, value: market._id, id: market?._id };
        }),
      );
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchMarkets();
    fetchCountries();
  }, []);

  useEffect(() => {
    markets?.map((market) => {
      return { label: market?.name, value: market._id, id: market?._id };
    });
  }, [markets]);

  const [marketOptions, setMarketOptions] = useState(
    markets?.map((market) => {
      return { label: market?.name, value: market._id, id: market?._id };
    }),
  );

  const stateOptions = useMemo(() => {
    const extrCont = countries?.find((x) => x?.id == formik?.values?.country);
    let filtered;
    if (extrCont) {
      filtered = extrCont?.states?.map((x) => {
        return { label: x?.name, value: x?._id, id: x?._id };
      });
    }
    return filtered || [];
  }, [formik?.values?.country, countries]);

  const countryOptions = useMemo(() => {
    let filtered = countries?.map((x) => {
      return { label: x?.name, value: x?._id, id: x?._id };
    });
    return filtered;
  }, [countries]);

  return (
    <Modal show={showNew} onHide={handleCloseAddNew}>
      <Modal.Header id="comptitle">
        <CustomModalHeading title={"Create New Area"} />
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Area"}
                id={"name"}
                placeholder={"Area"}
                onChange={formik?.handleChange}
                error={formik?.errors?.name}
                value={formik?.values?.name}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                inputType={"select"}
                selectOptions={marketOptions}
                required={true}
                label={"Connected Market"}
                id={"market"}
                placeholder={"Connected Market"}
                onChange={(e) => {
                  formik.setFieldValue("market", e);
                }}
                error={formik?.errors?.market}
                value={formik?.values?.market}
              />
            </Col>
            <Col lg={12} md={12} className="mb-1">
              <CustomInput
                type={"text"}
                required={true}
                label={"Local Govt Area"}
                id={"lga"}
                placeholder={"Local Govt Area"}
                onChange={formik?.handleChange}
                error={formik?.errors?.lga}
                value={formik?.values?.lga}
              />
            </Col>
            <CustomInput
              as={"select"}
              inputType={"select"}
              selectOptions={countryOptions}
              required={true}
              label={"Country"}
              id={"country"}
              name={"country"}
              placeholder={"Country"}
              onChange={(e) => {
                formik.setFieldValue("state", "");
                formik.setFieldValue("country", e);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.country}
              value={formik.values.country}
            />
            <CustomInput
              as={"select"}
              inputType={"select"}
              selectOptions={stateOptions}
              required={true}
              label={"State"}
              id={"state"}
              name={"state"}
              placeholder={"State"}
              onChange={(e) => {
                formik.setFieldValue("state", e);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.state}
              value={formik.values.state}
            />

            <CustomInput
              inputType={"select"}
              selectOptions={[
                { label: "Enabled", value: true, id: "true" },
                { label: "Disabled", value: false, id: "false" },
              ]}
              required={true}
              label={"Status"}
              id={"status"}
              name={"status"}
              placeholder={"Status"}
              onChange={(e) => {
                formik.setFieldValue("status", e);
              }}
              onBlur={formik.handleBlur}
              error={formik.errors.status}
              value={formik.values.status}
            />
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <CustomModalFooter
          button2Variant={"primary"}
          button2Type={"submit"}
          button2Loading={isLoading}
          button2disabled={isLoading || !formik?.isValid}
          button2onClick={formik.handleSubmit}
          button2Text={"Confirm"}
          button1Variant={"secondary"}
          button1Type={"cancel"}
          button1disabled={isLoading}
          button1Text={"Cancel"}
          button1onClick={handleCloseAddNew}
        />
      </Modal.Footer>
    </Modal>
  );
};
