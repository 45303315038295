import CustomButton from "components/CustomButton";
import CustomInput from "components/CustomInput";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CustomText } from "components/CustomText";
import moment from "moment/moment";

export const ShowAdminDetails = ({ showDetails, setShowEdit, handleCloseDetails, defaultValues }) => {
  const [roleOptions, setRoleOptions] = useState([
    { label: "Super Admin", value: "super admin" },
    { label: "Local Admin", value: "local admin" },
    { label: "Local Store Admin", value: "local store admin" },
    { label: "Global Admin", value: "global admin" },
    { label: "Global Store Admin", value: "global store admin" },
    { label: "Shopper Admin", value: "shopper admin" },
    { label: "Finance Admin", value: "finance admin" },
  ]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required").typeError("Must be text"),
    lastName: "",
    email: Yup.string().email("Invalid email address").required("Required"),
    role: "",
    password: "",
    confirmPassword: "",
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      password: "",
      confirmPassword: "",
    },
    validateOnMount: false,
    // validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      handleEditAdmin(values);
    },
  });

  useEffect(() => {
    formik.setValues(defaultValues);
  }, [defaultValues]);

  const handleEditAdmin = (values) => {};

  return (
    <Modal show={showDetails} onHide={handleCloseDetails}>
      <Modal.Header id="comptitle">
        <Modal.Title>
          <Col className="d-flex align-items-center ">
            <Col lg={10} md={10} sm={10}>
              <CustomText fontFamily={"Poppins"} fontSize={20} fontWeight={600} text="Admin's Details" cNColor="primary" />
            </Col>
            <Col>
              <Col lg={2} md={2} sm={2} className="d-flex gap-3">
                <CustomButton
                  variant="primary"
                  type="submit"
                  disabled={!formik?.isValid}
                  text="Deactivate"
                  // loading={}
                />

                <CustomButton variant="secondary" type="submit" text="Cancel" onClick={handleCloseDetails} />
              </Col>
            </Col>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0 px-3">
        <Row className="p-2 border-bottom">
          <Col className="d-flex gap-3 p-1">
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={600} text="First Name:" cNColor="primary" />
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={400} text={formik?.values?.firstName} cNColor="primary" />
          </Col>

          <Col className="d-flex gap-3 p-1">
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={600} text="Last Name:" cNColor="primary" />
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={400} text={formik?.values?.lastName} cNColor="primary" />
          </Col>
        </Row>
        <Row className="p-2 border-bottom">
          <Col className="d-flex gap-3 p-1">
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={600} text="Email:" cNColor="primary" />
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={400} text={formik?.values?.email} cNColor="primary" />
          </Col>
        </Row>
        <Row className="p-2 border-bottom">
          <Col className="d-flex gap-3 p-1">
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={600} text="Role:" cNColor="primary" />
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={400} text={formik?.values?.role} cNColor="primary" />
          </Col>
        </Row>
        <Row className="p-2 border-bottom">
          <Col className="d-flex gap-3 p-1">
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={600} text="Status:" cNColor="primary" />
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={400} text={formik?.values?.status} cNColor="primary" />
          </Col>
        </Row>
        <Row className="p-2 border-bottom">
          <Col className="d-flex gap-3 p-1">
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={600} text="Date Created" cNColor="primary" />

            <CustomText
              fontFamily={"Poppins"}
              fontSize={14}
              fontWeight={400}
              text={moment(formik?.values?.createdAt).format("ddd, DD MMM YYYY")}
              cNColor="primary"
            />
          </Col>

          <Col className="d-flex gap-3 p-1">
            <CustomText fontFamily={"Poppins"} fontSize={14} fontWeight={600} text="Date Updated:" cNColor="primary" />

            <CustomText
              fontFamily={"Poppins"}
              fontSize={14}
              fontWeight={400}
              text={moment(formik?.values?.updatedAt).format("ddd, DD MMM YYYY")}
              cNColor="primary"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
