import { Carousel } from "react-bootstrap";

export function ImageCarousel(props) {
  const arrowStyle = {
    backgroundColor: "black",
    opacity: 0.5,
  };
  return (
    <Carousel
      style={props?.carouselStyle}
      prevIcon={<span className="carousel-control-prev-icon" style={arrowStyle} />}
      nextIcon={<span className="carousel-control-next-icon" style={arrowStyle} />}
    >
      {props?.images?.map((image) => (
        <Carousel.Item key={image?.id}>
          <img className="d-block w-100" src={image?.src} alt={image?.alt || "No Image"} />
          {image?.caption && (
            <Carousel.Caption>
              <h3>{image?.caption?.title}</h3>
              <p>{image?.caption?.description}</p>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
