import React from "react";
import { Button, Spinner } from "react-bootstrap";

const CustomButton = ({ text, type, size = "sm", variant, disabled, loading, className, style, onClick, children }) => {
  return (
    <Button size={size} variant={variant} onClick={onClick} type={type} disabled={disabled || loading} className={className} style={style}>
      {loading ? <Spinner size="sm" color="white" /> : text || children}
    </Button>
  );
};

export default CustomButton;

