import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomText } from "components/CustomText";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Card, Col, Table } from "react-bootstrap";
import { useMemo, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { capitalize, formatCurrency } from "helper/formatText";
import { useSelector } from "react-redux";
import { TableHeader } from "./TableHeader";
import Pagination from "./advance-table/Pagination";
import NoData from "./Nodata";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { sortedDataByUpdatedAt } from "helper/formatArray";
const OrderTable = ({ orders, title, detailsRedirect = "/orders/:id" }) => {
  const history = useHistory();
  const { countries } = useSelector((state) => state.userAuth);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      {
        accessor: "order_id",
        Header: "ORDER ID",
      },
      {
        accessor: "order_status",
        Header: "Status",
      },

      {
        accessor: "items",
        Header: "Number of Items",
        Cell: ({ value }) => {
          return JSON.parse(value || [])?.length;
        },
      },

      {
        accessor: "total_delivery_fee",
        Header: "Delivery",
        Cell: ({ value, row }) => {
          const currency = JSON.parse(row?.original?.country)?.currency;
          return `${formatCurrency(value, 2, currency?.symbol)}`;
        },
      },
      {
        accessor: "total_amount",
        Header: "Total Amount",
        Cell: ({ value, row }) => {
          const currency = JSON.parse(row?.original?.country)?.currency;
          return `${formatCurrency(value, 2, currency?.symbol)}`;
        },
      },
      {
        accessor: "payment_status",
        Header: "Payment status",
      },
      {
        accessor: "payment_method",
        Header: "Payment Method",
      },
      {
        accessor: "createdAt",
        Header: "Date Created",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = orders;
    filtered = sortedDataByUpdatedAt(filtered);

    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });
    return filtered;
  }, [orders]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  const handleRowClick = (values) => {
    history.push(detailsRedirect?.replace(":id", values?.id), values);
  };

  return (
    <Card>
      <TableHeader
        title={title}
        data={data}
        searchable={true}
        paginated={true}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        currentPageSize={currentPageSize}
        setCurrentPageSize={setCurrentPageSize}
        setPageSize={setPageSize}
        pageSizeOptions={pageSizeOptions}
        actionButtons={true}
        actionButton1={
          <DownloadTableExcel filename="FD_Transactions" sheet="transactions" currentTableRef={tableRef.current}>
            <CustomButton variant="primary" type="button" text="Export" />
          </DownloadTableExcel>
        }
      />
      <Col>
        <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
          {data.length > 0 ? (
            <>
              <thead className="table-light text-center">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                        {column.render("Header")}

                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <SortDownSvg style={{ height: 20 }} />
                          ) : (
                            <SortUpSvg style={{ height: 20 }} />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="text-center" onClick={() => handleRowClick(row?.original)}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </>
          ) : (
            <NoData />
          )}
        </Table>
        {data.length > 0 ? (
          <Pagination previousPage={previousPage} pageCount={pageCount} pageIndex={pageIndex} gotoPage={gotoPage} nextPage={nextPage} />
        ) : (
          <></>
        )}
      </Col>
    </Card>
  );
};

export default OrderTable;
