import React, { useState } from "react";
import { Card } from "react-bootstrap";

export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  } else {
    return text;
  }
}

export function convertToYesNo(value) {
  if (value === 1) {
    return "Yes";
  } else {
    return "No";
  }
}

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

export function timeFormatCheck(timeString) {
  const timeRegex = /^([0-9]{1,2}):([0-9]{2}):([0-9]{2})$/;
  return timeRegex.test(timeString);
}

export function formatKobo(number) {
  return (typeof number == "string" ? parseInt(number) / 100 : number / 100)?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function extractEndNumber(word) {
  const regex = /\d+$/; // Match one or more digits at the end of the string
  const match = regex.exec(word); // Try to match the regex against the input string
  if (match) {
    return parseInt(match[0]); // Return the matched number as an integer
  } else {
    return null; // If no match was found, return null
  }
}

export const TruncateText = ({ fullText, truncateTo }) => {
  const [showFullText, setShowFullText] = useState(false);

  const handleClick = () => {
    setShowFullText(!showFullText);
  };

  const renderTruncatedText = () => {
    const words = fullText.split(" ");
    const truncatedWords = words.slice(0, truncateTo);
    const truncatedText = truncatedWords.join(" ");

    return (
      <>
        {truncatedText}{" "}
        {words.length > truncateTo && (
          <span onClick={handleClick} style={{ color: "blue", cursor: "pointer" }}>
            ...more
          </span>
        )}
      </>
    );
  };

  const renderFullText = () => {
    return (
      <>
        {fullText}{" "}
        <span onClick={handleClick} style={{ color: "blue", cursor: "pointer" }}>
          ...less
        </span>
      </>
    );
  };

  return <div>{showFullText ? renderFullText() : renderTruncatedText()}</div>;
};

export function formatAmount(str, type) {
  let num = parseFloat(str) * 100;
  if (type === "number") {
    return num;
  } else return num.toString();
}

export function formatCurrency(number, dp = 2, currencySign = "₦") {
  if (typeof number === "string" && /^\d+$/.test(number)) {
    number = Number(number);
  }
  if (typeof number !== "number" || isNaN(number)) {
    return "Error: Invalid input";
  }
  let formattedNumber = Number(number).toFixed(dp);
  let parts = formattedNumber.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let formattedString = `${currencySign}${parts.join(".")}`;

  return formattedString;
}

export const capitalize = (str) => {
  if (!str) return "----";
  else
    return str
      ?.toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
};

export const dateString = (val) => {
  return new Date(val).toDateString();
};
