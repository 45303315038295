// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Tab, Nav, Spinner } from "react-bootstrap";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";
// import Category from './../../componentsx/dashboard/cms/Category
// import sub components
// import data files

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { simulateFetch } from "components/simulateFetch";
import { CustomText } from "components/CustomText";
import CustomButton from "components/CustomButton";
import NoData from "components/Nodata";
import { TableHeader } from "components/TableHeader";
import { DownloadTableExcel } from "react-export-table-to-excel";

const ShopperOrders = () => {
  const columns = useMemo(
    () => [
      // { accessor: "sn", Header: "S/N" },
      // { accessor: "id", Header: "ID" },
      {
        accessor: "orderid",
        Header: "ID",
        Cell: ({ value, row }) => {
          return (
            <Link className="text-inherit " to={`/orders/${row?.original?.id}`}>
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value }) => {
          return <div className={value}>{value}</div>;
        },
      },
      {
        accessor: "marketarea",
        Header: "Market",
      },

      {
        accessor: "ordertype",
        Header: "Order Type",
      },
      {
        accessor: "total",
        Header: "Total Amount",
      },

      {
        accessor: "updatedAt",
        Header: "Date Created",
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = Array(0).fill(null);
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        id: index + 1,
        sn: index + 1,
        orderid: "122333444",
        marketarea: Math.random() > 0.3 ? "Oshodi" : "Mile 12",
        total: "10000",
        ordertype: Math.random() > 0.3 ? "Regular" : "B2B",
        unitprice: Math.random() > 0.5 ? "50" : "100",
        status: Math.random() > 0.5 ? "Open" : "In Transit",
        updatedAt: Date.now(),
      };
    });

    return filtered;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <TableHeader
              title={"Shopper Orders"}
              data={data}
              searchable={true}
              paginated={true}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              currentPageSize={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              actionButtons={true}
              actionButton1={
                <DownloadTableExcel filename="FD_ShopperOrders" sheet="Shopper Orders" currentTableRef={tableRef.current}>
                  <CustomButton variant="primary" type="button" text="Export" />
                </DownloadTableExcel>
              }
            />
            <Card.Body className="p-0">
              <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                {data.length > 0 ? (
                  <>
                    <thead className="table-light text-center">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                              {column.render("Header")}

                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownSvg style={{ height: 20 }} />
                                ) : (
                                  <SortUpSvg style={{ height: 20 }} />
                                )
                              ) : (
                                ""
                              )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()} className="text-center">
                            {row.cells.map((cell) => {
                              return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                ) : (
                  <NoData />
                )}
              </Table>

              {data.length > 0 ? (
                <Pagination
                  previousPage={previousPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                  nextPage={nextPage}
                />
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ToastContainer />
    </Fragment>
  );
};

export default ShopperOrders;
