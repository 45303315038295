// import node module libraries
import React, { Fragment, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import tomatopng from "../../assets/images/png/tomato.png";
import { Col, Card, Image, Row, Form } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-feather";

import ProductCardImage from "./../../components/productCardImage/ProductCardImage";
import { CustomText } from "components/CustomText";
import { calculateAveragePrice } from "helper/formatArray";
import { Link } from "react-router-dom";

function ProductsGrid({ pageSize, data = [] }) {
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = Number(pageSize ? pageSize : 20);
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(data.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const displayProducts = data?.slice(pagesVisited, pagesVisited + productsPerPage).map((product) => {
    return (
      <Col xl={3} lg={4} md={6} sm={12} key={product?._id}>
        <Link className="text-inherit " to={{ pathname: `/store/local/${product?._id}`, state: product }}>
          <Card className="mb-5 p-1" style={{ margin: 24 }}>
            <ProductCardImage src={product?.imageCover} imgStyle={{ objectFit: "scale-down", height: 200 }} />

            <Card.Body className="p-1">
              <Card.Title>
                <CustomText fontFamily={"Poppins"} fontSize={16} fontWeight={600} text={product?.name} cNColor="primary" />
              </Card.Title>
              <Card.Subtitle>
                <CustomText
                  fontFamily={"Poppins"}
                  fontSize={16}
                  fontWeight={600}
                  text={`Category: ${product?.category_id?.name}`}
                  cNColor="primary"
                />
              </Card.Subtitle>
              <Card.Text>
                <CustomText
                  fontFamily={"Poppins"}
                  fontSize={16}
                  fontWeight={600}
                  text={`Average price: ${product?.currency}${calculateAveragePrice(product?.priceByMarketId, "price")}`}
                  cNColor="primary"
                />
                {/* {product?.priceByMarketId?.map((x) => {
                  return (
                    <CustomText
                      fontFamily={"Poppins"}
                      fontSize={16}
                      fontWeight={600}
                      text={`${
                        x?.uom_id?.name + " (" + x?.market_id?.name + ", " + x?.cost_price + "/" + x?.price + ", " + x?.kg + "kg" + ") "
                      }`}
                      cNColor="primary"
                    />
                  );
                })} */}
                <CustomText
                  fontFamily={"Poppins"}
                  fontSize={16}
                  fontWeight={600}
                  text={`Availability: ${product?.available}`}
                  cNColor="primary"
                  textClassName={"text-capitalize"}
                />
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    );
  });
  // end of paging

  // searching code started

  const [searchTerm, setSearchTerm] = useState("");

  //   const getSearchTerm = (event) => {
  //     let searchTerm = event.target.value;
  //     setSearchTerm(searchTerm);
  //     if (searchTerm !== "") {
  //       const newInstructorsList = InstructorData.filter((instructor) => {

  //         return Object.values(instructor)
  //           .join(" ")
  //           .toLowerCase()
  //           .includes(searchTerm.toLowerCase());
  //       });
  //       setInstructorsList(newInstructorsList.slice(0, 500));
  //       setPageNumber(0);
  //     } else {
  //       setInstructorsList(InstructorData.slice(0, 500));
  //     }
  //   };

  // end of searching code

  return (
    <Fragment>
      {/* <div className="mb-4">
        <Form.Control
          type="search"
          placeholder="Search Instructors"
          value={searchTerm}
          onChange={getSearchTerm}
        />
      </div> */}
      <Row>{displayProducts.length > 0 ? displayProducts : <Col>No matching Products found.</Col>}</Row>

      <ReactPaginate
        previousLabel={<ChevronLeft size="14px" />}
        nextLabel={<ChevronRight size="14px" />}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"justify-content-end mb-0 pagination"}
        previousLinkClassName={"page-link mx-1 rounded"}
        nextLinkClassName={"page-link mx-1 rounded"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link mx-1 rounded"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"active"}
      />
    </Fragment>
  );
}

export default ProductsGrid;
