// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import StatRightIcon from "components/StatRightIcon";
import { TrashFill } from "react-bootstrap-icons";
import { ReactComponent as UserCardSvg } from "assets/svgs/user-card.svg";
import { ReactComponent as OrderCardSvg } from "assets/svgs/order-card.svg";
import { ReactComponent as StoreCardSvg } from "assets/svgs/store-card.svg";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { Link } from "react-router-dom";

import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Col, Row, Dropdown, Card, Breadcrumb, Button, Modal, Table, Form, Tab, Nav, Spinner } from "react-bootstrap";

import { Trash, Send, Inbox, MoreVertical } from "react-feather";

// import custom components
import GlobalFilter from "components/advance-table/GlobalFilter";
import Pagination from "components/advance-table/Pagination";

import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomText } from "components/CustomText";

import CustomButton from "components/CustomButton";

import NoData from "components/Nodata";
import { useSelector } from "react-redux";
import { useDeleteAreaMutation, useGetAreasMutation } from "redux/features/user/userApi";
import { saveAreas } from "redux/features/auth/authSlice";
import Loader from "components/Loader";
import { AddNewArea } from "./components/AddNewArea";
import { EditArea } from "./components/EditArea";
import { ConfirmDeleteModal } from "components/ConfirmDeleteModal";
import { sortByProperty, sortedDataByCreatedAt, sortedDataByUpdatedAt } from "helper/formatArray";
import { TableHeader } from "components/TableHeader";
import { DownloadTableExcel } from "react-export-table-to-excel";

const Areas = () => {
  const dispatch = useDispatch();
  const [editArea, setEditArea] = useState(false);
  const [areaDetails, setAreaDetails] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showNew, setNewarea] = useState(false);
  const handleCloseAddNew = () => {
    setNewarea(false);
    setRefresh(!refresh);
  };

  let initial = {};

  const handleCloseEditArea = () => {
    setEditArea(false);
    setAreaDetails(null);
    setRefresh(!refresh);
  };
  const handleShowAddNew = () => {
    setNewarea(true);
  };
  const handleShowEdit = (area) => {
    setEditArea(true);
  };

  const { uoms, countries, categories, areas } = useSelector((state) => state.userAuth);

  const [getAreas, { isLoading }] = useGetAreasMutation();
  const [deleteCategory, { isLoading: isDeleting }] = useDeleteAreaMutation();

  const fetchList = async () => {
    const res = await getAreas();
    if (res?.data?.status === "success") {
      dispatch(saveAreas(res?.data?.data));
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDelete = async () => {
    const res = await deleteCategory(areaDetails?.id);
    if (res?.data) {
      toast.success(res?.data?.message || "Deleted successfully");
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchList();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      {
        accessor: "name",
        Header: "Name",
      },
      {
        accessor: "area_code",
        Header: "Area code",
      },
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value, row }) => {
          return value === true ? "enabled" : "disabled";
        },
      },
      {
        accessor: "market_name",
        Header: "Connected Market",
      },
      {
        accessor: "state_name",
        Header: "State",
      },
      {
        accessor: "country_name",
        Header: "Country",
      },

      {
        accessor: "action",
        Header: "Action",
        Cell: ({ value, row }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <CustomButton
                variant="primary"
                onClick={async () => {
                  const area = row?.original;
                  const values = {
                    name: area?.name,
                    country: { _id: area?.country?._id, id: area?.country?.id },
                    market: { _id: area?.market?._id, id: area?.market?.id },
                    state: area?.state,
                    id: area?.id,
                    _id: area?._id,
                    delivery_fee: area?.delivery_fee,
                    minimum_order_value: area?.minimum_order_value,
                  };
                  setAreaDetails(row?.original);
                  handleShowEdit(row?.original);
                }}
                text={"Edit"}
              />

              {/* <TrashFill
                size={30}
                color="#FFB800"
                onClick={() => {
                  const area = row?.original;
                  const values = {
                    name: area?.name,
                    country: { _id: area?.country?._id, id: area?.country?.id },
                    market: { _id: area?.market?._id, id: area?.market?.id },
                    state: area?.state,
                    id: area?.id,
                    _id: area?._id,
                    delivery_fee: area?.delivery_fee,
                    minimum_order_value: area?.minimum_order_value,
                  };
                  setAreaDetails(values);
                  setDeleteShow(true);
                }}
              /> */}
            </div>
          );
        },
      },
    ],
    [],
  );

  const data = useMemo(() => {
    let filtered = areas || [];
    filtered = sortedDataByUpdatedAt(filtered);

    filtered = sortByProperty(filtered, "updatedAt", "desc");
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });
    setAreaDetails(null);
    return filtered;
  }, [areas]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const { pageIndex, globalFilter, pageSize } = state;

  const pageSizeOptions = [10, 20, 50, 100, 200, 500];
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const tableRef = useRef(null);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <TableHeader
              title={"Areas"}
              data={data}
              searchable={true}
              paginated={true}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              currentPageSize={currentPageSize}
              setCurrentPageSize={setCurrentPageSize}
              setPageSize={setPageSize}
              pageSizeOptions={pageSizeOptions}
              actionButtons={true}
              actionButton1={
                <DownloadTableExcel filename="FD_Areas" sheet="areas" currentTableRef={tableRef.current}>
                  <CustomButton variant="primary" type="button" text="Export" />
                </DownloadTableExcel>
              }
              actionButton2={
                <CustomButton
                  variant="primary"
                  // type="submit"
                  onClick={handleShowAddNew}
                  text="Create New "
                  // loading={}
                />
              }
            />
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <Table ref={tableRef} responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
                  {data.length > 0 ? (
                    <>
                      <thead className="table-light text-center">
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                                {column.render("Header")}

                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownSvg style={{ height: 20 }} />
                                  ) : (
                                    <SortUpSvg style={{ height: 20 }} />
                                  )
                                ) : (
                                  ""
                                )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} className="text-center">
                              {row.cells.map((cell) => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <NoData />
                  )}
                </Table>

                {data.length > 0 ? (
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Add New Area Moodal */}

      <AddNewArea showNew={showNew} handleCloseAddNew={handleCloseAddNew} />
      {/* Edit Area Modal */}
      {areaDetails && <EditArea showEdit={editArea} handleCloseEdit={handleCloseEditArea} defaultValues={areaDetails} />}
      <ConfirmDeleteModal
        deleteShow={deleteShow}
        handleDelete={handleDelete}
        handleDeleteClose={() => setDeleteShow(false)}
        name={"'" + areaDetails?.name + "' Area"}
        text="Deleting this area will affect all products and orders under this connected to this area, proceed with caution"
      />
    </Fragment>
  );
};

export default Areas;
