import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomText } from "components/CustomText";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import { Trash, Send, Inbox, MoreVertical } from "react-feather";
import { ReactComponent as SortUpSvg } from "assets/svgs/sort-up.svg";
import { ReactComponent as SortDownSvg } from "assets/svgs/sort-down.svg";
import { useTable, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy } from "react-table";
import { Card, Col, Dropdown, DropdownButton, Form, FormLabel, FormSelect, InputGroup, Row, Table } from "react-bootstrap";
import { Button } from "bootstrap";
import { useMemo, useState } from "react";
import ProductCardImage from "components/productCardImage/ProductCardImage";
import CustomDetailGroup from "components/CustomDetailGroup";
import { Products } from "./Products";
import { Link } from "react-router-dom";
import { formatCurrency } from "helper/formatText";
import { useSelector } from "react-redux";
import { convertJsonStringToObject } from "helper/formatObject";
const OrderSummary = ({ order }) => {
  const { countries } = useSelector((state) => state.userAuth);
  const items = order?.items;
  const vendors = order?.vendors;

  const columns = useMemo(
    () => [
      { accessor: "sn", Header: "S/N" },
      //   { accessor: "id", Header: "ID" },
      {
        accessor: "name",
        Header: "Name",
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit "
              to={{ pathname: `/store/local/${row?.original?.product_id?.id}`, state: row?.original?.product_id }}
            >
              <h5 className="text-primary-hover mb-0">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: "cost_price",
        Header: "Cost Price",
        Cell: ({ value, row }) => {
          return formatCurrency(value, 2, "₦");
        },
      },
      {
        accessor: "price",
        Header: "Selling Price",
        Cell: ({ value, row }) => {
          return formatCurrency(value, 2, countries?.find((x) => x?.id == row?.original?.country_id)?.currency?.symbol);
        },
      },
      {
        accessor: "conversion_rate",
        Header: "Conversion rate",
      },
      {
        accessor: "uom",
        Header: "Unit of Measure",
      },
      // enableSorting: true,
      {
        accessor: "quantity",
        Header: "Quantity",
      },
      {
        accessor: "vendor_code",
        Header: "Vendor Code",
      },
      {
        accessor: "costTotal",
        Header: "Cost Total",
        Cell: ({ value, row }) => {
          return formatCurrency(value, 2, "₦");
        },
      },
      {
        accessor: "subTotal",
        Header: "Sub Total",
        Cell: ({ value, row }) => {
          return formatCurrency(value, 2, "₦");
        },
      },
    ],
    [],
  );
  const data = useMemo(() => {
    let filtered = convertJsonStringToObject(items) || [];
    filtered = filtered?.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        price: x?.priceByMarket_id?.price,
        cost_price: x?.priceByMarket_id?.cost_price,
        costTotal: x?.priceByMarket_id?.cost_price * x?.quantity,
        subTotal: x?.priceByMarket_id?.price * x?.quantity,
        uom: x?.priceByMarket_id?.uom_id?.name,
        name: x?.product_id?.name,
        vendor_code: vendors?.find((y) => y?.product_id === x?.product_id?.id && y?.uom === x?.priceByMarket_id?.uom_id?.name)?.vendor_code,
        conversion_rate: order.conversion_rate,
      };
    });
    return filtered;
  }, [items, vendors, order]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    setPageSize,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // pageSize: pagesize,
      initialState: {
        // pageSize: pagesize,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  return (
    <Table responsive {...getTableProps()} className="text-nowrap" style={{ position: "relative" }}>
      <thead className="table-light text-center">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="text-black">
                {column.render("Header")}

                {column.isSorted ? column.isSortedDesc ? <SortDownSvg style={{ height: 20 }} /> : <SortUpSvg style={{ height: 20 }} /> : ""}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="text-center">
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default OrderSummary;
